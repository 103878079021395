import axios from "axios";
import React, { useEffect, useState } from "react";
import { getLogsBaseURL } from "../../config";

function EnvironmentNames(props) {
  const [Data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(getLogsBaseURL() + "/openapi.json")
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        setData(response.data["components"]["schemas"]["EnvironmentName"]["enum"]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    }, []);

  return Data.map((item) => {
    return (
      <option key={item} value={item}>{item}</option>
    );
  });
};

export default EnvironmentNames;
