import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Col,
  Row,
  Modal,
  Form,
} from "react-bootstrap";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Check } from "react-feather";
import { useTranslation } from "react-i18next";
import { parseISO } from "date-fns";
import ErrorHandler from "../Helper/ErrorHandler";

const AddPaidStatusModal = (props) => {
  let { id } = useParams();
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const [openModal, setOpenModal] = useState(false);

  const [bankRef, setBankRef] = useState();
  const [paidOn, setPaidOn] = useState();

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  function handleSubmit(e) {
    e.preventDefault();

    console.log({
      is_paid: true,
      paid_on: paidOn.toISOString(),
      bank_reference: bankRef
    })

    axios
      .put("/invoices/" + id, {
        is_paid: true,
        paid_on: paidOn.toISOString(),
        bank_reference: bankRef
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        toggle(false)
        refreshPage();
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  return (
    <>
      <Button
        variant={"primary"}
        onClick={() => toggle()}
        className="shadow-sm me-1"
      >
        <Check className="feather" /> {t("Mark as \"Paid\"")}
      </Button>
      <Modal show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Mark as \"Paid\"")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <br />
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Payment Date")}</Form.Label>
                  <Form.Control
                    type="date"
                    onChange={e => setPaidOn(parseISO(e.target.value))}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Bank Reference")}</Form.Label>
                  <Form.Control
                    placeholder={t("Bank Reference")}
                    onChange={(e) => setBankRef(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => toggle(false)}>
            {t("Cancel")}
          </Button>{" "}
          <Button variant={"primary"} onClick={handleSubmit}>
            {t("Save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default AddPaidStatusModal;
