import { Badge, Card, Spinner, Table } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format, parseISO } from 'date-fns'
import { de } from "date-fns/locale"
import LoadingSpinner from "../Helper/LoadingSpinner";

const InvoiceTable = ({data, title, isLoading, showFullTable}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();


  const renderInvoiceState = (invoice) => {

    switch (invoice.payment_status) {
      case "paid":
        return (
          <Badge bg="" className="badge-soft-success me-2">{t(invoice.payment_status)}</Badge>
        )
      case "unpaid":
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t(invoice.payment_status)}</Badge>
        )
      case "overdue":
        return (
          <Badge bg="" className="badge-soft-danger me-2">{t(invoice.payment_status)}</Badge>
        )
      default:
        break;
    }
  }


  const renderTable = () => {
    return data.map((invoice) => {
      return (
        <tr key={invoice.id} onClick={() => navigate("/invoices/" + invoice.id)} style={{ cursor: "pointer" }}>
          <td>{renderInvoiceState(invoice)}</td>
          <td>{invoice.invoice_number}</td>
          {showFullTable && <td>{invoice?.billed_party_name || "-"}</td>}
          <td>{t(invoice?.billing_to)}</td>
          {/* <td>{invoice.contract_number}</td> */}
          <td>{invoice.total.toLocaleString(i18n.language === "de" ? de : "en", {
            style: 'currency',
            currency: 'EUR',
          })}</td>
          {invoice?.created_at && <td>{format(parseISO(invoice.created_at), "dd MMMM yyyy", { locale: i18n.language === "de" ? de : null })}</td>}
          <td>{format(parseISO(invoice.due_date), "dd MMMM yyyy", { locale: i18n.language === "de" ? de : null })}</td>
        </tr>
      );
    });
  };

  return (
    <Card className="flex-fill w-100">
      {
        title === null ?
          <div />
          :
          <Card.Header>
            <Card.Title tag="h5" className="mb-0">
              {t(title)}
            </Card.Title>
          </Card.Header>
      }
      {/* <Card.Body> */}
      {
        isLoading ?
          <LoadingSpinner withText/> :
          data != null && data.length > 0
            ?
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">{t("Status")}</th>
                  <th scope="col">#</th>
                  {showFullTable === true && <th scope="col">{t("Recipient")}</th>}
                  <th scope="col">{t("Invoice Recipient")}</th>
                  <th scope="col">{t("Amount")}</th>
                  {/* {thinTable === false && <th scope="col">{t("Billing date")}</th>} */}
                  <th scope="col">{t("Due date")}</th>
                </tr>
              </thead>
              <tbody>{renderTable()}</tbody>
            </Table>
            :
            <Card.Body>
              <p>{t("No data found.")}</p>
            </Card.Body>
      }
      {/* </Card.Body> */}
    </Card>
  );
};

export default InvoiceTable;
