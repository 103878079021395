import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Table, Breadcrumb, ButtonGroup, OverlayTrigger, Popover, } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { HelpCircle, Power, } from "react-feather";
import { Button } from "react-bootstrap";
import usePalette from "../../hooks/usePalette";
import { format, formatDistanceToNow, parseISO, } from 'date-fns'
import { useTranslation } from "react-i18next";
import { de } from 'date-fns/locale';
import Chart from "react-apexcharts";
import LottieControl from "../Helper/LottieControl";
import ErrorHandler from "../Helper/ErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import animationData from "../Helper/99714-go-live.json";

const AssetLiveView = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [gatewayData, setGatewayData] = useState([]);

  const [LiveData, setLiveData] = useState([]);
  const [InfoLightsData, setInfoLightsData] = useState([]);
  // const [pause, setPause] = useState(false);
  const [UsageCounter, setUsageCounter] = useState();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const [data, setData] = useState([]);
  const [PowerUsage, setPowerUsage] = useState([]);
  const [CurrentPower, setCurrentPower] = useState([]);
  // const [PowerUsageData, setPowerUsageData] = useState([]);
  const [PowerUsagePerUse, setPowerPerUseData] = useState([]);

  const Live_Data_INTERVAL = 5000;
  // const Usage_Counter_INTERVAL = 10000;

  useEffect(() => {
    const interval = setInterval(() => {
      // console.log('Reloading Livedata');
      reloadLiveUsageData();
    }, Live_Data_INTERVAL);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log('Reloading Counter');
  //     reloadUsageCounterData();
  //     reloadLiveInfoLightsData();
  //   }, Usage_Counter_INTERVAL);

  //   return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  // }, [])

  useEffect(() => {

    axios
      .get("/webservice/assets/" + id + "/base")
      .then(function (response) {
        console.log(response.status, "Loaded asset by id");
        // handle success
        setData(response.data);
        // setGatewayData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    reloadLiveUsageData();
  }, []);

  async function reloadLiveUsageData() {
    setError({ showMessage: false, errorData: null });

    try {
      const response = await axios.get("/webservice/assets/" + id + "/live");

      console.log(response.status, "Loaded live");

      setUsageCounter(response.data.last_usage_counter);
      setInfoLightsData(response.data.info_lights.sort((a, b) => (a.time < b.time)));
      setGatewayData(response.data.iot_gateway);
      setPowerUsage(response.data?.power_usage_current_hour);
      setCurrentPower(response.data?.current_power);
      setPowerPerUseData(response.data?.power_per_use_current_hour)

      if (response.data.live_usage === null) {
        setLiveData([]);
      } else {
        setLiveData(response.data.live_usage.sort((a, b) => new Date(a.interval_start) - new Date(b.interval_start)));
      }
    } catch (error) {
      setError({ showMessage: true, errorData: error });
      console.log(error);
    }
  }

  const InfoLights = () => {

    function getBadge(light) {

      if (light.plc_node_id.toLowerCase().includes('blue')) {
        if (light.value === 0) {
          return "outline-primary"
        } else {
          return "primary"
        }
      } else if (light.plc_node_id.toLowerCase().includes('red')) {
        if (light.value === 0) {
          return "outline-danger"
        } else {
          return "danger"
        }
      } else if (light.plc_node_id.toLowerCase().includes('green')) {
        if (light.value === 0) {
          return "outline-success"
        } else {
          return "success"
        }
      } else {
        return "-"
      }
    }

    function getBadgeText(light) {

      if (light.plc_node_id.toLowerCase().includes('blue')) {
        switch (light.value) {
          case 0:
            return t("Off") // "Bereit"
          case 1:
            return "Vorwarnung Wartungsarbeiten"
          case 2: // blinkend
            return "Wartungsarbeiten"
          default:
            return "n/a"
        }
      } else if (light.plc_node_id.toLowerCase().includes('red')) {

        if (light.value === 0) {
          return t("Off") // "Störungsfrei"
        } else if (light.value === 1) {
          return "Abgeschaltet"
        } else if (light.value === 2) { // blinkend
          return "Störung"
        } else {
          return "n/a"
        }

      } else if (light.plc_node_id.toLowerCase().includes('green')) {

        if (light.value === 0) {
          return t("Off") // "Nicht in Produktion"
        } else if (light.value === 1) {
          return "In Produktion"
        } else if (light.value === 2) { // blinkend
          return "Bauteil einlegen"
        } else {
          return "n/a"
        }
      } else {
        return "n/a"
      }
    }

    if (InfoLightsData != null) {
      if (InfoLightsData.length === 0) {
        return (<Button onClick={() => navigate("/assets/" + id + "/?focus=digital-twin")} variant="outline-secondary" size="sm" className="btn-pill me-1 mb-1">
          n/a
        </Button>)

      } else {
        if (InfoLightsData.filter(e => e.value !== 0).length === 0) {
          return (<Button onClick={() => navigate("/assets/" + id + "/?focus=digital-twin")} variant="outline-secondary" size="sm" className="btn-pill me-1 mb-1">
            n/a
          </Button>)
        }
        else {
          return InfoLightsData.filter(e => e.value !== 0).map(light => {
            return (
              <Button key={light.id} onClick={() => navigate("/assets/" + id + "/?focus=digital-twin")} variant={getBadge(light)} size="sm" className="btn-pill me-1 mb-1">
                {getBadgeText(light)}
              </Button>
            )
          })
        }
      }
    }
  }

  const renderConnectionState = (status) => {

    switch (status) {
      case "connected":
        return (
          <span className="badge bg-success">{t(status)}</span>
        )
      case "connection_lost":
        return (
          <span className="badge bg-warning">{t(status)}</span>
        )
      default:
        break;
    }
  }

  // function handlePause() {
  //   setPause(pause ? false : true)
  // }

  const LiveChart = (props) => {
    const palette = usePalette();
    const { t, i18n } = useTranslation();

    const data = [
      {
        name: t("Units"),
        data: LiveData.map((entry) => { return entry.amount }),
      },
    ];

    const options = {
      chart: {
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          endingShape: "rounded",
          columnWidth: "100%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
      },
      labels: LiveData.map((entry) => { return format(parseISO(entry.interval_start), 'HH:mm:ss', { locale: i18n.language === "de" ? de : null }) }),
      xaxis: {
        // type: "time",
        // max: 100,
        title: {
          text: t("Time"),
        },
        tickAmount: 20,
        labels: {
          show: true,
          align: 'right',
          // minWidth: 100,
          // maxWidth: 200,
          hideOverlappingLabels: true,
          style: {
            colors: palette.black,
          },
        },
      },
      yaxis: {
        min: 0,
        max: Math.max(...LiveData.map(o => o.amount), 0) * 2,
        title: {
          text: t("Units"),
        },
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
          formatter: (value) => { return value.toLocaleString() },
        },
      },
      legend: {
        position: 'top',
        labels: {
          colors: palette.black,
          useSeriesColors: false
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      colors: [
        palette.primary,
      ],
    };

    return (

      <Card className="flex-fill">
        <Card.Header>
          <Card.Title tag="h5">{t("Last 30 minutes")}</Card.Title>
        </Card.Header>
        <Card.Body>
          {
            LiveData.reduce((a, v) => a = a + v.amount, 0) === 0 ?
              <h6 className="card-subtitle text-muted">
                {t("Currently no production.") + " " + t("Last production unit") + ": " + (gatewayData == null ? "n/a" : gatewayData.last_usage_log == null ? "-" : format(parseISO(gatewayData.last_usage_log.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null }) + " (" + formatDistanceToNow(parseISO(gatewayData.last_usage_log.time), { locale: i18n.language === "de" ? de : null, addSuffix: true }) + ")")}
              </h6>
              :
              LiveData.length === 0
                ?
                <h6 className="card-subtitle text-muted">
                  {t("No production data available.")}
                </h6>
                :
                <Chart options={options} series={data} type="bar" height="300" />
          }

        </Card.Body>
      </Card>
    );
  };

  return (
    <React.Fragment>
      <Helmet title="Live View" />
      <Container fluid className="p-0">
        <Breadcrumb>
          {/* <div className="vertical-center-all"> */}
          <Button variant="outline-primary" size="sm" className="me-2" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
          </Button>
          <Breadcrumb.Item href="#" onClick={() => navigate("/assets")}>{t("Assets")}</Breadcrumb.Item>
          <Breadcrumb.Item href="#" onClick={() => navigate("/assets/" + id)}>{t("Asset 360° View")}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t("Realtime")}</Breadcrumb.Item>
          {/* </div> */}
        </Breadcrumb>
        <Row className="mb-2 mb-xl-3">
          <Col>{/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("Realtime Monitoring")}: {data.title}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <ButtonGroup>
              {/* <LottieControl animation={animationData} height={30} width={70} /> */}
              {/* {
                !isMobile && <Button
                  onClick={() => handlePause()}
                  variant={pause ? "outline-primary" : "primary"}
                  className="shadow-sm me-1">
                  {
                    pause ?
                      <><Pause className="feather" />  {t('Pause')} </> :
                      <><Play className="feather" />  {t('Play')} </>
                  }
                </Button>
              } */}
              {/* {!isMobile && <>&nbsp; &nbsp; &nbsp;<Button
                  variant="outline-primary"
                  size="sm"
                  className="shadow-sm" // me-1
                  onClick={() => navigate("/assets/" + id + "/usage")}>{t("More usage details")}
                </Button>
                </>} */}
            </ButtonGroup>
            {/* <Button
              onClick={reloadData}
              variant="primary"
              className="shadow-sm">
              <RefreshCcw className="feather" />
            </Button> */}
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Col sm="3" lg="6" className="d-flex col-xxl-3">
            <Card className="flex-fill">
              <Card.Body>
                <Row>
                  <Col className="mt-0">
                    <span className="h1 d-inline-block mt-1 mb-4">{LiveData.length === 0 ? "-" : LiveData.reduce((a, v) => a = a + v.amount, 0).toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'decimal',
                      maximumFractionDigits: 0,
                    })}</span>

                  </Col>
                  {/* <Col xs="auto">
                    <div className="stat stat-sm">
                      <Activity className="align-middle text-success" />
                    </div>
                  </Col> */}
                </Row>
                <h5 className="card-title">{t("Usage last 30 minutes")}</h5>
                <div className="mb-0">
                  {/* <Badge bg=""
                    className={TodayUsageSum > TodayLastWeekUsageSum ? "badge-soft-success me-2" : "badge-soft-warning me-2"}>
                    {TodayLastWeekUsageSum == null ? 0 : TodayLastWeekUsageSum.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  </Badge> */}
                  {/* <Badge bg="" className="badge-soft-success me-2">
                    {LiveData == null ? "-" : (LiveData.reduce((a, v) => a = a + v.amount, 0) * data.per_use_price).toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </Badge>
                  <span className="text-muted">{t("Expenses")}</span> */}
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col sm="3" lg="6" className="d-flex col-xxl-3">
            <Card className="flex-fill w-100">
              <Card.Body>
                <Row>
                  <Col className="mt-0">
                    <span className="h1 d-inline-block mt-1 mb-4">{UsageCounter === null | UsageCounter === undefined ? "-" : UsageCounter.usage === null ? "-" : (UsageCounter.usage).toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'decimal',
                      maximumFractionDigits: 0,
                    })}</span>
                  </Col>
                  {/* <Col xs="auto">
                    <div className="stat stat-sm">
                      <Hash className="align-middle text-success" />
                    </div>
                  </Col> */}
                </Row>
                <h5 className="card-title">{t("Usage counter")}</h5>
                <div className="mb-0">
                  <span className="text-muted">{t("Updated")} {UsageCounter === null | UsageCounter === undefined ? "-" : UsageCounter.time === null ? "-" : formatDistanceToNow(parseISO(UsageCounter.time), { locale: i18n.language === "de" ? de : null, includeSeconds: true, addSuffix: true })}</span>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="3" lg="6" className="d-flex col-xxl-3">
            <Card className="flex-fill w-100">
              <Card.Body>
                <Row>
                  <Col className="mt-0">
                    <div className=" mt-1 mb-4">
                      <InfoLights />
                    </div>
                  </Col>
                </Row>
                <h5 className="card-title">{t("Status Lights")}</h5>
                <div className="mb-0">
                  <span className="text-muted">{t("Updated")} {InfoLightsData == null ? "-" : InfoLightsData[0] == null ? "n/a" : formatDistanceToNow(parseISO(InfoLightsData[0].time), { locale: i18n.language === "de" ? de : null, includeSeconds: true, addSuffix: true })}</span>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="3" lg="6" className="d-flex col-xxl-3">
            <Card className="flex-fill">
              <Card.Body>

                <Row>
                  <Col className="mt-0">
                    {
                      PowerUsage === null || PowerUsage?.length === 0 ?
                        <Col className="mt-0">
                          <span className="h1 d-inline-block mt-1 mb-4">{LiveData == null ? "-" : (LiveData.reduce((a, v) => a = a + v.amount, 0) * data.co2_per_use).toLocaleString(i18n.language === "de" ? de : "en", {
                            style: 'decimal',
                            maximumFractionDigits: 2,
                          })} g</span>
                        </Col>
                        :
                        // <Col className="mt-0">
                        //   <span className="h1 d-inline-block mt-1 mb-4">
                        //     {(powerUsage[0]?.value / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", {
                        //       style: 'decimal',
                        //       maximumFractionDigits: 2,
                        //     })} {t("kWh")}
                        //   </span>
                        // </Col>

                        <Col className="mt-0">
                          <span className="h1 d-inline-block mt-1 mb-4">
                            {(PowerUsage / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) + " kWh"}
                          </span>
                        </Col>
                    }
                  </Col>
                  <Col xs="auto" className="ms-auto text-end">
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Popover>
                          <Popover.Header as='h3'>{t("Power Consumption")}</Popover.Header>
                          <Popover.Body>
                            {t("Mehr Informationen zum Stromverbrauch")}<br /><br /><br />

                            <strong>{t("Power Consumption")}</strong> {t("(current hour)")}<br />
                            <strong className="h4">{(PowerUsage / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) + " kWh"}</strong><br />
                            <strong className="h4">{(PowerUsagePerUse / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 }) + " kWh/" + t("unit")}</strong>

                            <br /><br /><br />

                            <strong>{t("Current Power")}</strong><br />
                            <strong className="h4">{(CurrentPower / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", {
                              style: 'decimal',
                              maximumFractionDigits: 2,
                            })} {" "} {t("kW")}</strong>{" "}
                            ({CurrentPower?.toLocaleString(i18n.language === "de" ? de : "en", {
                              style: 'decimal',
                              maximumFractionDigits: 0,
                            })} {" "} {t("W")})

                            <br /><br /><br /><br />

                            Bei der Herstellung des verbrauchten Stroms wurden <strong className="h5">{(PowerUsage * data?.co2_kg_per_wh)?.toLocaleString(i18n.language === "de" ? de : "en", {
                              style: 'decimal',
                              maximumFractionDigits: 2,
                            })} kg CO<sub>2</sub>e</strong> freigesetzt.<br /><br />
                            1 kWh entspricht {data?.co2_kg_per_wh * 1000} kg CO<sub>2</sub>e<br />
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <HelpCircle className="feather" />
                    </OverlayTrigger>
                  </Col>
                </Row>

                {
                  PowerUsage === null || PowerUsage?.length === 0 ?
                    <h5 className="card-title">{t("Estimated")}</h5>
                    :
                    <h5 className="card-title">{t("Power Consumption")} {t("(current hour)")}</h5>
                }
                <div className="mb-0">
                  <span className="text-muted">{(PowerUsage * data?.co2_kg_per_wh)?.toLocaleString(i18n.language === "de" ? de : "en", {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                  })} kg CO<sub>2</sub>e Emissionen</span>
                </div>
                {/* <div className="mb-0">
                  <span className="text-muted">{t("Updated")} {powerUsage == null ? "-" : powerUsage[0] == null ? "n/a" : formatDistanceToNow(parseISO(powerUsage[0].time), { locale: i18n.language === "de" ? de : null, addSuffix: true })}</span>
                </div> */}
                {/* <h5 className="card-title">CO<sub>2</sub> {t("Emissions")}</h5> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <Row>
          {UsageBarChart(
            LiveData.map((entry) => { return entry.amount }),
            LiveData.map((entry) => { return format(parseISO(entry.interval_start), "HH:mm:ss", { locale: i18n.language === "de" ? de : null }) }),
            Math.max(...LiveData.map(o => o.amount), 0) * 2, t("Last 30"))}
        </Row> */}
        <Row>
          <Col md="12" className="d-flex">
            <LiveChart />
          </Col>
          {/* <Col md="4">
            <Card>
              <LottieControl/>
            </Card>
          </Col> */}
        </Row>
        <Row>
          {/* <Col lg="6" xl="5" className="d-flex">
            <Row>
              <Col sm="6" lg="12" className="d-flex col-xxl-6">
              <Card className="flex-fill">
                  <Card.Body>
                    <Row>
                      <Col className="mt-0">
                        <span className="h1 d-inline-block mt-1 mb-4">{LiveData == null ? "-" : LiveData.reduce((a, v) => a = a + v.amount, 0).toLocaleString(i18n.language === "de" ? de : "en", {
                          style: 'decimal',
                          maximumFractionDigits: 0,
                        })}</span>

                      </Col>
                      <Col xs="auto">
                        <div className="stat stat-sm">
                          <Activity className="align-middle text-success" />
                        </div>
                      </Col>
                    </Row>
                    <h5 className="card-title">{t("Usage last 30 minutes")}</h5>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6" lg="12" className="d-flex col-xxl-6">
              <Card className="flex-fill w-100">
                  <Card.Body>
                    <Row>
                      <Col className="mt-0">
                        <span className="h1 d-inline-block mt-1 mb-4">{UsageCounter == null ? "-" : (UsageCounter.usage).toLocaleString(i18n.language === "de" ? de : "en", {
                          style: 'decimal',
                          maximumFractionDigits: 0,
                        })}</span>

                      </Col>
                      <Col xs="auto">
                        <div className="stat stat-sm">
                          <Hash className="align-middle text-success" />
                        </div>
                      </Col>
                    </Row>
                    <h5 className="card-title">{t("Current usage counter")}</h5>
                    <div className="mb-0">
                      <span className="text-muted">{t("Updated")} {UsageCounter == null ? "-" : formatDistanceToNow(parseISO(UsageCounter.time), { locale: i18n.language === "de" ? de : null, addSuffix: true })}</span>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6" lg="12" className="d-flex col-xxl-6">
              <Card className="flex-fill">
                  <Card.Body>
                    <Row>
                      <Col className="mt-0">
                        <span className="h1 d-inline-block mt-1 mb-4">{LiveData == null ? "-" : (LiveData.reduce((a, v) => a = a + v.amount, 0)*data.per_use_price).toLocaleString(i18n.language === "de" ? de : "en", {
                        style: 'currency',
                        currency: 'EUR',
                      })}</span>
                      </Col>
                      <Col xs="auto">
                        <div className="stat stat-sm">
                          <DollarSign className="align-middle text-success" />
                        </div>
                      </Col>
                    </Row>
                    <h5 className="card-title">{t("Earnings last 30 minutes")}</h5>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="6" lg="12" className="d-flex col-xxl-6">
              <Card className="flex-fill w-100">
                  <Card.Body>
                    <Row>
                      <Col className="mt-0">
                        <span className="h1 d-inline-block mt-1 mb-4">n/a</span>
                      </Col>
                      <Col xs="auto">
                        <div className="stat stat-sm">
                          <DollarSign className="align-middle text-success" />
                        </div>
                      </Col>
                    </Row>
                    <h5 className="card-title">{t("Earnings today")}</h5>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col> */}
          <Col lg="6" xl="6" className="d-flex">
            <Card className="flex-fill w-100">
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("About")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Table>
                  <tbody>
                    <tr>
                      <th>{t("Asset Number")} / {t("Model Number")}</th>
                      <td>{data.asset_number} / {data.serial}</td>
                    </tr>
                    <tr>
                      <th>{t("Description")}</th>
                      <td>{data.description}</td>
                    </tr>
                    <tr>
                      <th>{t("Client")}</th>
                      <td>{data.client_name}</td>
                    </tr>
                    <tr>
                      <th>{t("cap-on Activation Date (start of billing cycle, SOP, etc.)")}</th>
                      <td>{data.activation_date == null ? "n/a" : format(parseISO(data.activation_date), "eee, d MMMM yyyy", { locale: i18n.language === "de" ? de : null })}</td>
                    </tr>
                    <tr>
                      <th>{t("Investment Volume")}</th>
                      <td>{data.investment_price == null ? "n/a" : data.investment_price.toLocaleString(i18n.language === "de" ? de : "en", {
                        style: 'currency',
                        maximumFractionDigits: 2,
                        currency: 'EUR',
                      })}</td>
                    </tr>
                    <tr>
                      <th>{t("Pay-per-Use Price")}</th>
                      <td>{data.per_use_price == null ? "n/a" : data.per_use_price.toLocaleString(i18n.language === "de" ? de : "en", {
                        style: 'currency',
                        currency: 'EUR',
                      })}</td>
                    </tr>
                    <tr>
                      <th>{t("Monthly Base Fee")}</th>
                      <td>{data.monthly_base_fee == null ? "n/a" : data.monthly_base_fee.toLocaleString(i18n.language === "de" ? de : "en", {
                        style: 'currency',
                        currency: 'EUR',
                      })}</td>
                    </tr>
                    <tr>
                      <th>{t("CO\u2082 Compensation")}</th>
                      <td>
                        {data.co2_compensation === false
                          ? t("Not included")
                          : t("Included")}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="6" xl="6" className="d-flex">
            <Card className="flex-fill w-100">
              <Card.Header>
                <Card.Title>
                  {t("IoT-Gateway")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Table>
                  <tbody>
                    {/* <tr>
                      <th>{t("Gateway Serial")}</th>
                      <td>
                        <span>{gatewayData == null ? "n/a" : gatewayData.serial}</span>
                      </td>
                    </tr> */}
                    <tr>
                      <th>{t("Connection")}</th>
                      <td>{gatewayData == null ? "n/a" : renderConnectionState(gatewayData.connection_status)}</td>
                    </tr>
                    {/* <tr>
                      <th>LTE-Connection</th>
                      <td>
                        <span className="badge bg-success">3/4</span>
                      </td>
                    </tr> */}
                    {/* <tr>
                      <th>Power consumption</th>
                      <td>437 kWh</td>
                    </tr> */}
                    <tr>
                      <th>{t("Last handshake")}</th>
                      <td>{gatewayData == null ? "n/a" : gatewayData.last_handshake == null ? "-" : format(parseISO(gatewayData.last_handshake.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })}</td>
                    </tr>
                    <tr>
                      <th>{t("Last production unit")}</th>
                      <td>{gatewayData == null ? "n/a" : gatewayData.last_usage_log == null ? "-" : format(parseISO(gatewayData.last_usage_log.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })}</td>
                    </tr>
                    {/* <tr>
                      <th>{t("Internet speed (Download)")}</th>
                      <td>{gatewayData == null ? "n/a" : gatewayData.last_handshake == null ? "-" : gatewayData.last_handshake.internet_speed_down + " Mbit/s"}</td>
                  </tr>*/}
                    <tr>
                      <th>{t("Internet speed (Upload)")}</th>
                      <td>{gatewayData == null ? "n/a" : gatewayData.last_handshake == null ? "-" : gatewayData.last_handshake.internet_speed_up == null ? "-" : gatewayData.last_handshake.internet_speed_up.toLocaleString(i18n.language === "de" ? de : "en", {
                        style: 'decimal',
                        maximumFractionDigits: 1,
                      }) + " Mbit/s"}</td>
                    </tr>
                    {/* <tr>
                      <th>{t("CPU Usage")}</th>
                      <td>{gatewayData == null ? "n/a" : gatewayData.last_handshake == null ? "-" : gatewayData.last_handshake.cpu_usage + " %"}</td>
                    </tr> */}
                  </tbody>
                </Table>
                <br />
                {
                  gatewayData == null ?
                    <br /> :
                    <Col className="ms-auto text-center mt-n1">
                      <Card.Link onClick={() => navigate("/gateways/" + gatewayData.id)}>{t("More info")}</Card.Link>
                    </Col>
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};


export default AssetLiveView;
