import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Col,
  Row,
  Dropdown,
  Modal,
  Form,
} from "react-bootstrap";
import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorHandler from "../Helper/ErrorHandler";
import NotyfContext from "../../contexts/NotyfContext";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { Plus } from "react-feather";

const UploadFileModal = (props) => {
  let { id } = useParams();
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);
  const notyf = useContext(NotyfContext);
  const [selectedFile, setSelectedFile] = useState();
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleuploadFile = event => {
    uploadFile(event);
  };

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    setSelectedFile(event.target.files[0]);
  };

  function uploadFile(e) {
    e.preventDefault();

    // console.log("->", selectedFile);

    if (selectedFile === undefined) {
      setError({ showMessage: true, errorData: error, customMessage: t("Please select a new file first") })
    } else {
      var formData = new FormData();
      formData.append("file", selectedFile);

      axios
        .post("/" + props.type + "s/" + id + "/files?" + props.type + "_id=" + id, formData, {
          headers: {
            "Content-Type": 'multipart/form-data'
          }
        })
        .then(function (response) {
          // handle success
          console.log("File uploaded");
          notyf.open({
            type: "success",
            message: t("File uploaded"),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })
          toggle(false)

          window.location.reload(false);
        })
        .catch(function (error) {
          // handle error
          // window.confirm(error)
          console.log(error);
          setError({ showMessage: true, errorData: error })
        });
    }
  }

  const ButtonForType = () => {
    switch (props?.buttonType) {
      case "small":
        return (<Button
          onClick={() => toggle()}
          variant="outline-primary"
          size="sm"
          className="shadow-sm me-1">
          <Plus className="feather" />  {t("Upload")}
        </Button>)
      default:
        return (<Dropdown.Item onClick={() => toggle()}>{t("Upload file")}</Dropdown.Item>)
    }
  }


  return (
    <>
      <ButtonForType />
      <Modal show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Upload File")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <br />
          <Form>
            <Row>
              <Col md="12">
                <Form.Group >
                  <Form.Control type="file" ref={hiddenFileInput} onChange={handleChange} />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => toggle(false)}>
            {t("Cancel")}
          </Button>{" "}
          <Button variant={"primary"} onClick={handleuploadFile}>
            <FontAwesomeIcon icon={faUpload} /> {t("Upload")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadFileModal;
