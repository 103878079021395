import axios from "axios";
import React, { useEffect, useState } from "react";
import { getLogsBaseURL } from "../../config";

function RequestMethods(props) {
  const [RequestMethods, setRequestMethods] = useState([]);

  useEffect(() => {
    axios
      .get(getLogsBaseURL() + "/openapi.json")
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        setRequestMethods(response.data["components"]["schemas"]["RequestMethod"]["enum"]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    }, []);

  return RequestMethods.map((method) => {
    return (
      <option key={method} value={method}>{method}</option>
    );
  });
};

export default RequestMethods;
