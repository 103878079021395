import { useEffect, useState } from "react";
import { Accordion, Button, Col, Modal, Row, Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Clipboard, Plus, Trash } from "react-feather";
import OPCUA_Logo_Gross from "../../assets/img/logos/opc_logo.png";
import SingleIoTDataPointModal from "../Assets/IoT_Data_Setup/SingleIoTDataPointModal";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";

const GatewayOPCUABrowserModal = ({ show, asset_id, handleClose, mode }) => {
  const { t, i18n } = useTranslation();

  const [configData, setConfigData] = useState();
  const [showDataPointModal, setShowDataPointModal] = useState(null);
  const [OPC_Browser_Data, set_OPC_Browser_Data] = useState();
  const [isLoadingOPCUACall, setIsLoadingOPCUACall] = useState(false)

  useEffect(() => {
    if (show && mode) {
      handleIoTOverview();
      loadConfigs();
    }
  }, [show, mode]);

  function handleIoTOverview() {
    setIsLoadingOPCUACall(true);
    axios.get(`/assets/${asset_id}/iot_overview`, { params: { iter_mode: mode } })
      .then(response => {
        set_OPC_Browser_Data(response.data);
        setFilteredData(groupData(response.data?.nodes));
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoadingOPCUACall(false));
  }

  function loadConfigs() {
    axios.get("/assets/" + asset_id + "/iot_configs")
      .then(response => setConfigData(response.data))
      .catch(error => console.log(error));
  }

  const renderNodeValue = (value) => {
    if (Array.isArray(value)) {
      // Join array values with a comma or render as list
      return value.join(", ");
      // Or for a more structured format:
      // return <ul>{value.map((val, i) => <li key={i}>{val}</li>)}</ul>;
    } else if (typeof value === 'boolean') {
      // Convert boolean to readable format
      return value ? "True" : "False";
    }
    // Directly return string and other types
    return value;
  };

  function groupData(listData) {
    // Initialize an empty object for grouped data
    const groupedData = listData?.reduce((acc, obj) => {
      const key = obj.category || "Other";
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {}) || {}; // Ensure acc is an empty object if listData or listData.nodes is undefined

    // Transform into the desired structure
    return Object.entries(groupedData).map(([key, value]) => ({
      category: key,
      items: value,
    }));
  }

  const [filteredData, setFilteredData] = useState([]);

  function handleDelete(e, delete_id) {
    e.preventDefault();

    console.log("To be deleted", delete_id)

    axios
      .delete("/assets/" + asset_id + "/iot_nodes/plc_node_id/" + delete_id)
      .then(function (response) {
        console.log(response.status, "/iot_nodes deleted");
        // handle success
        loadConfigs();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      style={{ zIndex: 1050 }} // Set z-index higher than the default
    >
      <Modal.Body>
        {showDataPointModal && <SingleIoTDataPointModal
          desired_protocol={"opc_ua"}
          asset_id={asset_id}
          handleClose={() => {
            loadConfigs();
            setShowDataPointModal(null);
          }}
        />}

        <Row>
          <Col className="mt-1">
            {isLoadingOPCUACall
              ? <div className="text-center mb-3">
                <img
                  src={OPCUA_Logo_Gross}
                  alt="avatar"
                  className="my-4"
                  width="auto"
                  height={100}
                />
                <br />
                <Spinner animation="border" variant="secondary" className="mb-4" />
                <div className="h3">{t("Browsing OPC UA Server")}</div>
                <div>{t("This will take a few seconds. Lean back!")}</div>
              </div>
              : <div>

                <div className="text-center">
                  <img
                    src={OPCUA_Logo_Gross}
                    alt="avatar"
                    className="mb-2"
                    width="auto"
                    height={100}
                  />
                  <div className="mb-4">{t("We found the following categories and OPC UA data points in the connected PLC.")}</div>

                  <div className="mb-4">{t("Last update")}: <strong>{OPC_Browser_Data == null ? "-" : OPC_Browser_Data.time === null ? "-" : format(parseISO(OPC_Browser_Data?.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })}</strong> (Mode: <strong>{OPC_Browser_Data?.iter_mode || "-"}</strong>)</div>

                  {/* <Dropdown align={{ lg: 'end' }} className="d-inline">
                    <Dropdown.Toggle variant="light" className="bg-white shadow-sm me-1">
                      <Filter className="feather align-middle" /> {t(filterButtonTitle)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => filterBy("new", t("Only show new node ids"))}>{t("Only show new node ids")}</Dropdown.Item>
                      <Dropdown.Item onClick={() => filterBy("all", t("All"))}>{t("All")}</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                </div>

                <div className="h4 mb-3">{t("Categories")}</div>
                <Accordion>
                  {
                    filteredData?.length > 0
                      ? filteredData?.map((item, indx) => <Accordion.Item key={indx} eventKey={indx} className="bg-white">

                        <Accordion.Header>{item?.category}</Accordion.Header>
                        <Accordion.Body className="bg-white">
                          <Table responsive hover>
                            <thead>
                              <tr>
                                <th scope="col">{t("Name")}</th>
                                <th scope="col">{t("Datentyp")}</th>
                                <th scope="col">{t("Beispielwert")}</th>
                              </tr>
                            </thead>
                            <tbody>{item?.items?.map((node, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {/* <span>
                                      <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                          <Popover>
                                            <Popover.Body>
                                              {node?.plc_node_id}
                                            </Popover.Body>
                                          </Popover>
                                        }
                                      >
                                        <Info className="feather me-2" />
                                      </OverlayTrigger>
                                    </span> */}
                                    {node?.plc_node_id || "-"}
                                  </td>
                                  <td><code>{node.data_type || "-"}</code></td>
                                  <td>{renderNodeValue(node.example_value || "-")}</td>
                                  <td>
                                    <div className="d-flex">
                                      <Button
                                        onClick={() => navigator.clipboard.writeText(node?.plc_node_id?.replace(/\\/g, '\\\\').replace(/"/g, '\\"'))}
                                        variant="outline-primary"
                                        className="shadow-sm me-1"
                                        size="sm"
                                      >
                                        <Clipboard className="feather me-1" /> {t("Copy ID")}
                                      </Button>

                                      {
                                        configData?.items?.find((item) => item?.log_type?.plc_node_id === node?.plc_node_id)
                                          ? <Button
                                            onClick={e => window.confirm(t("Are you sure you wish to delete this data point?")) && handleDelete(e, node?.nodeId)}
                                            variant="outline-danger"
                                            className="shadow-sm"
                                            size="sm"
                                          >
                                            <Trash className="feather me-1" /> {t("Remove")}
                                          </Button>
                                          : <Button
                                            onClick={() => setShowDataPointModal(node?.plc_node_id)}
                                            variant="success"
                                            className="shadow-sm"
                                            size="sm"
                                          >
                                            <Plus className="feather me-1" /> {t("Add")}
                                          </Button>
                                      }
                                    </div>

                                  </td>
                                </tr>
                              );
                            })}
                            </tbody>
                          </Table>
                        </Accordion.Body>
                      </Accordion.Item>
                      )
                      : t("No data found. Please start light or full browsing before checking the results.")
                  }

                </Accordion>
              </div>}
          </Col>
        </Row>
        <hr />
        <br />
        <Row>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button variant="outline-secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
          </Col>
        </Row>
      </Modal.Body >
    </Modal >
  );
};

export default GatewayOPCUABrowserModal;
