import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Dropdown, Form, Modal, OverlayTrigger, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { parseISO } from "date-fns";
import UserDataManager from "../Helper/UserDataManager";
import { Crosshair, Plus } from "react-feather";
import { isMobile } from "react-device-detect";
import NotyfContext from "../../contexts/NotyfContext";

const AddClientModal = (props) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  const notyf = useContext(NotyfContext);

  var client_name_TF = "";
  var client_address_TF = "";
  var client_registration_number_TF = "";
  var client_tax_number_TF = "";
  var website = "";

  function handleChangeName(event) {
    client_name_TF = event.target.value;
  }
  function handleChangeAddress(event) {
    client_address_TF = event.target.value;
  }
  function handleChangeRegistrationNo(event) {
    client_registration_number_TF = event.target.value;
  }
  function handleChangeTaxNo(event) {
    client_tax_number_TF = event.target.value;
  }
  function handleChangeWebsite(event) {
    website = event.target.value;
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (client_name_TF === "") {
      setError({ showMessage: true, errorData: null, customMessage: t("Please enter a client name.") })
    } else {
      setError({ showMessage: false, errorData: null })

      axios
        .post("/clients/", {
          business_name: client_name_TF,
          address: client_address_TF,
          registration_number: client_registration_number_TF,
          tax_number: client_tax_number_TF,
          website: website
        })
        .then(function (response) {
          // handle success
          console.log(response.status, "Load");
          navigate("/clients/" + response.data.id);

          notyf.open({
            type: "success",
            message: t("New item \"") + client_name_TF + t("\" has been added."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })

        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    }
  }

  return (
    <>
      <Button
        onClick={() => toggle()}
        variant="primary"
        className="shadow-sm me-1">
        <Plus className="feather" /> {t("New")}
      </Button>
      <Modal size="xl" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Create client")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Business Name")}</Form.Label>
                  <Form.Control
                    placeholder="thyssenkrupp AG"
                    required
                    onChange={handleChangeName}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Website")}</Form.Label>
                  <Form.Control placeholder="https://" onChange={handleChangeWebsite} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Address")}</Form.Label>
                  <Form.Control placeholder="Bergstr. 23, 10115 Berlin" onChange={handleChangeAddress} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Commercial register number")}</Form.Label>
                  <Form.Control placeholder="HR B12345" onChange={handleChangeRegistrationNo} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Tax Number")}</Form.Label>
                  <Form.Control placeholder="312/5324/23" onChange={handleChangeTaxNo} />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="outline-secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Create")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddClientModal;
