import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Table, ProgressBar, Dropdown } from "react-bootstrap";
import { Button } from "react-bootstrap";
import React, {
  useEffect,
  useState,
} from "react";
import { MoreHorizontal, Trash } from "react-feather";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { format, parseISO } from 'date-fns';
import InvoiceTable from "../Invoices/InvoiceTable";
import AssetTable from "../Assets/AssetTable";
import { useTranslation } from "react-i18next";
import FileTable from "../FileManagement/FileTable";
import { de } from "date-fns/locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ErrorHandler from "../Helper/ErrorHandler";
import UserDataManager from "../Helper/UserDataManager";
import ReactFlow, {
  Controls,
} from 'reactflow';

import 'reactflow/dist/style.css';
import GenericChartMultipleLines from "../Helper/GenericChartMultipleLines";
import EditContractModal from "./ContractModal_Edit";
import SingleAssetModal from "../Assets/SingleAssetModal";
import AddAdditionalService from "./AdditionalService_Add";
import ContractPermissionsModal from "./ContractPermissionsModal";

const ContractDetails = () => {

  let { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [filesData, setFilesData] = useState([]);
  const [amortization, setAmortization] = useState([]);
  const [timeProgress, setTimeProgress] = useState([]);
  const [financials, setFinancials] = useState([]);
  const [services, setServices] = useState([]);
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  function getStatus(status) {
    switch (status) {
      case "approved":
        return t("Approved")
      case "active":
        return t("Active")
      case "request":
        return t("Request")
      case "inactive":
        return t("Inactive")
      case "draft":
        return t("Draft")
      default:
        return t(status)
    }
  }

  useEffect(() => {

    axios
      .get("webservice/contracts/" + id)
      .then(function (response) {
        // handle success
        console.log(response.status, "Load contract");

        setData(response.data);
        setFilesData(response.data.files);
        setInvoiceData(response.data.invoices);
        setAccountManagers(response.data.permitted_users);
        setAmortization(response.data.amortization_progress);
        setFinancials(response.data.financials);
        setTimeProgress(response.data.time_progress);
        setAssetData(response.data.assets.sort((a, b) => a.title > b.title));
        setServices(response.data.services);
        // setNextPaymentData(response.data.next_payment)
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }, []);

  function handleDelete(e) {
    e.preventDefault();
    axios
      .delete("/contracts/" + id)
      .then(function (response) {
        // handle success
        console.log(response.status, "Deleted contract");
        navigate(-1);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const loadHeader = () => {

    if ("assets" in data) {

      var GesamtInvestitionsVolumen = 0
      for (var i = 0; i < data["assets"].length; i++) {
        GesamtInvestitionsVolumen += data["assets"][i]["investment_price"]
      }

      return (

        <Col md="3">
          <Card className="flex-fill">
            <Card.Body>
              <div className="flex-grow-1">
                <h3 className="mb-3">{amortization?.toLocaleString(i18n.language === "de" ? de : "en", { style: "percent", maximumFractionDigits: 0, })}</h3>
                {/* <h3>{t("Amortization")}</h3> */}
                <ProgressBar
                  className="mb-3"
                  variant="primary"
                  striped
                  animated
                  now={amortization * 100}
                  label="Amortization"
                />
                <p className="mb-3">{t("Amortization")}</p>
              </div>
            </Card.Body>
          </Card>
          <Card className="flex-fill">
            <Card.Body>
              <div >
                {/* <h3 className="mb-3">{t("Duration")}</h3> */}
                <h3 className="mb-3">{timeProgress?.toLocaleString(i18n.language === "de" ? de : "en", { style: "percent", maximumFractionDigits: 0, })}</h3>
                <ProgressBar
                  className="mb-3"
                  variant="primary"
                  striped
                  animated
                  now={timeProgress * 100}
                  label={t("Duration")}
                />
                <p className="mb-3">{t("Duration")}</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      )
    } else {
      return null
    }
  }

  const [accountManagers, setAccountManagers] = useState(null);

  const ShowAccountManagersList = () => {

    if (accountManagers === null) {
      console.log("huhuhu")
      return (null)
    } else {
      return accountManagers?.map((manager) => {
        return (

          // <li className="mb-1">
          //   <MapPin width={14} height={14} className="me-1" /> From{" "}
          //   <Link to="/dashboard/default">Boston</Link>
          // </li>

          <div className="mb-1" key={manager?.avatar_url} onClick={e => navigate(`/users/${manager.id}`)} style={{ cursor: "pointer" }}>
            <img
              src={manager?.avatar_url}
              alt="avatar"
              className="me-3 text-center rounded-circle"
              width={40} height={40}
            />
            {manager.first_name} {manager.last_name}
            {/* <User  width={14} height={14} className="me-1" /> */}
            {/* <Link to={`/users/${manager.id}`}></Link> */}
            {/* {" "} <Trash2 width={14} height={14} className="me-1" onClick={e => window.confirm(t("Are you sure you wish to remove this owner?")) && handleRemoveOwner(manager?.id)}/> */}
            {/* {" "} <Mail width={14} height={14} className="me-1" onClick={e => open("mailto:support@cap-on.de?subject=cap-on%20Support&body=")} /> */}
          </div>
        );
      })
    }
  }

  function handleDeleteAdditionalService(e, service_id) {
    e.preventDefault();

    axios
      .delete("/contracts/" + id + "/services/" + service_id)
      .then(function (response) {
        console.log(response.status, "Deleted service");
        // handle success
        refreshPage();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  const ContractFinancialsGraph = () => {
    // const nodeDefaults = {
    //   sourcePosition: Position.Right,
    //   targetPosition: Position.Left,
    // };

    var user_label = data?.client_name // + "\n (AaaS-User)"
    var investor_label = (data?.investor?.name || "Investor") + "\n(" + financials?.total_investor_profit?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'currency', maximumFractionDigits: 2, currency: 'EUR', }) + ")"

    const initialNodes = [
      {
        id: '1',
        style: {
          backgroundColor: "#EBF8FF"
        },
        position: { x: 0, y: 0 },
        data:
        {
          label:
            user_label
        }
      },
      {
        id: '2', style: {
          backgroundColor: "#EBF8FF"
        }, position: { x: 0, y: 150 }, data: { label: investor_label }
      }, //, ...nodeDefaults,
      {
        id: '3', style: {
          backgroundColor: "#EBF8FF"
        }, position: { x: 0, y: 300 }, data: { label: 'Refi / SPV' },
      },
      {
        id: '4', style: {
          backgroundColor: "#EBF8FF"
        }, position: { x: 200, y: 300 }, data: { label: 'cap-on' },
      },
    ];

    const initialEdges = [
      { id: 'e1-2', source: '1', target: '2', label: financials?.total_client_payments?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'currency', maximumFractionDigits: 2, currency: 'EUR', }), animated: true, },
      { id: 'e1-2', source: '2', target: '3', label: financials?.total_refi_payments?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'currency', maximumFractionDigits: 2, currency: 'EUR', }), animated: true, },
      { id: 'e1-2', source: '2', target: '4', label: financials?.total_capon_revenue?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'currency', maximumFractionDigits: 2, currency: 'EUR', }), animated: true, },
    ];

    return (
      // <Row>
      //   <Col md="12">
      //     <Card>
      //       <Card.Header>
      //         <Card.Title>
      //           {t("Financials")}
      //         </Card.Title>
      //       </Card.Header>
      //       <Card.Body>
      <div style={{ width: '100%', height: '35vh' }}>
        <ReactFlow
          nodes={initialNodes}
          edges={initialEdges}
          fitView
        >
          <Controls />
        </ReactFlow>
      </div>

      //       </Card.Body>
      //     </Card>
      //   </Col>
      // </Row>
    )
  }

  const loadAvatar = (url) => {
    return (
      <img
        src={url == null ? null : url}
        className="center"
        alt="avatar"
        width={25} height={25}
      />);
  };

  const ContractFinancials = () => {
    return (
      <Row>
        <Col md="6" className="d-flex">
          <Card className="flex-fill w-100" >
            <Card.Header>
              <Card.Title>
                {t("Financials")}
              </Card.Title>
            </Card.Header>
            <Card.Body
              style={{
                overflow: "hidden"
              }}>
              <ContractFinancialsGraph />
              {/* <Table>
                <tbody>
                  <tr>
                    <th>{t("total_client_payments")}</th>
                    <td>{financials?.total_client_payments?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency', maximumFractionDigits: 2, currency: 'EUR',
                    })}</td>
                  </tr>
                  <tr>
                    <th>{t("total_investor_profit")}</th>
                    <td>{financials?.total_investor_profit?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency', maximumFractionDigits: 2, currency: 'EUR',
                    })}</td>
                  </tr>
                  <tr>
                    <th>{t("total_refi_payments")}</th>
                    <td>{financials?.total_refi_payments?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency', maximumFractionDigits: 2, currency: 'EUR',
                    })}</td>
                  </tr>
                  <tr>
                    <th>{t("total_capon_revenue")}</th>
                    <td>{financials?.total_capon_revenue?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency', maximumFractionDigits: 2, currency: 'EUR',
                    })}</td>
                  </tr>
                </tbody>
              </Table> */}
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" className="d-flex">
          {/* <Card className="flex-fill w-100"> */}
          {/* <Card.Header>
              <Card.Title>
                {t("Timeline")}
              </Card.Title>
            </Card.Header>
            <Card.Body> */}
          {/* <br />
              {
                financials?.timeline?.map((item) => {
                  return (
                    <div className="mb-1" key={item}>
                      {item?.interval_start}
                    </div>
                  );
                })
              } */}
          {financials?.timeline == null ? "" : GenericChartMultipleLines(
            financials?.timeline?.map((entry) => { return entry?.interval_start }),
            financials?.timeline?.map((entry) => { return entry?.client_total_payment }),
            financials?.timeline?.map((entry) => { return entry?.investor_profit }),
            financials?.timeline?.map((entry) => { return entry?.capon_revenue }),
            (Math.max(...financials?.timeline?.map(o => o?.client_total_payment)) * 1.15),
            t("Timeline"),
            t("Client Payments"),
            t("Investor Profits"),
            t("cap-on Revenue"))}
          {/* </Card.Body> */}
          {/* </Card> */}
        </Col>
      </Row>
    )
  }

  function handleReadInvoices(e) {
    e.preventDefault();

    axios
      .post("/contracts/" + id + "/invoices_paid")
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        refreshPage();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  const [showSingleAsset, setShowSingleAsset] = useState();

  return (
    <React.Fragment>
      <Helmet title={data.title} />
      <Container fluid className="p-0">

        <SingleAssetModal show={showSingleAsset} handleClose={() => setShowSingleAsset(false)} contract_id={id} />

        <Row className="mb-2 mb-xl-3">
          <Breadcrumb>
            {/* <div className="vertical-center-all"> */}
            <Button variant="outline-primary" size="sm" className="me-2" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
            </Button>
            <Breadcrumb.Item href="/contracts">{t("Contracts")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Contract details")}</Breadcrumb.Item>
            {/* </div> */}
          </Breadcrumb>
          <Col xs="auto" className="d-none d-sm-block">
            <h3>{data.title}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            {UserDataManager.getInstance().isAdminOrTenantAdmin() && <Button variant="primary" onClick={() => setShowSingleAsset(true)}>{t("Create asset")}</Button>}
            <Dropdown align={{ lg: 'end' }} className="d-inline">
              <Dropdown.Toggle variant="light" className="bg-white shadow-sm">
                <MoreHorizontal className="feather align-middle mt-n1" /> {t("More")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <EditContractModal />
                <ContractPermissionsModal contract_data={data} />
                <Dropdown.Item onClick={e => window.confirm(t("Are you sure you wish to delete this contract?")) && handleDelete(e)}>{t("Remove contract")}</Dropdown.Item>
                <Dropdown.Divider />
                {UserDataManager.getInstance().isAdmin() && <Dropdown.Item onClick={e => window.confirm(t("Are you sure you wish to mark all invoices of this contract as read?")) && handleReadInvoices(e)}>{t("Mark invoices as paid")}</Dropdown.Item>}
                <Dropdown.Item onClick={refreshPage}>{t("Refresh")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Col md="9" className="d-flex">
            <Card className="flex-fill w-100">
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("About")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Table size="sm" className="my-2">
                  <tbody>
                    <tr>
                      <th>{t("Status")}</th>
                      <td>
                        <span className={data.status === "active" || data.status === "approved" ? "badge bg-success" : "badge bg-warning"}>{getStatus(data.status)}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>{t("Contract number")}</th>
                      <td>{data?.contract_number || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Title")}</th>
                      <td>{data?.title || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Description")}</th>
                      <td>{data?.description || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Contract start date")}</th>
                      <td>{data?.starting_date == null ? "-" : format(parseISO(data.starting_date), "d MMMM yyyy", { locale: i18n.language === "de" ? de : null })}</td>
                    </tr>
                    <tr>
                      <th>{t("Contract end date")}</th>
                      <td>{data?.end_date == null ? "-" : format(parseISO(data.end_date), "d MMMM yyyy", { locale: i18n.language === "de" ? de : null })}</td>
                    </tr>

                    <br />

                    {/* <h4 className="pt-4 mb-4">Asset-as-a-Service Ecosystem</h4> */}
                    <tr>
                      <th>{t("Client")}</th>
                      <td style={{ cursor: "pointer" }} onClick={() => navigate("/clients/" + data?.client_id)}>{data?.client_name || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Manufacturer")}</th>
                      <td style={{ cursor: "pointer" }} onClick={() => navigate("/manufacturers/" + data?.manufacturer_id)}>{data == null ? null : data?.manufacturer?.avatar_url == null ? "" : loadAvatar(data?.manufacturer?.avatar_url)} {" "} {data?.manufacturer?.name || "-"}</td>
                    </tr>
                    {data?.investor_id && <tr>
                      <th>{t("Investor")}</th>
                      <td style={{ cursor: "pointer" }} onClick={() => navigate("/investors/" + data?.investor_id)}>{data == null ? null : data?.manufacturer?.avatar_url == null ? "" : loadAvatar(data?.investor?.avatar_url)} {" "} {data?.investor?.name || "-"}</td>
                    </tr>}
                    <br />
                    {/* <tr>
                      <th>{t("Next Payment (Forecast)")}</th>
                      <td>{nextPaymentData === null || nextPaymentData === undefined || nextPaymentData?.payment_forecast_with_vat === undefined ? "-" : (nextPaymentData?.payment_forecast_with_vat?.toLocaleString(i18n.language === "de" ? de : "en", {
                        style: 'currency',
                        maximumFractionDigits: 0,
                        currency: 'EUR',
                      })
                        + " ("
                        + t("Percent change") + ": "
                        + (nextPaymentData?.percent_change_to_planned_usage?.toLocaleString(i18n.language === "de" ? de : "en", {
                          style: 'percent',
                          maximumFractionDigits: 0,
                        }) || "-")
                        + ")")} </td>
                    </tr> */}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          {loadHeader()}
        </Row>
        <Row>
          <Col md="12">
            <AssetTable data={assetData} title={t("Assets")} slim/>
          </Col>
        </Row>
        {data?.contract_type !== "iot_only" && <Row>
          <Col md="12">
            <Card className="flex-fill w-100">
              <Card.Header>
                <Col xs="auto" className="d-none d-sm-block">
                  <Card.Title tag="h5" className="mb-0">
                    {t("Additional Services")}
                  </Card.Title>
                </Col>
                <Col xs="auto" className="ms-auto text-end mt-n3">
                  <AddAdditionalService contract_id={id} />
                </Col>
              </Card.Header>
              {
                services?.length > 0 ?
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col">{t("Name")}</th>
                        <th scope="col">{t("Price")}</th>
                        <th scope="col">{t("Quantity")}</th>
                        <th scope="col">{t("Total")}</th>
                        {/* <th scope="col">{t("Live View")}</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        services?.map((service) => {
                          return (
                            <tr key={service.id}>
                              <td>{service.name}</td>
                              <td>{service.price?.toLocaleString(i18n.language === "de" ? de : "en", {
                                style: 'currency',
                                currency: 'EUR',
                              })}</td>
                              <td>{service.quantity?.toLocaleString(i18n.language === "de" ? de : "en", {
                                style: 'decimal',
                              })}</td>
                              <td>{service.total?.toLocaleString(i18n.language === "de" ? de : "en", {
                                style: 'currency',
                                currency: 'EUR',
                              })}</td>
                              <td className="table-action">
                                <Trash alt="Delete" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={e => window.confirm(t("Are you sure you wish to delete this service?")) && handleDeleteAdditionalService(e, service.id)} />
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                  : // If no data found

                  <Card.Body>
                    <p className="card-subtitle text-muted">
                      {t("No data found.")}
                    </p>
                  </Card.Body>
              }

            </Card>
          </Col>
        </Row>}
        <Row>
          <Col md="12" className="d-flex">
            {invoiceData == null ? "" : <InvoiceTable data={invoiceData} title="Invoices" isLoading={false} showFullTable={false} />}
          </Col>
          {/* <Col md="6">
            {invoiceData == null ? "" : GenericChart(
              invoiceData.map((entry) => { return entry.total }),
              invoiceData.map((entry) => { return format(parseISO(entry.due_date), "dd MM yyyy", { locale: i18n.language === "de" ? de : null }) }),
              (Math.max(...invoiceData.map(o => o.total)) * 1.15),
              t("Invoices Chart"))}
          </Col> */}

          {/* <Col md="6">
            {invoiceData == null ? "" : GenericChart(
              invoiceData.map((entry) => { return format(parseISO(entry.due_date), "dd MM yyyy", { locale: i18n.language === "de" ? de : null }) }),
              invoiceData.map((entry) => { return entry.total }),
              (Math.max(...invoiceData.map(o => o.total)) * 1.15),
              t("Invoices Chart"),
              "currency",
              "")}
          </Col> */}
        </Row>
        {/* {UserDataManager.getInstance().isAdmin() && <ContractFinancialsGraph />} */}
        {(UserDataManager.getInstance().isAdmin() && data?.contract_type !== "iot_only") && <ContractFinancials />}
        <Row>
          <Col md="12">
            {filesData == null ? "" : <FileTable data={filesData} title={"Documents"} parent_id={data.id} parent_type={"/contracts/"} />}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default ContractDetails;
