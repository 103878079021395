import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { RefreshCw } from "react-feather";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import InvestorTable from "./InvestorTable";
import AddInvestorModal from "./InvestorModal_Add";

const Investors = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setError({ showMessage: false, errorData: null })

    axios
      .get("/investors/", {
        // params: {
        //   "status": "approved_only",
        // }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        setData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setError({ showMessage: true, errorData: error })
        setIsLoading(false);
      });
    }, []);

  return (
    <React.Fragment>
      <Helmet title={t("Investors")} />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("Investors")}</h3>
          </Col>

          <Col xs="auto" className="ms-auto text-end mt-n1">
            {/* <Button
              onClick={() => navigate("/pricing-engine")}
              variant="primary"
              className="shadow-sm me-1"
            >{t("New Asset Request")}</Button> */}
            <AddInvestorModal/>
            <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorHandler error={error} />
            {InvestorTable(data, null, isLoading)}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default Investors;
