import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Container, Dropdown, Form, Modal, Row, Table } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Check, Edit2, Search, Trash2, X } from "react-feather";
import NotyfContext from "../../../contexts/NotyfContext";
import ErrorHandler from "../../Helper/ErrorHandler";
import AsyncSelect from 'react-select/async'
import { components } from "react-select";
import { getIAMBaseURL } from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import avatar1 from "../../../assets/img/avatars/avatar.png";
import { faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import CardDropdown from "../../../components/CardDropdown";
import UserDataManager from "../../Helper/UserDataManager";
import { Helmet } from "react-helmet-async";
import { format, parseISO } from "date-fns";
import { de } from "faker/lib/locales";

const TenantDetails = () => {

  const { t, i18n } = useTranslation();
  const [selectedFile, setSelectedFile] = useState();
  const hiddenFileInput = React.useRef(null);
  const [data, setData] = useState([]);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const notyf = useContext(NotyfContext);
  const [tenantData, setTenantData] = useState([]);
  const [tenantUsers, setTenantUsers] = useState([]);
  let { id } = useParams();

  useEffect(() => {
    // setTenantData(props?.tenant_data)
    refreshTenantData();
    refreshTenantUsers();
  }, []);

  function refreshTenantUsers() {
    axios
      .get(getIAMBaseURL() + "/tenants/" + id + "/users/")
      .then(function (response) {
        // handle success
        console.log(response.status, "tenant users");
        setTenantUsers(response.data?.filter(item => item?.role !== "device"));
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  function refreshTenantData() {
    axios
      .get(getIAMBaseURL() + "/tenants/" + id)
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        setTenantData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  function loadAvatar() {
    axios
      .get(getIAMBaseURL() + "/users/me")
      .then(function (response) {
        // handle success
        console.log(response.status, "/users/me ------> 5");
        UserDataManager.getInstance().setUserData(response.data);
        setData(response.data);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const showAvatar = () => {
    return (
      <img
        src={data.avatar == null ? avatar1 : data.avatar.url}
        className="rounded-circle img-responsive mt-2"
        width="128"
        height="128"
        alt="avatar"
      />);
  };

  // const [ClientArray, setClientArray] = useState([]);

  // useEffect(() => {
  //   loadAvatar()

  //   //   axios
  //   //     .get("/webservice/clients/")
  //   //     .then(function (response) {
  //   //       // handle success
  //   //       console.log(response.status, "Load");
  //   //       setClientArray(response.data);
  //   //     })
  //   //     .catch(function (error) {
  //   //       // handle error
  //   //       console.log(error);
  //   //     });
  // }, []);

  // const ShowClientsAvatars = () => {

  //   return ClientArray.map((client) => {

  //     if (client.avatar_url !== null) {
  //       return (
  //         <img
  //           src={client == null ? null : client.avatar_url}
  //           key={client.id}
  //           alt="avatar"
  //           className="me-3"
  //           width={40} height={40}
  //         />
  //       );
  //     }
  //     return (<></>)
  //   });
  // }

  // const ShowClientsList = () => {

  //   return ClientArray.map((client) => {
  //     return (
  //       <tr>
  //         <th>{t("Your company")}</th>
  //         <td>{client === undefined ? "" : client.business_name}</td>
  //       </tr>
  //     );
  //   });
  // }

  function uploadAvatar(e) {
    e.preventDefault();

    console.log("->", selectedFile);

    if (selectedFile === undefined) {
      setError({ showMessage: true, errorData: error, customMessage: t("Please select a new avatar first") })
    } else {
      var formData = new FormData();
      formData.append("file", selectedFile);

      axios
        .put(getIAMBaseURL() + "/users/me/avatar", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(function (response) {
          // handle success
          console.log("File uploaded");
          notyf.open({
            type: "success",
            message: t("Updated avatar successfully"),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })
          loadAvatar();
        })
        .catch(function (error) {
          // handle error
          // window.confirm(error)
          console.log(error);
          setError({ showMessage: true, errorData: error })
        });
    }
  }

  const ShowAccountManagersList = () => {

    return (
      <Table size="sm" responsive hover>
        <thead>
          <tr>
            <th scope="col">{""}</th>
            <th scope="col">{t("Email")}</th>
            <th scope="col">{t("Admin")}</th>
          </tr>
        </thead>
        <tbody>{tenantUsers?.map((user) => {
          return (
            <tr key={user?.id} style={{ cursor: "pointer" }}>
              <td><img
                src={user?.avatar_url}
                alt="avatar"
                className="me-3 text-center rounded-circle"
                width={40} height={40}
              /></td>
              <td>{user.email}</td>
              <td>{user.is_tenant_admin ? <Check /> : <X />}</td>
            </tr>
          )
        })}</tbody>
      </Table>
    )
  }


  return (
    <React.Fragment>
      <Helmet title={t("Tenant")} />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h1 className="h3 mb-3">{t("Tenant")}</h1>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            {/* <Button>test</Button> */}
            {/* <Editu id={data?.id} title={t("Edit")} pw_disabled/> */}
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            {/* <Card>
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {data.role === "regular" ? t("Your company") : t("Your clients")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <ShowClientsAvatars />
              </Card.Body>
            </Card> */}
            <Card className="flex-fill">
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("About")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Table size="sm" className="my-2">
                  <tbody>
                    <tr>
                      <th>{t("Name")}</th>
                      <td>{tenantData?.name || "-"}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <Card.Body>
                <ShowAccountManagersList />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default TenantDetails;
