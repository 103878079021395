import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { format, parseISO } from "date-fns";
import NotyfContext from "../../contexts/NotyfContext";
import { de } from "date-fns/locale";
import { getCoreBaseURL } from "../../config";

const EditContractModal = (props) => {
  let { id } = useParams();
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  const notyf = useContext(NotyfContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (openModal) {
      axios
        .get("/contracts/" + id)
        .then(function (response) {
          // handle success
          console.log(response.status, "/contracts");
          setData(response.data);
          setContractType(response.data?.contract_type)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      axios
        .get("/clients/")
        .then(function (response) {
          // handle success
          console.log(response.status, "Load clients");
          setClients(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      axios
        .get("/investors/")
        .then(function (response) {
          // handle success
          console.log(response.status, "Load investors");
          setInvestors(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      axios
        .get("/manufacturers")
        .then(function (response) {
          // handle success
          console.log(response.status, "Load manufacturers");
          setManufacturerArray(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  }, [id, openModal]);

  const [investors, setInvestors] = useState([]);
  const [clients, setClients] = useState([]);
  const [ManufacturerArray, setManufacturerArray] = useState([]);

  const [title, setTitle] = useState(data.title);
  const [description, setDescription] = useState(data.description);
  const [start, setStart] = useState(data.starting_date);
  const [end, setEnd] = useState(data.end_date);
  const [client, setClient] = useState(data.client_id);
  const [investor, setInvestor] = useState(data.investor_id);
  const [manufacturer, setManufacturer] = useState(data.manufacturer_id);
  const [contractType, setContractType] = useState(data.contract_type);
  const [ContractTypeOptions, setContractTypeOptions] = useState();

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    axios
      .put("/contracts/" + id, {
        title: title,
        starting_date: start,
        end_date: end,
        description: description,
        client_id: client,
        investor_id: investor,
        contract_type: contractType,
        manufacturer_id: manufacturer,
        // bill_to_investor: billToInvestor,
        // "contract_type": null,
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");

        notyf.open({
          type: "success",
          message: t("Item \"") + title + t("\" updated."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        refreshPage();
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  function refreshPage() {
    window.location.reload(false);
  }

  const loadClients = () => {
    return clients?.map((client) => {
      return (
        <option key={client.id} value={client.id} selected={client.id === data.client_id}>{client.business_name}</option>
      );
    });
  };

  const loadInvestors = () => {
    return investors?.map((investor) => {
      return (
        <option key={investor.id} value={investor.id} selected={investor.id === data.investor_id}>{investor.name}</option>
      );
    });
  };

  useEffect(() => {
    axios
      .get(getCoreBaseURL() + "/openapi.json")
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        setContractTypeOptions(response.data["components"]["schemas"]["ContractType"]["enum"]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

  }, []);

  const ShowContractTypes = () => {
    return ContractTypeOptions.map((option) => {
      return (
        <option key={option} value={option} selected={option === contractType}>{t(option)}</option>
      );
    });
  };

  const loadManufaturers = () => {
    return ManufacturerArray.map((manufacturer) => {
      return (
        <option key={manufacturer.id} value={manufacturer.id} selected={manufacturer.id === data.manufacturer_id}>{manufacturer.name}</option>
      );
    });
  };

  return (
    <>
      <Dropdown.Item onClick={() => toggle()}>{t("Edit contract")}</Dropdown.Item>
      <Modal size="xl" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Edit contract")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Title")}</Form.Label>
                  <Form.Control
                    placeholder={t("ABC Contract")}
                    required
                    defaultValue={data.title}
                    onChange={e => setTitle(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Description")}</Form.Label>
                  <Form.Control
                    placeholder={t("Description")}
                    defaultValue={data.description}
                    onChange={e => setDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Contract start")}</Form.Label>
                  <Form.Control
                    type="date"
                    defaultValue={data.starting_date == null ? null : format(parseISO(data.starting_date), "yyyy-MM-dd", { locale: i18n.language === "de" ? de : null })}
                    onChange={e => setStart(parseISO(e.target.value))} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Contract end")}</Form.Label>
                  <Form.Control type="date"
                    defaultValue={data.end_date == null ? null : format(parseISO(data.end_date), "yyyy-MM-dd", { locale: i18n.language === "de" ? de : null })}
                    onChange={e => setEnd(parseISO(e.target.value))} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {/* <Col md={6}>
                <Form.Check
                  checked={billToInvestor}
                  onChange={e => setBillToInvestor(!billToInvestor)}
                  label={t("Always send bill to investor")}
                  type="checkbox" />
              </Col> */}
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Contract Type")}</Form.Label>
                  <Form.Select name="state" onChange={(e) => setContractType(e.target.value)}>
                    <option>{t("-- Select --")}</option>
                    <ShowContractTypes/>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <br /><br />
            <hr />
            <h4>Asset-as-a-Service Ökosystem</h4>
            <br />
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Client")}</Form.Label>
                  <Form.Select name="state" defaultValue={data.client_id} onChange={e => setClient(e.target.value === "" ? null : e.target.value)}>
                    <option value="">{t("-- Select --")}</option>
                    {loadClients()}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Manufacturer")}</Form.Label>
                  <Form.Select name="state" defaultValue={data.manufacturer_id} onChange={(e) => setManufacturer(e.target.value === "" ? null : e.target.value)}>
                    <option value="">{t("-- Select --")}</option>
                    {loadManufaturers()}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Investors")}</Form.Label>
                  <Form.Select name="state" defaultValue={data.investor_id} onChange={e => setInvestor(e.target.value === "" ? null : e.target.value)}>
                    <option value="">{t("-- Select --")}</option>
                    {loadInvestors()}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="outline-secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Apply")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditContractModal;
