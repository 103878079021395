import { Badge, Card, Spinner, Table } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../Helper/LoadingSpinner";

const ContractTable = (data, title, isLoading) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function getStatus(status) {
    switch (status) {
      case "approved":
        return t("Approved")
      case "active":
        return t("Active")
      case "request":
        return t("Request")
      case "inactive":
        return t("Inactive")
      case "draft":
        return t("Draft")
      default:
        return t(status)
    }
  }

  const renderTable = () => {
    return data.map((contract) => {
      return (
        <tr key={contract.id} onClick={() => navigate("/contracts/" + contract.id)} style={{ cursor: "pointer" }}>
          <td>
            <Badge bg="" className={contract.status === "active" || contract.status === "approved" ? "badge-soft-success me-2" : "badge-soft-warning me-2"} >{getStatus(contract.status)}</Badge>
          </td>
          {/* <td>{contract?.contract_number}</td> */}
          <td>{contract?.title}</td>
          <td>{contract?.client_name || "-"}</td>
          {/* <td>{contract.assets.length}</td> */}
          {/* <td>{GesamtInvestitionsVolumen.toLocaleString(i18n.language === "de" ? de : "en", {
            style: 'currency',
            currency: 'EUR',
          })}</td>
          <td>
            <span className={NumOfUnpaidInvoices === 0 ? "badge bg-success" : "badge bg-warning"}>{NumOfUnpaidInvoices}</span>
          </td> */}
        </tr>
      );
    });
  };

  return (
    <Card className="flex-fill w-100">
      {
        title === null ?
          <div />
          :
          <Card.Header>
            <Card.Title tag="h5" className="mb-0">
              {t(title)}
            </Card.Title>
          </Card.Header>
      }
      {/* <Card.Body> */}
      {
        isLoading ?
        <LoadingSpinner withText/> :
          data != null && data.length > 0
            ?
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">{t("Status")}</th>
                  {/* <th scope="col">#</th> */}
                  <th scope="col">{t("Title")}</th>
                  <th scope="col">{t("Client")}</th>
                  {/* <th scope="col">No. of Assets</th>
                  <th scope="col">Investment</th>
                  <th scope="col">Pending Invoices</th> */}
                </tr>
              </thead>
              <tbody>{renderTable()}</tbody>
            </Table>
            :
            <Card.Body>
              <p>{t("No data found.")}</p>
            </Card.Body>
      }
      {/* </Card.Body> */}
    </Card>
  );
};

export default ContractTable;
