import { Badge, Card, Spinner, Table } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import de from "date-fns/locale/de";
import { ArrowUpRight } from "react-feather";
import LoadingSpinner from "../Helper/LoadingSpinner";

const InvestorTable = (data, title, isLoading) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  function getStatus(status) {
    switch (status) {
      case "approved":
        return t("Approved")
      case "active":
        return t("Active")
      case "request":
        return t("Request")
      case "inactive":
        return t("Inactive")
      case "draft":
        return t("Draft")
      default:
        return t(status)
    }
  }

  const loadAvatar = (url) => {
    return (
      <img
        src={url == null ? null : url}
        className="center"
        alt="avatar"
        width={25} height={25}
      />);
  };

  const renderTable = () => {
    return data.map((item) => {
      return (
        <tr key={item.id} onClick={() => navigate("/investors/" + item.id)} style={{ cursor: "pointer" }}>
          {/* <td>{item?.investor_number}</td> */}
          <td >{item == null ? null : loadAvatar(item?.avatar_url)}</td>
          <td>{item?.name}</td>
          {/* <td>{item?.contracts?.length}</td> */}
        </tr>
      );
    });
  };

  return (
    <Card className="flex-fill w-100">
      {
        title === null ?
          <></>
          :
          <Card.Header>
            <Card.Title tag="h5" className="mb-0">
              {t(title)}
            </Card.Title>
          </Card.Header>
      }
      {/* <Card.Body> */}
      {
        isLoading ?
        <LoadingSpinner withText/> :
          data != null && data.length > 0
            ?
            <Table responsive hover>
              <thead>
                <tr>
                  {/* <th scope="col">{t("Status")}</th> */}
                  {/* <th scope="col">#</th> */}
                  <th scope="col"></th>
                  <th scope="col">{t("Name")}</th>
                  {/* <th scope="col">{t("Verträge")}</th> */}
                  {/* <th scope="col">{t("Live View")}</th> */}
                </tr>
              </thead>
              <tbody>{renderTable()}</tbody>
            </Table>
            :
            <Card.Body>
              <p>{t("No data found.")}</p>
            </Card.Body>
      }
      {/* </Card.Body> */}
    </Card>
  );
};

export default InvestorTable;
