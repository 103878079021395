import { format, parseISO } from "date-fns";
import { de } from 'date-fns/locale';
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserDataManager from "../pages/Helper/UserDataManager";
import { isProdEnvironment } from "../config";

const Footer = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  function getEnvironmentAbbreviation() {
    if (isProdEnvironment()) {
      return "PROD"
    } else if (process.env.REACT_APP_ENVIRONMENT === "TEST") {
      return "TEST"
    } else if (process.env.NODE_ENV === "development") {
      return "DEV (localhost)"
    } else {
      return "n/a"
    }
  }

  function getEnvironmentBadgeType() {
    if (isProdEnvironment()) {
      return "badge bg-success"
    } else if (process.env.REACT_APP_ENVIRONMENT === "TEST") {
      return "badge bg-warning"
    } else if (process.env.NODE_ENV === "development") {
      return "badge bg-warning"
    } else {
      return "badge bg-warning"
    }
  }

  const CustomerOrUserSince = () => {

    if (UserDataManager.getInstance().getUserData() === null) {
      console.log("No user data...")
    } else if (UserDataManager.getInstance().getUserRole() === "regular") {

      // axios
      // .get("/clients/" + UserDataManager.getInstance().getUserData().id)
      // .then(function (response) {
      //   // handle success
      //   console.log(response.status, "Load clients");
      //   setData(response.data);
      //   // setFilesData(response.data);
      // })
      // .catch(function (error) {
      //   // handle error
      //   setError({ showMessage: true, errorData: error })
      //   console.log(error);
      // });

      return (
        <li className="list-inline-item">
          <span className="text-muted" href="#">
            {t("• You're a user since ")} {UserDataManager.getInstance().getUserData()?.created_at && format(parseISO(UserDataManager.getInstance().getUserData().created_at), "dd MMM yyyy", { locale: i18n.language === "de" ? de : null })}
          </span>
        </li>
      )
    } else if (UserDataManager.getInstance().getUserData().created_at !== null & UserDataManager.getInstance().getUserData().created_at !== undefined) {

      return (
        <li className="list-inline-item">
          <span className="text-muted" href="#">
            {t("• You're a user since ")} {format(parseISO(UserDataManager.getInstance().getUserData().created_at), "dd MMM yyyy", { locale: i18n.language === "de" ? de : null })}
          </span>
        </li>
      )
    } else {
      return <></>
    }
  }

  return (
    <footer className="footer">
      <Container fluid>
        <Row className="text-muted">
          <Col xs="9" className="text-start">
            <ul className="list-inline">
              <li className="list-inline-item" onClick={() => window.open("mailto:support@cap-on.de?subject=cap-on%20Supportanfrage")} style={{ cursor: "pointer" }}>
                <span className="text-muted" href="#">
                  {t("Support")}
                </span>
              </li>
              {/* <li className="list-inline-item">
              <span className="text-muted" href="#">
                Help Center
              </span>
            </li> */}
              <li className="list-inline-item">
                <span className="text-muted" onClick={() => navigate("/privacy-policy")} style={{ cursor: "pointer" }}>
                  {t("Privacy Policy")}
                </span>
              </li>
              <li className="list-inline-item">
                <span className="text-muted" href="#">
                  {t("Terms of Service")}
                </span>
              </li>
              <CustomerOrUserSince />
              {
                isProdEnvironment() ?
                  <></>
                  :
                  <li className="list-inline-item">
                    <span className="text-muted" href="#">
                      {t("• Environment: ")}
                    </span>
                    <span className={getEnvironmentBadgeType()} href="#">
                      {getEnvironmentAbbreviation()}
                    </span>
                  </li>
              }
              <li className="list-inline-item">
                <span className="text-muted" href="#">
                  {t("Version: ")} {process.env.REACT_APP_APP_VERSION}
                </span>
              </li>
            </ul>
          </Col>
          <Col xs="3" className="text-end">
            <p className="mb-0">
              &copy; {new Date().getFullYear()} {" "}
              <span className="text-muted" onClick={() => window.open("https://cap-on.de")} style={{ cursor: "pointer" }}>
                cap-on GmbH
              </span>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
