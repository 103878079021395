import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Col, Dropdown, Form, Modal, OverlayTrigger, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { parseISO } from "date-fns";
import UserDataManager from "../Helper/UserDataManager";
import { Crosshair, Plus } from "react-feather";
import { isMobile } from "react-device-detect";
import NotyfContext from "../../contexts/NotyfContext";
import { getIAMBaseURL } from "../../config";
import GatewayVPNContentModal from "./GatewayVPNContentModal";
import AddContractModal from "../Contracts/ContractModal_Add";

const AddFullGatewayModal = (props) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  const [gateway_serial, set_gateway_serial] = useState();
  const [asset_title, set_asset_title] = useState();
  const [tenant_id, set_tenant_id] = useState();
  const [contract_id, set_contract_id] = useState();

  const [tenants, setTenants] = useState([]);
  const [contracts, setContracts] = useState([]);

  useEffect(() => {

    axios
      .get(getIAMBaseURL() + "/tenants/")
      .then(function (response) {
        // handle success
        setTenants(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    axios
      .get("/contracts/")
      .then(function (response) {
        // handle success
        console.log(response.status, "/contracts");
        setContracts(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    if (gateway_serial === "") {
      setError({ showMessage: true, errorData: null, customMessage: t("Please enter a gateway serial.") })
    } else {
      setError({ showMessage: false, errorData: null })

      // hier sollte man im dropdown einen Kunden auswählen können, dessen ID dann im Post-Request gesetzt wird

      let body = {
        // "tenant_id": tenant_id,
        "title": asset_title,
        "contract_id": contract_id,
        "gateway_serial": gateway_serial
      }

      console.log("Request body", body)
      axios
        .post(`/webservice/assets/full/?tenant_id=${tenant_id}`, body)
        .then(function (response) {
          // handle success
          console.log(response.status, "Added full gateway");

          let env_content = response?.data?.dot_env

          // Create a blob with the env_content and download it as a .env file without extra spaces
          if (env_content) {
            const trimmedContent = env_content.split('\n').map(line => line.trim()).join('\n');
            const blob = new Blob([trimmedContent], { type: "text/plain" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `${response?.data?.iot_gateway?.serial}.env`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }

          setResponseJSON({
            content: response.data
          })
          setShowContentModal(true)

          toggle(false)

          notyf.open({
            type: "success",
            message: t("New item \"") + gateway_serial + t("\" has been added."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })

        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    }
  }

  const [responseJSON, setResponseJSON] = useState()
  const [showContentModal, setShowContentModal] = useState()

  return (
    <>
      <GatewayVPNContentModal data={responseJSON} asset_id={id} show={showContentModal} handleClose={() => { setShowContentModal(false) }} />

      <Button
        onClick={() => toggle()}
        variant="primary"
        className="shadow-sm" // me-1
      >
        <Plus className="feather" /> {t("Gateway / Asset / User Combination")}
      </Button>
      <Modal size="ms" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Create Gateway")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>

          <Form onSubmit={handleSubmit}>

            <Row>
              <Col>
                <Alert variant="success" className="mb-4">
                  <div className="alert-message">
                    🧙🏽‍♂️ Das hier ist etwas <strong>magisch</strong>. Es werden automatisch ein Gateway, ein Asset und ein User angelegt und jeweils miteinander verbunden. Abrakadabra 🪄. Cool.
                  </div>
                </Alert>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Gateway Serial")}</Form.Label>
                  <Form.Control
                    placeholder="CBIOT2050X__"
                    defaultValue={"CBIOT2050X"}
                    required
                    onChange={(e) => set_gateway_serial(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Asset name")}</Form.Label>
                  <Form.Control
                    placeholder={t("Enter a name for your asset.")}
                    required
                    onChange={(e) => set_asset_title(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Tenant")}</Form.Label>
                  <Form.Select name="state" onChange={(e) => set_tenant_id(e.target.value)}>
                    <option >{t("-- Select --")}</option>
                    {tenants?.map((item) => {
                      return (
                        <option key={item?.id} value={item?.id}>{item?.name}</option>
                      )
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Contract")}</Form.Label>
                  <Form.Select name="state" onChange={(e) => set_contract_id(e.target.value)}>
                    <option >{t("-- Select --")}</option>
                    {contracts?.map((contract) => {
                      return (
                        <option key={contract.id} value={contract.id}>{contract.title}</option>
                      )
                    })}
                  </Form.Select>
                  <AddContractModal type="link" />
                </Form.Group>
              </Col>


              {/* <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Passcode")}</Form.Label>
                  <Form.Control
                    placeholder={t("8-letter code")}
                    onChange={handleChangePasscode}
                  />
                </Form.Group>
              </Col> */}
            </Row>
            {/* <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Tailscale Node-ID")}</Form.Label>
                  <Form.Control
                    placeholder="Tailscale ID"
                    onChange={handleChangeTailscale_node_id}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Internal IP")}</Form.Label>
                  <Form.Control
                    placeholder={t("Internal IP")}
                    onChange={handleChangeInternal_ip}
                  />
                </Form.Group>
              </Col>
            </Row> */}
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="outline-secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Create")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddFullGatewayModal;
