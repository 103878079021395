import { Badge, Button, Card, Col, Container, Row, Spinner, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { format, parseISO, startOfYear } from "date-fns";
import { de } from "date-fns/locale";
import ShowStatus from "./SystemHealth";
import { AlertTriangle, Maximize2, Minimize2, RefreshCw, Wifi } from "react-feather";
import { useFullScreen } from "react-browser-hooks/lib/hooks/fullscreen";
import useSidebar from "../../hooks/useSidebar";
import { useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import usePalette from "../../hooks/usePalette";
import { isMobile } from "react-device-detect";
import { getLogsBaseURL } from "../../config";
import LoadingSpinner from "../Helper/LoadingSpinner";

const Tech360 = () => {
  const { t, i18n } = useTranslation();
  const [DBdata, setDBData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const { toggle: toggleFullscreen, fullScreen } = useFullScreen()
  const { setIsOpen } = useSidebar();
  // let { fullScreenMode } = useParams();
  const [availabilities, setAvailabilities] = useState([]);
  const [prominentAvailabilities, setProminentAvailabilities] = useState([]);
  const [gateways, setGateways] = useState([]);
  const navigate = useNavigate();
  const [errorLogCount400Data, setErrorLogCount400Data] = useState([]);
  const [errorLogCount500Data, setErrorLogCount500Data] = useState([]);
  const [IoTApplicationLogData, setIoTApplicationLogData] = useState([]);

  var start_date = new Date( new Date() - 60 * 60 * 24 * 28 * 1000 ).toISOString()

  const Live_Data_INTERVAL = 120000; // 2 mins
  // const Usage_Counter_INTERVAL = 10000;

  useEffect(() => {

    reloadData()

    const interval = setInterval(() => {
      console.log('Reloading Data');
      reloadData();
    }, Live_Data_INTERVAL);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  function reloadData() {

    setIsLoading(true);
    setError({ showMessage: false, errorData: null })

    axios
      .get(getLogsBaseURL() + "/availabilities/", {
        period_start: start_date,
        // period_end: zonedTimeToUtc(parseISO(new Date(new Date()).toISOString().split('T')[0]), "GMT").toISOString(),
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Load. Start date: ", start_date);
        setAvailabilities(response.data);
        setProminentAvailabilities(response.data.filter(item => String(item.service_name).includes("_prod")));
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        setIsLoading(false);
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });

    axios
      .get("/status/db/")
      .then(function (response) {
        // handle success
        console.log(response.status, "/360");
        setDBData(response.data);
      })
      .catch(function (error) {
        // handle error
        setIsLoading(false);
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });

    axios
      .get("/webservice/iot_gateways/")
      .then(function (response) {
        // handle success
        console.log(response.status, "/gateways");
        setGateways(response.data);
      })
      .catch(function (error) {
        // handle error
        setIsLoading(false);
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });

    var qs = require('qs');

    axios
      .get(getLogsBaseURL() + "/access_logs/count", {
        params: {
          response_status_min: 400,
          response_status_exclude: [401, 403],
          response_status_max: 500,
          clustering_unit: "30 minutes",
          // environment_name: "PROD"
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      })
      .then(function (response) {
        // handle success
        setErrorLogCount400Data(response.data.reverse())
        console.log(response.status, "/logs");
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        // setError({ showMessage: true, errorData: error })
        setIsLoading(false);
      });

    axios
      .get(getLogsBaseURL() + "/access_logs/count", {
        params: {
          response_status_min: 500,
          response_status_max: 600,
          clustering_unit: "30 minutes",
          // environment_name: "PROD"
        }
      })
      .then(function (response) {
        // handle success
        setErrorLogCount500Data(response.data.reverse())
        console.log(response.status, "/logs");
        // console.log(response.status.filter(e => { return e.response_status_min > 399 }), "/logs");
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        // setError({ showMessage: true, errorData: error })
        setIsLoading(false);
      });

    axios
      .get(getLogsBaseURL() + "/application_logs/count", {
        params: {
          application_name: "iot_gateway",
          level: "ERROR",
          clustering_unit: "30 minutes",
          // environment_name: "PROD"
        }
      })
      .then(function (response) {
        // handle success
        setIoTApplicationLogData(response.data.reverse())
        console.log(response.status, "/logs");
        // console.log(response.status.filter(e => { return e.response_status_min > 399 }), "/logs");
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        // setError({ showMessage: true, errorData: error })
        setIsLoading(false);
      });
  }

  const renderBackups = () => {
    return DBdata?.backups?.map((item) => {
      return (
        <tr key={item.id}>
          <td >{format(parseISO(item.time), "dd MMM yyyy HH:mm", { locale: i18n.language === "de" ? de : null })}</td>
          <td >{(item.size / 1000000).toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 })} MB</td>
          {/* <td >{item.encrypted.toString()}</td> */}
        </tr>
      );
    });
  };

  const renderCluster = () => {
    return DBdata?.cluster?.map((item) => {
      return (
        <tr key={item.id}>
          <td >{item.state}</td>
          <td >{item.name}</td>
          <td >{item.host}</td>
          <td >{item.lag}</td>
          <td >{item.role}</td>
        </tr>
      );
    });
  };

  const renderAvailability = () => {
    return availabilities?.map((item) => {
      return (
        <tr key={item.id}>
          <td >{t(item.service_name)}</td>
          <td >
            <Badge bg="" className={renderAvailabilityState(item.availability)}>{item?.availability?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 3 })}</Badge>
          </td>
        </tr>
      );
    });
  };

  const LogChart = (props) => {
    const palette = usePalette();
    const { t, i18n } = useTranslation();

    const data = [
      {
        name: t("400 Errors"),
        data: props?.array400?.map((e) => { return e.count_logs }),
      },
      {
        name: t("500 Errors"),
        data: props?.array500?.map((e) => { return e.count_logs }),
      },
      {
        name: t("IoT Gateway Logs"),
        data: props?.iot_gw_logs?.map((e) => { return e.count_logs }),
      },
      // {
      //   name: t(props.operatingTimes[1]?.state_name),
      //   data: props.operatingTimes[1]?.clustered_operating_times.map((e) => { return e.seconds }),
      // },
    ];

    const options = {
      chart: {
        // animations: {
        //   enabled: false,
        // },
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          // endingShape: "rounded",
          columnWidth: "100%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: [2, 2, 2],
        colors: ["transparent"],
      },
      labels: props?.array400?.map((e) => { return format(parseISO(e.interval_start), "HH:mm", { locale: i18n.language === "de" ? de : null }) }),
      xaxis: {
        tickAmount: 10,
        title: {
          text: t("Last 24h"),
        },
        // type: "time",
        // max: 100,
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          hideOverlappingLabels: true,
          style: {
            colors: palette.black,
          },
        },
      },
      yaxis: {
        title: {
          text: "Errors",
        },
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
          formatter: (value) => { return value.toLocaleString() },
        },
      },
      legend: {
        position: 'top',
        labels: {
          colors: palette.black,
          useSeriesColors: false
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      colors: [
        palette.warning,
        palette.danger,
        palette.info,
      ],
    };

    return (

      <Card className="flex-fill">
        {/* <Card.Header>
          <Card.Title tag="h5">{t("Errors (Code 400-599)")}</Card.Title>
        </Card.Header> */}
        <Card.Body>
          <Row>
            <Col className="mt-0">
              <span className="d-inline-block mb-4">
                <Card.Title tag="h5">{t("Errors (Code 400-599)")}</Card.Title>
              </span>
            </Col>
            <Col xs="auto">
              <div className="stat stat-sm" onClick={() => navigate("/system_logs/?response_status_min=400&response_status_max=599")} style={{ cursor: "pointer" }}>
                <AlertTriangle className="align-middle text-success" />
              </div>
            </Col>
          </Row>

          {
            props?.logArray?.length === 0
              ?
              <h6 className="card-subtitle text-muted">
                {t("No operating times available.")}
              </h6>
              :
              <Chart options={options} series={data} type="bar" height="200" />
          }
        </Card.Body>
      </Card>
    );
  };

  const GatewayAvailabilities = () => {

    var active_inactive_gateways = gateways.filter(gateway => gateway.connection_status === "connected").length / gateways.length;

    return (
      <Card className="flex-fill w-100">
        <Card.Body className=" py-3">
          <Row>
            <Col className="mt-0">
              <span className="h1 d-inline-block mt-1 mb-4"></span>

              <span className="d-inline-block mt-1 mb-4">
                <h1>
                    <Badge bg="" className={renderGatewayState(active_inactive_gateways)}>{isNaN(parseFloat(active_inactive_gateways)) ? "0" : active_inactive_gateways.toLocaleString(i18n.language === "de" ? de : "en", {
                    style: 'percent',
                  })}</Badge>
                  </h1>
              </span>
            </Col>
            <Col xs="auto">
              <div className="stat stat-sm" onClick={() => navigate("/gateways/")} style={{ cursor: "pointer" }}>
                <Wifi className="align-middle text-success" />
              </div>
            </Col>
          </Row>
          <div className="mb-0">
            <span className="text-muted">{t("Connected Gateways")}</span>
          </div>
        </Card.Body>
      </Card>
    )
  }

  function handleClickFullScreen() {
    toggleFullscreen()
    setIsOpen(fullScreen ? true : false)
  }

  const renderGatewayState = (score) => {
    return score >= 0.99 ? "badge-soft-success me-2" : "badge-soft-warning me-2"
  }

  const renderErrorState = (score) => {
    return score >= 10 ? "badge-soft-danger me-2" : "badge-soft-success me-2"
  }

  const renderAvailabilityState = (score) => {
    return score >= 0.999 ? "badge-soft-success me-2" : "badge-soft-warning me-2"
  }

  const AvailabilityTile = (availability) => {

    return (
      <Col sm="6" lg="3" className="d-flex col-xxl-3">
        <Card className="flex-fill">
          <Card.Body className=" py-3">
            <Row>
              <Col className="mt-0">
                <span className="d-inline-block mt-1 mb-4">
                  <h1>
                    <Badge bg="" className={renderAvailabilityState(availability.availability)}>{availability.availability.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 3 })}</Badge>
                  </h1>
                </span>
              </Col>
            </Row>
            <div className="mb-0">
              <span className="text-muted">{t(availability.service_name)}</span>
            </div>
          </Card.Body>
        </Card>
      </Col>
    )
  }

  return (

    <React.Fragment>
      <Helmet title={t("cap-on Tech 360°")} />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("cap-on Tech 360°")}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            {
              !isMobile && <Button
                onClick={() => handleClickFullScreen()}
                variant={fullScreen ? "outline-primary" : "primary"}
                className="shadow-sm me-1">
                {
                  fullScreen ?
                    <><Minimize2 className="feather" />  {t('Stop fullscreen')} </> :
                    <><Maximize2 className="feather" />  {t('Fullscreen')} </>
                }
              </Button>
            }
            <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm">
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>
        {
          isLoading ?
            <LoadingSpinner />
            : <><Row>
              <Col>
                <ErrorHandler error={error} />
              </Col>
            </Row>

              {/* Production Instances Availabilites */}
              <Row>
                {
                  prominentAvailabilities?.map(availability => {
                    return (AvailabilityTile(availability))
                  })
                }
              </Row>

              {/* Gateway Availabilty Tile | Errors Tiles | Chart */}
              <Row>
                <Col md="6">

                  <Row>
                    {/* Gateway Availabilty Tile */}
                    <Col md="6" className="d-flex">
                      <GatewayAvailabilities />
                    </Col>
                    <Col md="6" className="d-flex">
                      <Card className="flex-fill w-100">
                        <Card.Body className=" py-3">
                          <Row>
                            <Col className="mt-0">
                              <span className="d-inline-block mt-1 mb-4">
                                <h1>
                                  <Badge bg="" className={renderErrorState(IoTApplicationLogData.reduce((a, v) => a = a + v.count_logs, 0))}>{IoTApplicationLogData.reduce((a, v) => a = a + v.count_logs, 0).toLocaleString(i18n.language === "de" ? de : "en")}</Badge>
                                </h1>
                              </span>
                            </Col>
                            <Col xs="auto">
                              <div className="stat stat-sm" onClick={() => navigate("/application_logs/?application_name=iot_gateway&level=ERROR")} style={{ cursor: "pointer" }}>
                                <AlertTriangle className="align-middle text-success" />
                              </div>
                            </Col>
                          </Row>
                          <div className="mb-0">
                            <span className="text-muted">{t("IoT Gateway Logs")}</span>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>


                  {/* Error Tiles */}
                  <Row>
                    <Col md="6" className="d-flex">
                      <Card className="flex-fill w-100">
                        <Card.Body className=" py-3">
                          <Row>
                            <Col className="mt-0">
                              <span className="d-inline-block mt-1 mb-4">
                                <h1>
                                  <Badge bg="" className={renderErrorState(errorLogCount400Data.reduce((a, v) => a = a + v.count_logs, 0))}>{errorLogCount400Data.reduce((a, v) => a = a + v.count_logs, 0).toLocaleString(i18n.language === "de" ? de : "en")}</Badge>
                                </h1>
                              </span>
                            </Col>
                            <Col xs="auto">
                              <div className="stat stat-sm" onClick={() => navigate("/system_logs/?response_status_exclude=401,403&response_status_min=400&response_status_max=499")} style={{ cursor: "pointer" }}>
                                <AlertTriangle className="align-middle text-success" />
                              </div>
                            </Col>
                          </Row>
                          <div className="mb-0">
                            <span className="text-muted">{t("400er Errors (last 24h)")}</span>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col md="6" className="d-flex">
                      <Card className="flex-fill w-100">
                        <Card.Body className=" py-3">
                          <Row>
                            <Col className="mt-0">
                              <span className="d-inline-block mt-1 mb-4">
                                <h1>
                                  <Badge bg="" className={renderErrorState(errorLogCount500Data.reduce((a, v) => a = a + v.count_logs, 0))}>{errorLogCount500Data.reduce((a, v) => a = a + v.count_logs, 0).toLocaleString(i18n.language === "de" ? de : "en")}</Badge>
                                </h1>
                              </span>
                            </Col>
                            <Col xs="auto">
                              <div className="stat stat-sm" onClick={() => navigate("/system_logs/?response_status_min=500")} style={{ cursor: "pointer" }}>
                                <AlertTriangle className="align-middle text-success" />
                              </div>
                            </Col>
                          </Row>
                          <div className="mb-0">
                            <span className="text-muted">{t("500er Errors (last 24h)")}</span>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Col>

                {/* Chart Tiles */}
                <Col md="6" className="d-flex">
                  <LogChart array400={errorLogCount400Data} array500={errorLogCount500Data} iot_gw_logs={IoTApplicationLogData} />
                </Col>
              </Row>

              {/* Show Instances Status */}
              <ShowStatus />

              {/* DB Stuff */}
              <Row>

                {/* DB Clusters */}
                <Col md="6">
                  <Card className="flex-fill w-100">
                    <Card.Header>
                      <Card.Title tag="h5" className="mb-0">
                        {t("DB Cluster")}
                      </Card.Title>
                    </Card.Header>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th scope="col">{t("Status")}</th>
                          <th scope="col">{t("Name")}</th>
                          <th scope="col">{t("host")}</th>
                          <th scope="col">{t("lag")}</th>
                          <th scope="col">{t("role")}</th>
                        </tr>
                      </thead>
                      <tbody>{renderCluster()}</tbody>
                    </Table>
                  </Card>
                </Col>

                {/* DB Info */}
                <Col md="3">
                  <Card className="flex-fill w-100">
                    <Card.Header>
                      <Card.Title tag="h5" className="mb-0">
                        {t("DB Info")}
                      </Card.Title>
                    </Card.Header>
                    {/* <Card.Body> */}
                    <Table>
                      <tbody>
                        <tr>
                          <th>Postgres Version</th>
                          <td>{DBdata?.postgres_version}</td>
                        </tr>
                        <tr>
                          <th>Timescale Version</th>
                          <td>{DBdata?.timescale_version}</td>
                        </tr>
                        <tr>
                          <th>{t("Size")}</th>
                          <td>{(DBdata?.size / 1000000).toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2 })} MB</td>
                        </tr>
                      </tbody>
                    </Table>
                    {/* </Card.Body> */}
                  </Card>
                </Col>

                {/* DB Backups */}
                <Col>
                  <Card className="flex-fill w-100">
                    <Card.Header>
                      <Card.Title tag="h5" className="mb-0">
                        {t("DB Backups")}
                      </Card.Title>
                    </Card.Header>
                    {/* <Card.Body> */}
                    <Table>
                      <thead>
                        <tr>
                          <th scope="col">{t("Time")}</th>
                          <th scope="col">{t("Size")}</th>
                          {/* <th scope="col">{t("Encrypted")}</th> */}
                        </tr>
                      </thead>
                      <tbody>{renderBackups()}</tbody>
                    </Table>
                    {/* </Card.Body> */}
                  </Card>
                </Col>
              </Row>

              {/* All Instance Availabilities */}
              <Row>
                <Col md="12">
                  <Card className="flex-fill w-100">
                    <Card.Header>
                      <Card.Title tag="h5" className="mb-0">
                        {t("Availabilities")} ({t("since")} {format(parseISO(start_date), 'd MMMM y', { locale: i18n.language === "de" ? de : null })})
                      </Card.Title>
                    </Card.Header>
                    {/* <Card.Body> */}
                    <Table responsive>
                      <thead>
                        <tr>
                          <th scope="col">{t("Service")}</th>
                          <th scope="col">{t("Availability")}</th>
                        </tr>
                      </thead>
                      <tbody>{renderAvailability()}</tbody>
                    </Table>
                    {/* </Card.Body> */}
                  </Card>
                </Col>
              </Row>
            </>
        }

      </Container>
    </React.Fragment >
  )
};

function refreshPage() {
  window.location.reload(false);
}

export default Tech360;