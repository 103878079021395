import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Col,
  Row,
  Table,
  Modal,
  Form,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Trash2, Edit2 } from "react-feather";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { getIAMBaseURL } from "../../config";

const AddPermissionModal = (props) => {
  let { id } = useParams();
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const [openModal, setOpenModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState();

  function getRolesForPageType(type) {
    switch (type) {
      case "clients":
        return ['cap-on agent', 'regular']
      case "manufacturers":
        return ['cap-on agent', 'regular']
      case "investors":
        return ['cap-on agent', 'regular']
      default:
        return []
    }
  }

  useEffect(() => {

    var qs = require('qs');

    axios
      .get(getIAMBaseURL() + "/users/", {
        params: {
          roles: getRolesForPageType(props.type)
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Loaded users");
        setUsers(response.data);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }, []);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    console.log("->", id, userId);

    axios
      .put("/" + props.type + "/" + id + "/add_owner?user_id=" + userId)
      .then(function (response) {
        // handle success
        console.log(response.status, "Edited successfully");
        toggle(false)
        refreshPage();
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const load = () => {
    return users.map((user) => {
      return (
        <option key={user.id} value={user.id}>{user.first_name} {user.last_name}</option>
      );
    });
  };

  const ShowAccountManagersList = () => {

    return (

      <Table size="sm" responsive hover>
        <thead>
          <tr>
            <th scope="col">{""}</th>
            <th scope="col">{t("Name")}</th>
            <th scope="col">{t("Position")}</th>
            <th scope="col">{t("Role")}</th>
            <th scope="col">{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>{props?.accountManagers?.map((manager) => {
          return (
            <tr key={manager?.id} style={{ cursor: "pointer" }}>
              <td><img
                src={manager?.avatar_url}
                alt="avatar"
                className="me-3 text-center rounded-circle"
                width={40} height={40}
              /></td>
              <td>{manager.first_name} {manager.last_name}</td>
              <td>{manager.position}</td>
              <td>{manager.role}</td>
              <td><Trash2 width={14} height={14} className="me-1" onClick={e => window.confirm(t("Are you sure you wish to remove this owner?")) && handleRemoveOwner(manager?.id)} style={{ cursor: "pointer" }} /></td>
            </tr>

            // <div className="mb-1" key={manager?.avatar_url}>
            //   <img
            //     src={manager?.avatar_url}
            //     alt="avatar"
            //     className="me-3 text-center rounded-circle"
            //     width={40} height={40}
            //   />
            //   {/* <User width={14} height={14} className="me-1" /> */}
            //   {manager.first_name} {manager.last_name} ({manager.position})
            //   {" "} <Trash2 width={14} height={14} className="me-1" onClick={e => window.confirm(t("Are you sure you wish to remove this owner?")) && handleRemoveOwner(manager?.id)} style={{ cursor: "pointer" }} />
            //   {/* {" "} <Mail width={14} height={14} className="me-1" onClick={e => open("mailto:support@cap-on.de?subject=cap-on%20Support&body=")} /> */}
            // </div>
          )
        })}</tbody>
      </Table>
    )
  }

  function handleRemoveOwner(userId) {

    axios
      .put("/" + props.type + "/" + id + "/remove_owner?user_id=" + userId)
      .then(function (response) {
        // handle success
        console.log(response.status, "Edited successfully");
        toggle(false)
        refreshPage();
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
      });
  }

  return (
    <>
      <br />
      <Button
        variant={"outline-primary"}
        size="sm"
        onClick={() => toggle()}
        className="me-2"
      >
        <Edit2 className="feather" /> {t("Edit")}
      </Button>
      <Modal show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Edit User Permission")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <p className="mb-0">
            Hier kannst du die Account Manager bearbeiten.
          </p>
          <br />
          <ShowAccountManagersList />
          <br />
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <Form.Label>{t("Add user")}</Form.Label>
                <Form.Select name="state" onChange={(e) => setUserId(e.target.value)}>
                  <option value="" selected disabled>{t("-- Select --")}</option>
                  {load()}
                </Form.Select>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => toggle(false)}>
            {t("Cancel")}
          </Button>{" "}
          <Button variant={"primary"} onClick={handleSubmit}>
            {t("Apply")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default AddPermissionModal;
