import React from "react";
import { Helmet } from "react-helmet-async";
import { Alert, Button, Card, Form } from "react-bootstrap";
import mainLogo from "../../assets/company/cap-on_logo_black.png";
import mainLogoLight from "../../assets/company/cap-on_logo.png";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import { getIAMBaseURL } from "../../config";

const ForgotPasswordPage = () => {
  const { theme } = useTheme(); //onClick={setTheme(THEME.DEFAULT)}
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const { resetPassword } = useAuth();

  return (
    <React.Fragment>
      <Helmet title="Confirm Sign Up" />
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center mt-4">
              {theme === "dark"
                ? <img src={mainLogoLight} padding-top="50" alt="cap-on" style={{ height: "160px", paddingTop: "10px", paddingBottom: "10px" }} />
                : <img src={mainLogo} padding-top="50" alt="cap-on" style={{ height: "160px", paddingTop: "10px", paddingBottom: "10px" }} />
              }
            </div>
            <div className="text-center mt-2">
              <h1 className="h2">{t("Forgot Password?")}</h1>
              <p className="lead">
                {t("Please enter your email to reset your password.")}
              </p>
            </div>
            <br />
            <Formik
              initialValues={{
                email: "",
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
              })}
              onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                axios({
                  method: "post",
                  url: getIAMBaseURL() + "/reset_password/request/" + values.email,
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                  }
                })
                  .then(function (response) {
                    // handle success
                    console.log(response.status, "Load");
                    navigate("/sign-in");
                  })
                  .catch(function (error) {
                    // handle error
                    const message = error.message || "Something went wrong";

                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setSubmitting(false);
                    console.log("->", error);
                  })
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert className="my-3" variant="danger">
                      <div className="alert-message">{errors.submit}</div>
                    </Alert>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Email")}</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      label="Email Address"
                      value={values.email}
                      isInvalid={Boolean(touched.email && errors.email)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.email && (
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <div className="text-center mt-3">
                    <br />
                    <Button
                      type="cancel"
                      variant="outline-primary"
                      className="shadow-sm me-1"
                      onClick={() => navigate(-1)}
                      size="lg"
                    >
                      {t("Cancel")}
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      size="lg"
                      disabled={isSubmitting}
                    >
                      {t("Reset password")}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  )
};

export default ForgotPasswordPage;
