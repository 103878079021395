import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Dropdown, Form, Modal, OverlayTrigger, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { parseISO } from "date-fns";
import UserDataManager from "../Helper/UserDataManager";
import { Crosshair, Plus } from "react-feather";
import { isMobile } from "react-device-detect";
import NotyfContext from "../../contexts/NotyfContext";

const AddManufacturerModal = (props) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const [name, setName] = useState("");
  const [registration_number, set_registration_number] = useState("");
  const [tax_number, set_tax_number] = useState("");
  const [website, setWebsite] = useState("");

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    axios
      .post("/manufacturers/", {
        name: name,
        registration_number: registration_number,
        tax_number: tax_number,
        website: website
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        navigate("/manufacturers/" + response.data.id);

        notyf.open({
          type: "success",
          message: t("New item \"") + name + t("\" has been added."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })

        console.log(error);
      });
  }

  return (
    <>
      <Button
        onClick={() => toggle()}
        variant="primary"
        className="shadow-sm me-1">
        <Plus className="feather" /> {t("New")}
      </Button>
      <Modal size="xl" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Create manufacturer")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>

          <Form onSubmit={handleSubmit}>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Business Name")}</Form.Label>
                <Form.Control required onChange={(e) => setName(e.target.value)} placeholder="Name" />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Registration Number")}</Form.Label>
                <Form.Control onChange={(e) => set_registration_number(e.target.value)} placeholder="HRB 1234" />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Tax Number")}</Form.Label>
                <Form.Control onChange={(e) => set_tax_number(e.target.value)} placeholder="ABC 123 DEF" />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Website")}</Form.Label>
                <Form.Control onChange={(e) => setWebsite(e.target.value)} placeholder="https://" />
              </Form.Group>
            </Col>
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="outline-secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Create")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddManufacturerModal;
