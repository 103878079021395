import axios from "axios";
import React, { useEffect, useState } from "react";

function ActionType(props) {
  const [data, setData] = useState([]);

  useEffect(() => {

    axios
      .get("/openapi.json")
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        setData(response.data["components"]["schemas"]["ActionType"]["enum"]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

  }, []);

  return data?.map((name) => {
    return (
      <option key={name} value={name}>{name}</option>
    );
  });
};

export default ActionType;
