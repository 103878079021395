import axios from "axios";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Table, Spinner, Form, Dropdown } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { RefreshCw } from "react-feather";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import UserList from "../Helper/UserList";
import ApplicationNames from "../Helper/ApplicationName";
import LogLevels from "../Helper/LogLevels";
import EnvironmentNames from "../Helper/EnvironmentNames";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { formatDistanceToNow, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { getIAMBaseURL, getLogsBaseURL } from "../../config";
import LoadingSpinner from "../Helper/LoadingSpinner";

const SystemApplicationLogs = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [logData, setLogData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const queryParams = new URLSearchParams(window.location.search)
  const [limit, setLimit] = useState();
  const [period_start, setPeriod_start] = useState((new Date(new Date() - 24 * 60 * 60 * 1000 * 7)).toISOString().split('.')[0]);
  const [period_end, setPeriod_end] = useState();
  const [level, setLevel] = useState(queryParams.get("level"));
  const [environmentName, setEnvironmentName] = useState(queryParams.get("environment_name"));
  const [trace_id, setTrace_id] = useState(queryParams.get("trace_id"));
  const [username, setUsername] = useState(queryParams.get("username"));
  const [application_name, setApplication_name] = useState(queryParams.get("application_name"));
  const [searchParams, setSearchParams] = useSearchParams();
  const [skip, setSkip] = useState(queryParams.get("skip"));

  const updateSearchParams = (event) => {
    const { name, value } = event?.target;
    setSearchParams({ [name]: value })
  }

  useEffect(() => {
    loadLogs(null);
  }, []);

  function loadLogs(event = null, { newSkip = null } = {}) {
    // Prevent default behavior if an event is passed
    if (event) {
      event.preventDefault();
    }

    // If newSkip is passed, update the skip state
    if (newSkip !== null) {
      setSkip(newSkip);
    }

    setIsLoading(true);
    setError({ showMessage: false });

    axios
      .get(getLogsBaseURL() + "/application_logs/", {
        params: {
          limit: limit === "" ? 50 : limit,  // Default limit to 50 if not specified
          skip: newSkip !== null ? newSkip : skip,  // Use newSkip if provided
          period_start: period_start === "" ? null : period_start,
          period_end: period_end === "" ? null : period_end,
          trace_id: trace_id === "" ? null : trace_id,
          username: username === "" ? null : username,
          application_name: application_name === "" ? null : application_name,
          environment_name: environmentName === "" ? null : environmentName,
          level: level === "" ? null : level
        }
      })
      .then(function (response) {
        // handle success
        setLogData(response.data)
        console.log(response.status, "/logs");
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setError({ showMessage: true, errorData: error })
        setIsLoading(false);
      });
  }
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10); // Set this based on your total results

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const newSkip = (page - 1) * (limit || 50);  // Calculate new skip value
    loadLogs(null, { newSkip });  // Call loadLogs with the new skip value
  };

  function Pagination({ currentPage, totalPages, onPageChange }) {
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }

    return (
      <div className="pagination">
        {currentPage > 1 && (
          <Button variant="outline-secondary" size="sm" className="shadow-sm me-1" onClick={() => onPageChange(currentPage - 1)}>{t("Previous")}</Button>
        )}
        {pages.map((page) => (
          <Button
            key={page}
            variant="outline-secondary" size="sm" className="shadow-sm me-1"
            onClick={() => onPageChange(page)}
            disabled={page === currentPage}
          >
            {page}
          </Button>
        ))}
        {currentPage < totalPages && (
          <Button variant="outline-secondary" size="sm" className="shadow-sm me-1" onClick={() => onPageChange(currentPage + 1)}>{t("Next")}</Button>
        )}
      </div>
    );
  }

  function resetFields() {
    setLimit("");
    setPeriod_start(new Date(new Date() - 24 * 60 * 60 * 1000 * 7).toISOString().split('.')[0])
    setPeriod_end((new Date()).toISOString().split('.')[0]);
    setTrace_id("");
    setUsername("");
    setApplication_name("");
    setEnvironmentName("");
    setLevel("");
  }

  const renderLogsListTable = () => {
    return logData.map((log) => {
      return (
        <tr key={log.id}>
          {/* <td>{format(parseISO(log.time), 'd MMM, HH:mm:ss', { locale: i18n.language === "de" ? de : null })} (UTC)</td> */}
          <td>{log.time}</td>
          <td>{formatDistanceToNow(parseISO(log?.time), { locale: i18n.language === "de" ? de : null, addSuffix: true })}</td>
          <td>{log.application_name}</td>
          <td>{log.environment_name}</td>
          <td>{log.username}</td>
          <td>{log.trace_id}</td>
          <td>{log.message}</td>
          <td>{log.level}</td>
          <td>{log.file_path}</td>
          <td>{log.host_ip}</td>
        </tr>
      );
    });
  };

  const LogsList = () => (
    <Card>
      {/* <Card.Body> */}
      {
        isLoading === true ?
          <LoadingSpinner withText /> :
          <div>
            <Row>
              <Col xs="auto" className="ms-auto text-center">
                <div className="px-3 py-3 mt-2 mb-2">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </Col>
            </Row>

            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">{t("Timestamp")}</th>
                  <th scope="col">{t("")}</th>
                  <th scope="col">Application</th>
                  <th scope="col">Environment</th>
                  <th scope="col">User</th>
                  <th scope="col">Trace ID</th>
                  <th scope="col">Message</th>
                  <th scope="col">Level</th>
                  <th scope="col">File Path</th>
                  <th scope="col">Host IP</th>
                </tr>
              </thead>
              <tbody>{renderLogsListTable()}</tbody>
            </Table>
          </div>

      }
      {/* </Card.Body> */}
    </Card>
  );

  const [suggestions, setSuggestions] = useState([]); // Store the API suggestions
  const [showDropdown, setShowDropdown] = useState(false); // Whether to show the suggestion dropdown
  let { id } = useParams();
  const [allUsers, setAllUsers] = useState(false)

  useEffect(() => {

    axios
      .get(getIAMBaseURL() + "/users/emails")
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        setAllUsers(response.data.sort((a, b) => (a?.localeCompare(b))));
        // setUsers(response.data.sort((a, b) => (a.role > b.role)));
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  // Function to fetch suggestions from the API
  const fetchSuggestions = (inputValue, field) => {

    console.log("Filtering", inputValue, field,)
    setSuggestions([])
    var filteredSuggestions = [];

    filteredSuggestions = allUsers?.filter(item =>
      item?.toLowerCase().includes(inputValue.toLowerCase()) // Case-insensitive match
    );
    setSuggestions(filteredSuggestions);
    setShowDropdown(true);
  };

  // Debounced API call
  const handleInputChange = (e, field) => {
    const value = e.target.value;

    setUsername(value); // Update the plc_node_id with the input value

    clearTimeout(id);
    id = setTimeout(() => {
      fetchSuggestions(value, field); // Fetch suggestions after 500ms of user input
    }, 500);
  };

  // Handle selecting a suggestion from the dropdown
  const handleSuggestionSelect = (suggestion, field) => {

    setUsername(suggestion)
    setShowDropdown(false); // Hide the dropdown
  };


  return (
    <React.Fragment>
      <Helmet title={t("System Logs")} />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("Application Logs")}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>

        {/* <Row>
          <Col xl="12">
            <LogsChart />
          </Col>
        </Row> */}

        <Card>
          <Card.Header>
            <ErrorHandler error={error} />
          </Card.Header>
          <Card.Body>
            <Form onSubmit={(e) => loadLogs(e)}>
              <Row>
                <Col md={3}>
                  {/* <Form.Group className="mb-3">
                    <Form.Label>{t("Username")}</Form.Label>
                    <Form.Select
                      name="username"
                      onChange={(e) => {
                        updateSearchParams(e)
                        setUsername(e.target.value)
                      }}
                      value={username}>
                      <option value="">{t("-- Select --")}</option>
                      <UserList />
                      <option value="unknown">{t("unknown")}</option>
                    </Form.Select>
                  </Form.Group> */}

<Form.Group className="mb-3">
                    <Form.Label>{t("Username")}</Form.Label>
                    <Form.Control
                      type="text"
                      value={username}
                      placeholder="Search"
                      onChange={handleInputChange} // Update input and fetch suggestions
                    />

                    {showDropdown && suggestions.length > 0 && (
                      <Dropdown.Menu
                        show
                        style={{ width: '100%', position: 'absolute', zIndex: 1000 }}
                      >
                        {suggestions.map((suggestion, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => handleSuggestionSelect(suggestion)}
                          >
                            {suggestion}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Environment")}</Form.Label>
                    <Form.Select
                      name="environment_name"
                      onChange={(e) => setEnvironmentName(e.target.value)}
                      value={environmentName}>
                      <option value="">{t("-- Select --")}</option>
                      <EnvironmentNames />
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Application")}</Form.Label>
                    <Form.Select
                      onChange={(e) => setApplication_name(e.target.value)}
                      name="application_name"
                      value={application_name}>
                      <option value="">{t("-- Select --")}</option>
                      <ApplicationNames />
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Limit")}</Form.Label>
                    <Form.Control
                      type="number"
                      value={limit}
                      onChange={(e) => setLimit(e.target.value)}
                      placeholder="50"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Level")}</Form.Label>
                    <Form.Select
                      onChange={(e) => setLevel(e.target.value)}
                      name="level"
                      value={level}>
                      <option value="">{t("-- Select --")}</option>
                      <LogLevels />
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Trace ID")}</Form.Label>
                    <Form.Control
                      type="text"
                      value={trace_id}
                      onChange={(e) => {
                        updateSearchParams(e)
                        setTrace_id(e.target.value)
                      }}
                      name="trace_id"
                      placeholder="UUID"
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Start")}</Form.Label>
                    <Form.Control
                      type="text"
                      value={period_start}
                      onChange={(e) => setPeriod_start(e.target.value)}
                      placeholder="Start date" />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("End")}</Form.Label>
                    <Form.Control
                      type="text"
                      value={period_end}
                      onChange={(e) => setPeriod_end(e.target.value)}
                      placeholder="End date" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Skip")}</Form.Label>
                    <Form.Control
                      type="number"
                      name="skip"
                      value={skip}
                      onChange={(e) => {
                        updateSearchParams(e)
                        setSkip(e.target.value)
                      }}
                      placeholder="50"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => navigate(-1)}>
                    {t("Back")}
                  </Button>
                  <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => resetFields()}>
                    {t("Reset")}
                  </Button>
                  <Button type="submit" variant="primary"
                  >
                    {t("Query")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        {
          logData.length === 0
            ?
            <Row>
              <Col xl="12">
                <Card>
                  <Card.Header>
                    <Card.Title tag="h5"></Card.Title>
                    <h6 className="card-subtitle text-muted">
                      {t("No data found.")} {t("Run new query.")}
                    </h6>
                  </Card.Header>
                </Card>
              </Col>
            </Row>
            :
            <Row>
              <Col xl="12">
                <LogsList />
              </Col>
            </Row>
        }
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default SystemApplicationLogs;