import React from "react";
import NavbarDropdown from "./NavbarDropdown";
import { AlertTriangle, Bell, DollarSign, HardDrive, Sliders, Wifi } from "react-feather";
import NavbarDropdownItem from "./NavbarDropdownItem";
import { useTranslation } from "react-i18next";

const NavbarAlerts = (alertData, countAll, countUnread) => {

  const { t } = useTranslation();

  return (
    <NavbarDropdown
      header={t("Alerts")}
      footer={t("Show all")}
      footerLink={"/alerts"}
      icon={Bell}
      countAll={countAll}
      countUnread={countUnread}
      showBadge={countUnread > 0 ? true : false}
    >
      {alertData.map((item, key) => {
        let icon = <Bell size={18} className={item.is_read === true ? "text-secondary" : "text-primary"} />;
        let footerLink = "/alerts"
        let detailLink = null

        if (item.source_type === "invoice") {
          icon = <DollarSign size={18} className={item.is_read === true ? "text-secondary" : "text-primary"} />;
          detailLink = "/invoices/" + item.source_id
        }

        if (item.source_type === "application_log") {
          icon = <AlertTriangle size={18} className={item.is_read === true ? "text-secondary" : "text-primary"} />;
          detailLink = "/application_logs/?level=CRITICAL"
        }

        if (item.source_type === "asset") {
          icon = <HardDrive size={18} className={item.is_read === true ? "text-secondary" : "text-primary"} />;
          detailLink = "/assets/" + item.source_id
        }

        if (item.source_type === "iot_gateway") {
          icon = <Wifi size={18} className={item.is_read === true ? "text-secondary" : "text-primary"} />;
          detailLink = "/application_logs/?application_name=iot_gateway" //"/application_logs/" + item.source_id + "/errors"
        }

        if (item.source_type === "system") {
          icon = <Sliders size={18} className={item.is_read === true ? "text-secondary" : "text-primary"} />;
          detailLink = "/configuration/"
        }

        return (
          <NavbarDropdownItem
            alertItem={item}
            key={key}
            icon={icon}
            title={item.title}
            description={item.message}
            footerLink={footerLink}
            detailLink={detailLink}
            time={item.created_at}
          />
        );
      })}
    </NavbarDropdown>
  )
}

export default NavbarAlerts;
