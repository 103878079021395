import {
  Activity,
  HardDrive,
  Briefcase,
  Clipboard,
  DollarSign,
  Users,
  Tool,
  Wifi,
  Cpu,
  Bell,
  Globe,
  Sliders,
  HelpCircle,
  Inbox,
  Database,
  Hash,
  RefreshCcw,
  Crosshair,
  ShoppingBag
} from "react-feather";

const cap_on_Admin_Section_CRM = [
  {
    href: "/clients",
    icon: Briefcase,
    title: "Clients",
  },
  {
    href: "/manufacturers",
    icon: Tool,
    title: "Manufacturers",
  },
  {
    href: "/investors",
    icon: DollarSign,
    title: "Investors",
  },
  // {
  //   href: "/contracts",
  //   icon: Clipboard,
  //   title: "Contracts",
  // },
  // {
  //   href: "/invoices",
  //   icon: DollarSign,
  //   title: "Invoices",
  // },
  {
    icon: Clipboard,
    title: "Contract Management",
    children: [
      {
        href: "/pricing-engine",
        // icon: Hash,
        title: "Pricing Engine",
      },
      {
        href: "/contracts",
        title: "Contracts",
      },
      {
        href: "/invoices",
        // badge: "+",
        title: "Invoices",
      },
    ],
  }
];

const cap_on_Admin_Section_Dashboard = [
  {
    href: "/dashboard",
    icon: Activity,
    title: "Dashboard",
  },
  {
    href: "/alerts",
    icon: Bell,
    title: "Alerts",
  },
]

const cap_on_Admin_Section_Assets = [
  {
    href: "/assets",
    icon: HardDrive,
    title: "Assets",
    // children: [
    //   {
    //     href: "/assets",
    //     title: "Assets",
    //   },
    //   {
    //     href: "/asset-request",
    //     badge: "+",
    //     title: "New Asset Request",
    //   },
    // ],
  },
  // {
  //   href: "/watchtower",
  //   icon: Crosshair,
  //   title: "Watch Tower",
  // },
  // {
  //   href: "/asset-requests",
  //   icon: HardDrive,
  //   // badge: "+",
  //   title: "Asset Requests",
  // },
  // {
  //   href: "/asset/request",
  //   icon: PlusSquare,
  //   // badge: "+",
  //   title: "New Asset Request",
  // },
  {
    href: "/gateways",
    icon: Wifi,
    title: "IoT-Gateways",
  },
  // {
  //   href: "/watchtower",
  //   icon: Crosshair,
  //   title: "Watch Tower",
  // }
]

const cap_on_Tenant_Admin_Section = [
  {
    href: "/users",
    icon: Users,
    title: "User Management",
  },
  // {
  //   href: "/tech-360",
  //   icon: Globe,
  //   title: "Tech 360°",
  // },
  // {
  //   href: "/data-integration",
  //   icon: Database,
  //   title: "API Documenation",
  // },
];

const cap_on_Section_Support = [
  {
    href: "/marketplace",
    icon: ShoppingBag,
    title: "cap-on Marketplace",
  },
  {
    href: "/data-integration",
    icon: Database,
    title: "Client API Documenation",
  },
  // {
  //   href: "/faq",
  //   icon: HelpCircle,
  //   title: "FAQ",
  // },
  {
    href: "/support",
    icon: Inbox,
    // badge: 2,
    title: "Support",
  }
];

const navItems = [
  {
    title: "Home",
    pages: cap_on_Admin_Section_Dashboard,
  },
  {
    title: "Asset Management",
    pages: cap_on_Admin_Section_Assets,
  },
  {
    title: "Asset-as-a-Service Ecosystem",
    pages: cap_on_Admin_Section_CRM,
  },
  {
    title: "Support",
    pages: cap_on_Section_Support,
  },
  // {
  //   title: "Admin Panel",
  //   pages: cap_on_Tenant_Admin_Section,
  // },
];

export default navItems;
