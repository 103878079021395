import React from "react";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/DashboardLayout";
import Public from "./layouts/Public";

//import LandingLayout from "./layouts/Landing";

// Guards
//import AuthGuard from "./components/guards/AuthGuard";

// Landing
// import Landing from "./pages/landing/Landing";

// cap-on
import Dashboard from "./pages/Dashboard/";
import Assets from "./pages/Assets/Assets";
import Contracts from "./pages/Contracts/Contracts";
// import AddAssetUsage from "./pages/Assets/AddAssetUsage";
import AddInvoice from "./pages/Invoices/AddInvoice";
import Clients from "./pages/Clients/Clients";
import PricingEngine from "./pages/PricingEngine/PricingEngine";
import Invoices from "./pages/Invoices/Invoices";
import CommunicationHub from "./pages/CommunicationHub";
import Support from "./pages/Support/Support";
// import Settings from "./pages/Obsolet/Settings";
import AssetDetails from "./pages/Assets/AssetDetails";
import ClientDetails from "./pages/Clients/ClientDetails";
import ContractDetails from "./pages/Contracts/ContractDetails";
import InvoiceDetails from "./pages/Invoices/InvoiceDetails";
import Manufacturers from "./pages/Manufacturers/Manufacturers";
import ManufacturerDetails from "./pages/Manufacturers/ManufacturerDetails";
import Gateways from "./pages/Gateways/Gateways";
import GatewayDetails from "./pages/Gateways/GatewayDetails";
import Handshakes from "./pages/Gateways/Handshakes";
import EditPlannedProduction from "./pages/Assets/EditPlannedProduction";
import GatewayDetailsPublic from "./pages/Gateways/GatewayDetailsPublic";

// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignInPage";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
import SystemAccessLogs from "./pages/Logs/SystemAccessLogs";
import AssetRequestDetails from "./pages/Assets/AssetRequestDetails";
import GatewayConnect from "./pages/Gateways/Gateway Connect";
import SystemHealthPage from "./pages/AdminPanel/SystemHealthPage";
import SystemConfig from "./pages/AdminPanel/SystemConfig";
import SystemApplicationLogs from "./pages/Logs/SystemApplicationLogs";
import SystemAccessLogsCount from "./pages/Logs/SystemAccessLogsCount";
import SystemApplicationLogsCount from "./pages/Logs/SystemApplicationLogsCount";
import AssetLiveView from "./pages/Obsolet/AssetLiveView";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Alerts from "./pages/Alerts/Alerts";
import SystemLogReports from "./pages/Logs/SystemLogReports";
import ChangePassword from "./pages/ChangePassword";
import ConfirmSignInPage from "./pages/auth/ConfirmSignInPage";
import ResetPasswordPage from "./pages/auth/ResetPasswordPage";
import GatewayErrorLog from "./pages/Gateways/GatewayErrorLog";
import Insights from "./pages/Intelligence/Insights";
import AssetUsageDetails from "./pages/Assets/AssetUsageDetails";
import UserDetails from "./pages/User Management/UserDetails";
import FAQ from "./pages/FAQ";
import DataIntegration from "./pages/DataIntegration";
// import Iframe from "./pages/Iframe";
import Tech360 from "./pages/AdminPanel/Tech360";
import WatchTower from "./pages/Watchtower/WatchTower";
import AssetUsageDetailsLog from "./pages/Assets/AssetUsageDetailsLog";
import SystemRoutines from "./pages/AdminPanel/SystemRoutines";
import SystemRoutineDetails from "./pages/AdminPanel/SystemRoutineDetails";
import PCFCalculator from "./pages/Sustainability/PCFCalculator";
import Investors from "./pages/Investors/Investors";
import InvestorDetails from "./pages/Investors/InvestorDetails";
import Users from "./pages/User Management/Users";
import Tenants from "./pages/User Management/Tenant Management/Tenants";
import Marketplace from "./pages/Marketplace/Marketplace";
import Profile from "./pages/User Management/Profile";
import TenantDetails from "./pages/User Management/Tenant Management/TenantDetails";
import AssetDataSetup from "./pages/Assets/IoT_Data_Setup/AssetDataSetup";
import ClientAPI from "./pages/ClientAPI";
import AuditLogs from "./pages/Logs/AuditLogs";

const routes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
      {
        path: "sign-in",
        element: <SignIn />,
      },
    ],

  },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      // {
      //   path: "",
      //   element: <Dashboard />,
      // },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "assets",
        element: <Assets />,
      },
      {
        path: "assets/:id",
        element: <AssetDetails />,
      },
      {
        path: "assets/:id/usage",
        element: <AssetUsageDetails />,
      },
      {
        path: "assets/:id/usage/logs",
        element: <AssetUsageDetailsLog />,
      },
      {
        path: "assets/:id/insights",
        element: <Insights />,
      },
      {
        path: "assets/:id/live",
        element: <AssetLiveView />,
      },
      {
        path: "asset-requests/:id",
        element: <AssetRequestDetails />
      },
      {
        path: "assets/:id/edit/planned_production",
        element: <EditPlannedProduction />,
      },
      // {
      //   path: "assets/:id/addusage",
      //   element: <AddAssetUsage />,
      // },
      {
        path: "investors/",
        element: <Investors />,
      },
      {
        path: "investors/:id",
        element: <InvestorDetails />,
      },
      {
        path: "assets/:id/pcf",
        element: <PCFCalculator />,
      },
      {
        path: "assets/:id/setup",
        element: <AssetDataSetup />,
      },
      {
        path: "pricing-engine",
        element: <PricingEngine />,
      },
      {
        path: "clients",
        element: <Clients />,
      },
      {
        path: "clients/:id",
        element: <ClientDetails />,
      },
      {
        path: "contracts",
        element: <Contracts />,
      },
      {
        path: "contracts/:id",
        element: <ContractDetails />,
      },
      {
        path: "gateways/",
        element: <Gateways />,
      },
      {
        path: "gateways/:id",
        element: <GatewayDetails />
      },
      {
        path: "gateways/connect",
        element: <GatewayConnect />
      },
      {
        path: "gateways/:id/handshakes",
        element: <Handshakes />,
      },
      {
        path: "gateways/:id/errors",
        element: <GatewayErrorLog />,
      },
      {
        path: "manufacturers/",
        element: <Manufacturers />,
      },
      {
        path: "manufacturers/:id",
        element: <ManufacturerDetails />,
      },
      {
        path: "communication-hub",
        element: <CommunicationHub />,
      },
      {
        path: "support",
        element: <Support />,
      },
      // {
      //   path: "settings",
      //   element: <Settings />,
      // },
      {
        path: "me",
        element: <Profile />,
      },
      {
        path: "tech-360",
        element: <Tech360 />,
      },
      {
        path: "watchtower",
        element: <WatchTower />,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
      },
      {
        path: "invoices",
        element: <Invoices />,
      },
      {
        path: "invoices/add",
        element: <AddInvoice />,
      },
      {
        path: "invoices/:id",
        element: <InvoiceDetails />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "tenants",
        element: <Tenants />,
      },
      {
        path: "tenants/:id",
        element: <TenantDetails />,
      },
      {
        path: "configuration",
        element: <SystemConfig />,
      },
      {
        path: "users/:id",
        element: <UserDetails />,
      },
      {
        path: "system_logs",
        element: <SystemAccessLogs />,
      },
      {
        path: "/count/system_logs",
        element: <SystemAccessLogsCount />,
      },
      {
        path: "/log_reports",
        element: <SystemLogReports />,
      },
      {
        path: "/count/application_logs",
        element: <SystemApplicationLogsCount />,
      },
      {
        path: "/system-routines",
        element: <SystemRoutines />
      },
      {
        path: "/system-routines/:name",
        element: <SystemRoutineDetails />
      },
      {
        path: "application_logs",
        element: <SystemApplicationLogs />,
      },
      {
        path: "system-health",
        element: <SystemHealthPage />,
      },
      {
        path: "audit-logs",
        element: <AuditLogs />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "faq",
        element: <FAQ />,
      },
      {
        path: "marketplace",
        element: <Marketplace />,
      },
      {
        path: "data-integration",
        element: <ClientAPI />,
      },
      {
        path: "climategrid",
        element: <DataIntegration />,
      },
      // {
      //   path: "iframe",
      //   element: <Iframe />,
      // },
      {
        path: "alerts",
        element: <Alerts />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "forgot-password",
        element: <ForgotPasswordPage />,
      },
      {
        path: "reset_password/:code",
        element: <ResetPasswordPage />,
      },
      {
        path: "confirm-sign-in",
        element: <ConfirmSignInPage />,
      },
      {
        path: "500",
        element: <Page500 />,
      }
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "/",
    element: <Public />,
    children: [
      {
        path: "gateways/:id/public",
        element: <GatewayDetailsPublic />,
      },
      {
        path: "gateways/:id/public/:code",
        element: <GatewayDetailsPublic />,
      },
    ],
  },
];

export default routes;
