import axios from "axios";
import { useNavigate, useParams, } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Table, Breadcrumb, Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { RefreshCw, Trash } from "react-feather";
import { format, parseISO } from 'date-fns'
import { useTranslation } from "react-i18next";
import { de } from "date-fns/locale"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ErrorHandler from "../Helper/ErrorHandler";
import UserDataManager from "../Helper/UserDataManager";
import AddPaidStatusModal from "./AddPaidStatusModal";
import LoadingSpinner from "../Helper/LoadingSpinner";

const InvoiceDetails = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [isLoading, setIsLoading] = useState(false);

  const config = {
    headers: { Accept: "application/json" },
  };

  useEffect(() => {

    setIsLoading(true);

    axios
      .get("webservice/invoices/" + id, config)
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        setData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  function handleDelete(e) {
    e.preventDefault();
    axios
      .delete("/invoices/" + id)
      .then(function (response) {
        // handle success
        console.log(response.status, "Deleted invoice");
        navigate(-1);
      })
      .catch(function (error) {
        // handle error
        // setErrorMessage(error);
        console.log(error);
      });
  }


  const loadAssetBills = () => {
    if ("asset_bills" in data) {
      return data["asset_bills"].map((invoice) => {
        return (
          <tr key={invoice.id}>
            <td>{t("Usage period")} {format(parseISO(invoice["billing_period_start"]), 'd MMMM yyyy', { locale: i18n.language === "de" ? de : null })} {t("until")} {format(parseISO(invoice["billing_period_end"]), 'd MMMM yyyy', { locale: i18n.language === "de" ? de : null })}</td>
            {/* <td>{invoice["base_fee_amount"].toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: 'EUR',
            })}</td> */}
            <td>{invoice["per_use_price"]?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: 'EUR',
            })}</td>
            <td>{invoice["usage_units"]?.toLocaleString()}</td>
            <td className="text-end">{invoice["usage_amount"]?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: 'EUR',
            })}</td>
          </tr>
        );
      });
    } else {
      return null;
    }
  };

  const loadPositions = () => {
    if (data?.positions?.length > 0) {
      return data?.positions?.map((fee) => {
        return (
          <tr key={fee["name"]}>
            <td>{fee["name"] === "usage" ? t("Asset Usage Fee") : t(fee["name"])}</td>
            <td>{fee["price"]?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: 'EUR',
            })}</td>
            <td>{fee["quantity"]?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'decimal',
            })}</td>
            <td className="text-end">{fee["total"]?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: 'EUR',
            })}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td>-</td>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <td>&nbsp;</td>
        </tr>
      );
    }
  };

  const loadAssetAdditionalFees = () => {
    if (data?.additional_items?.length > 0) {
      return data?.additional_items?.map((fee) => {
        return (
          <tr key={fee["name"]}>
            <td>{fee["name"]}</td>
            <td>{fee["price"]?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: 'EUR',
            })}</td>
            <td>{fee["quantity"]?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'decimal',
            })}</td>
            <td className="text-end">{fee["total"]?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: 'EUR',
            })}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td>-</td>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
          <td>&nbsp;</td>
        </tr>
      );
    }
  };

  const renderInvoiceState = (invoice) => {
    switch (invoice.payment_status) {
      case "paid":
        return (
          <span className="badge bg-success">{t(invoice.payment_status)}</span>
        )
      case "unpaid":
        return (
          <span className="badge bg-warning">{t(invoice.payment_status)}</span>
        )
      case "overdue":
        return (
          <span className="badge bg-danger">{t(invoice.payment_status)}</span>
        )
      default:
        break;
    }
  }

  const loadHeader = () => {

    var total = data.total;
    var due_date = data.due_date;
    var contract_id = data.contract_id;

    return (
      <Row>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <div className="flex-grow-1">
                <h3 className="mb-2">
                  {renderInvoiceState(data)}
                </h3>
                <p className="mb-0">{t("Status")}</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <div className="flex-grow-1">
                <h3 className="mb-2">{total == null ? "" : total.toLocaleString(i18n.language === "de" ? de : "en", {
                  style: 'currency',
                  currency: 'EUR',
                })}</h3>
                <p className="mb-0">{t("Amount")}</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <div className="flex-grow-1">
                <h3 className="mb-2">{due_date == null ? "" : format(parseISO(due_date), "dd MMMM yyyy", { locale: i18n.language === "de" ? de : null })}</h3>
                <p className="mb-0">{t("Due date")}</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill" onClick={() => navigate("/contracts/" + contract_id)} style={{ cursor: "pointer" }}>
            <Card.Body className=" py-4">
              <div className="flex-grow-1">
                <h3 className="mb-2">{t("View Contract")}</h3>
                <p className="mb-0">{t("Contract")}</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }

  return (
    <React.Fragment>
      <Helmet title={"Invoice " + data.invoice_number} />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Breadcrumb>
            {/* <div className="vertical-center-all"> */}
            <Button variant="outline-primary" size="sm" className="me-2" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
            </Button>
            <Breadcrumb.Item href="/invoices">{t("Invoices")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Invoice details")}</Breadcrumb.Item>
            {/* </div> */}
          </Breadcrumb>
          <Col xs="auto" className="d-none d-sm-block">
            <h3>{t("Invoice")}: {data.invoice_number}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            {/* <Button
              variant="primary"
              onClick={e => window.confirm(t("Are you sure you wish to mark this invoice as paid?")) && handleSetAsPaid(e)}
              className="shadow-sm me-1"
            >
              <Check className="feather" /> {t("Mark as \"Paid\"")}
            </Button> */}

            {UserDataManager.getInstance().isAdmin() && <AddPaidStatusModal invoice={data} />}
            {UserDataManager.getInstance().isAdmin() && <Button
              variant="danger"
              onClick={e => window.confirm(t("Are you sure you wish to delete this invoice?")) && handleDelete(e)}
              className="shadow-sm me-1"
            >
              <Trash className="feather" />
            </Button>}
            <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        {/* <Row className="mb-2 mb-xl-3">
          <Col md="12" xl className="d-flex">
            {!!show && (
              <Alert
                className="mb-3"
                variant="primary"
                // dismissible
                onClose={() => setShow(false)}
              >
                <div className="alert-message">
                  {errorMessage}
                </div>
              </Alert>
            )}
          </Col>
        </Row> */}
        {isLoading ? <LoadingSpinner /> :
          <>
            {loadHeader()}
            <Row>
              <Col md="12" xl>
                <Card>
                  <Card.Body className="m-sm-3 m-md-5">
                    <Row>
                      <Col>
                        <div md="6" className="d-none d-sm-block">
                          {t("Dear customer,")}
                          <br />
                          <br /> {t("This is the monthly receipt regarding the aforementioned contract number. Please settle the amount until the given due date.")}
                        </div>
                      </Col>
                      <Col md="6" className="ms-auto text-end mt-n1">
                        <strong >{data?.billing_party?.name}</strong> 
                        {/* ({data?.billing_party?.type}) */}
                        <p>
                          {data?.billing_party?.address} <br /> <br /> <br />
                          <a href="mailto:support@cap-on.de">support@cap-on.de</a>
                        </p>
                      </Col>
                    </Row>
                    <hr className="my-4" />
                    <Row className="mb-4">
                      <Col md="6">
                        <strong>{data?.billed_party?.name}</strong> 
                        {/* ({data?.billing_party?.type}) */}
                        <p>
                          {data?.billed_party?.address}
                          <br />
                          <br />
                        </p>
                      </Col>
                      <Col md="6" className="ms-auto text-end mt-n1">
                        <Row>
                          <div className="text-muted">{t("Contract No.")}: <strong>{data.contract_number}</strong></div>
                        </Row>
                        <Row>
                          <div className="text-muted">{t("Invoice No.")}: <strong>{data.invoice_number}</strong></div>
                        </Row>
                        <Row>
                          <div className="text-muted">{t("Invoice Period")}: <strong>
                            {data.invoice_period_start == null ? "" : format(parseISO(data.invoice_period_start), 'd MMMM yyyy', { locale: i18n.language === "de" ? de : null })}
                          </strong>{" "}
                            {t("until")} <strong>
                              {data.invoice_period_end == null ? "" : format(parseISO(data.invoice_period_end), 'd MMMM yyyy', { locale: i18n.language === "de" ? de : null })}
                            </strong>{" "}</div>
                        </Row>
                        <Row >
                          <div className="text-muted">{t("Invoice date")}: <strong>{data.created_at == null ? "" : format(parseISO(data.created_at), 'd MMMM yyyy', { locale: i18n.language === "de" ? de : null })}</strong></div>
                        </Row>
                        <Row >
                          <div className="text-muted">{t("Due date")}: <strong>{data.due_date == null ? "" : format(parseISO(data.due_date), 'd MMMM yyyy', { locale: i18n.language === "de" ? de : null })}</strong></div>
                        </Row>
                      </Col>
                    </Row>
                    <br />
                    <Table size="sm">
                      <thead>
                        <tr>
                          <th>{t("Description")}</th>
                          <th>{t("Per-Use-Price")}</th>
                          <th>{t("Quantity")}</th>
                          <th className="text-end">{t("Amount")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>{t("Asset fees")}</th>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                        </tr>

                        {/* {loadAssetBills()} */}
                        {loadPositions()}

                        {/* {data?.additional_items?.length > 0 && <tr>
                      <th>{t("Additional fees")}</th>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                    </tr>} */}

                        <tr>
                          <th>{t("Additional fees")}</th>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                        </tr>

                        {loadAssetAdditionalFees()}
                        <tr>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                          <th>{t("Subtotal")}</th>
                          <th className="text-end">{data.subtotal == null ? "0" : data.subtotal.toLocaleString(i18n.language === "de" ? de : "en", {
                            style: 'currency',
                            currency: 'EUR',
                          })}</th>
                        </tr>
                        <tr>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                          <th>{t("VAT")}</th>
                          <th className="text-end">{data.vat == null ? "0" : (data.vat).toLocaleString(i18n.language === "de" ? de : "en", {
                            style: 'currency',
                            currency: 'EUR',
                          })}</th>
                        </tr>
                        <tr>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                          <th>{t("Total")}</th>
                          <th className="text-end">{data.total == null ? "0" : (data.total).toLocaleString(i18n.language === "de" ? de : "en", {
                            style: 'currency',
                            currency: 'EUR',
                          })}</th>
                        </tr>
                      </tbody>
                    </Table>
                    {/* <br />
                <br />
                <div className="text-center">
                  <Button variant="primary">{t("Print invoice")}</Button>
                </div> */}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>}
      </Container>
    </React.Fragment >
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default InvoiceDetails;
