import axios from "axios";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Table, Spinner, Form, Dropdown } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { ArrowUpRight, RefreshCw } from "react-feather";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import UserList from "../Helper/UserList";
import RequestMethods from "../Helper/RequestMethods";
import ApplicationNames from "../Helper/ApplicationName";
import EnvironmentNames from "../Helper/EnvironmentNames";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { formatDistanceToNow, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { getIAMBaseURL, getLogsBaseURL } from "../../config";
import LoadingSpinner from "../Helper/LoadingSpinner";

const SystemAccessLogs = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [logData, setLogData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  var qs = require('qs');
  const queryParams = new URLSearchParams(window.location.search)

  // const [searchParams, setSearchParams] = useSearchParams();
  // const [query, setQuery] = useState(searchParams.get("response_status_min"));

  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = (event) => {
    const { name, value } = event?.target;
    setSearchParams({ [name]: value })
  }

  const [limit, setLimit] = useState();
  const [period_start, setPeriod_start] = useState((new Date(new Date() - 24 * 60 * 60 * 1000 * 7)).toISOString().split('.')[0]);
  const [period_end, setPeriod_end] = useState();
  const [trace_id, setTrace_id] = useState(queryParams.get("trace_id"));
  const [username, setUsername] = useState();
  const [application_name, setApplication_name] = useState();
  const [request_method, setRequest_method] = useState();
  const [request_path, setRequest_path] = useState();
  const [status_code_min, setStatus_code_min] = useState(queryParams.get("response_status_min"));
  const [status_code_max, setStatus_code_max] = useState(queryParams.get("response_status_max"));
  const [skip, setSkip] = useState(0);
  const [environmentName, setEnvironmentName] = useState();
  // const [response_status, setResponse_status] = useState();
  const [response_status_exclude, setResponse_status_exclude] = useState(queryParams.get("response_status_exclude") === null ? null : Array.from(queryParams.get("response_status_exclude")?.split(','), Number));

  useEffect(() => {
    loadLogs(null);
  }, []);

  function loadLogs(event = null, { newSkip = null } = {}) {
    // Prevent default behavior if an event is passed
    if (event) {
      event.preventDefault();
    }

    // If newSkip is passed, update the skip state
    if (newSkip !== null) {
      setSkip(newSkip);
    }

    setIsLoading(true);
    setError({ showMessage: false });

    axios
      .get(getLogsBaseURL() + "/access_logs/", {
        params: {
          limit: limit === "" ? 50 : limit,  // Default limit to 50 if not specified
          skip: newSkip !== null ? newSkip : skip,  // Use newSkip if provided
          period_start: period_start === "" ? null : period_start,
          period_end: period_end === "" ? null : period_end,
          trace_id: trace_id === "" ? null : trace_id,
          username: username === "" ? null : username,
          application_name: application_name === "" ? null : application_name,
          request_method: request_method === "" ? null : request_method,
          request_path: request_path === "" ? null : request_path,
          response_status_min: status_code_min === "" || status_code_min === null ? 0 : status_code_min,
          response_status_max: status_code_max === "" || status_code_max === null ? 10000 : status_code_max,
          environment_name: environmentName === "" ? null : environmentName,
          response_status_exclude: response_status_exclude === "" || response_status_exclude === null ? [] : response_status_exclude,
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        }
      })
      .then(function (response) {
        // handle success
        setLogData(response.data);
        console.log(response.status, "/logs");
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setError({ showMessage: true, errorData: error });
        setIsLoading(false);
      });
  }

  function resetFields() {
    setLimit("");
    setPeriod_start(new Date(new Date() - 24 * 60 * 60 * 1000 * 7).toISOString().split('.')[0])
    setPeriod_end("");
    setTrace_id("");
    setUsername("");
    setApplication_name("");
    setRequest_method("");
    setRequest_path("");
    setStatus_code_min("");
    setStatus_code_max("");
    setEnvironmentName("");
    setResponse_status_exclude("");
    loadLogs(null);
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10); // Set this based on your total results

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const newSkip = (page - 1) * (limit || 50);  // Calculate new skip value
    loadLogs(null, { newSkip });  // Call loadLogs with the new skip value
  };

  function Pagination({ currentPage, totalPages, onPageChange }) {
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }

    return (
      <div className="pagination">
        {currentPage > 1 && (
          <Button variant="outline-secondary" size="sm" className="shadow-sm me-1" onClick={() => onPageChange(currentPage - 1)}>{t("Previous")}</Button>
        )}
        {pages.map((page) => (
          <Button
            key={page}
            variant="outline-secondary" size="sm" className="shadow-sm me-1"
            onClick={() => onPageChange(page)}
            disabled={page === currentPage}
          >
            {page}
          </Button>
        ))}
        {currentPage < totalPages && (
          <Button variant="outline-secondary" size="sm" className="shadow-sm me-1" onClick={() => onPageChange(currentPage + 1)}>{t("Next")}</Button>
        )}
      </div>
    );
  }

  const renderLogsListTable = () => {
    return logData.map((log) => {
      return (
        <tr key={log.id}>
          {/* <td>{format(parseISO(log.time), 'd MMM, HH:mm:ss', { locale: i18n.language === "de" ? de : null })} (UTC)</td> */}
          <td>{log.time}</td>
          <td>{formatDistanceToNow(parseISO(log?.time), { locale: i18n.language === "de" ? de : null, addSuffix: true })}</td>
          <td>{log.application_name}</td>
          <td>{log.environment_name}</td>
          <td>
            <span>
              {log.trace_id}

              {
                log.trace_id &&
                <td className="table-action">
                  <ArrowUpRight alt="Log" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={() => navigate("/application_logs/?trace_id=" + log.trace_id)} />
                </td>
              }
            </span>
          </td>
          <td>{log.username}</td>
          <td>{log.request_method}</td>
          <td>{log.response_status}</td>
          <td>{log.request_path}</td>
          <td>{log.host_ip}</td>
          {changeRemoteIP(log?.remote_ip)}
          <td>{log.duration}</td>
          <td>{log.response_size}</td>
        </tr>
      );
    });
  };

  const [suggestions, setSuggestions] = useState([]); // Store the API suggestions
  const [showDropdown, setShowDropdown] = useState(false); // Whether to show the suggestion dropdown
  let { id } = useParams();
  const [allUsers, setAllUsers] = useState(false)

  useEffect(() => {

    axios
      .get(getIAMBaseURL() + "/users/emails")
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        setAllUsers(response.data.sort((a, b) => (a?.localeCompare(b))));
        // setUsers(response.data.sort((a, b) => (a.role > b.role)));
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  // Function to fetch suggestions from the API
  const fetchSuggestions = (inputValue, field) => {

    console.log("Filtering", inputValue, field,)
    setSuggestions([])
    var filteredSuggestions = [];

    filteredSuggestions = allUsers?.filter(item =>
      item?.toLowerCase().includes(inputValue.toLowerCase()) // Case-insensitive match
    );
    setSuggestions(filteredSuggestions);
    setShowDropdown(true);
  };

  // Debounced API call
  const handleInputChange = (e, field) => {
    const value = e.target.value;

    setUsername(value); // Update the plc_node_id with the input value

    clearTimeout(id);
    id = setTimeout(() => {
      fetchSuggestions(value, field); // Fetch suggestions after 500ms of user input
    }, 500);
  };

  // Handle selecting a suggestion from the dropdown
  const handleSuggestionSelect = (suggestion, field) => {

    setUsername(suggestion)
    setShowDropdown(false); // Hide the dropdown
  };


  function changeRemoteIP(ip) {
    switch (ip) {
      case "213.196.214.120":
        return <td><strong>TS (home)</strong></td>
      case "80.133.118.221":
        return <td><strong>AL (home)</strong></td>
      case "83.135.3.219":
        return <td><strong>PR (home)</strong></td>
      case "217.111.70.71":
        return <td><strong>Beriner Office(?)</strong></td>
      default:
        return <td>{ip}</td>
    }
  }

  const LogsList = () => (
    <Card>
      {
        isLoading === true ?
          <LoadingSpinner withText /> :
          <div>
            <Row>
              <Col xs="auto" className="ms-auto text-center">
                <div className="px-3 py-3 mt-2 mb-2">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </Col>
            </Row>

            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">{t("Timestamp")}</th>
                  <th scope="col"></th>
                  <th scope="col">Application</th>
                  <th scope="col">Environment</th>
                  <th scope="col">Trace ID</th>
                  <th scope="col">User</th>
                  <th scope="col">Request Method</th>
                  <th scope="col">Response Status</th>
                  <th scope="col">Request Path</th>
                  <th scope="col">Host IP</th>
                  <th scope="col">Remote IP</th>
                  <th scope="col">Duration</th>
                  <th scope="col">Response Size</th>
                </tr>
              </thead>
              <tbody>{renderLogsListTable()}</tbody>
            </Table>

          </div>

      }
    </Card>
  );

  return (
    <React.Fragment>
      <Helmet title={t("System Logs")} />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("Access Logs")}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>

        {/* <Row>
          <Col xl="12">
            <LogsChart />
          </Col>
        </Row> */}

        <Card>
          <Card.Header>
            <ErrorHandler error={error} />
          </Card.Header>
          <Card.Body>
            <Form onSubmit={(e) => loadLogs(e)}>
              <Row>
                <Col md={3}>
                  {/* <Form.Group className="mb-3">
                    <Form.Label>{t("Username")}</Form.Label>
                    <Form.Select
                      name="username"
                      onChange={(e) => {
                        updateSearchParams(e)
                        setUsername(e.target.value)
                      }}
                      value={username}>
                      <option value="">{t("-- Select --")}</option>
                      <UserList />
                      <option value="unknown">{t("unknown")}</option>
                    </Form.Select>
                  </Form.Group> */}

                  <Form.Group className="mb-3">
                    <Form.Label>{t("Username")}</Form.Label>
                    <Form.Control
                      type="text"
                      value={username}
                      placeholder="Search"
                      onChange={handleInputChange} // Update input and fetch suggestions
                    />

                    {showDropdown && suggestions.length > 0 && (
                      <Dropdown.Menu
                        show
                        style={{ width: '100%', position: 'absolute', zIndex: 1000 }}
                      >
                        {suggestions.map((suggestion, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => handleSuggestionSelect(suggestion)}
                          >
                            {suggestion}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    )}
                  </Form.Group>

                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Environment")}</Form.Label>
                    <Form.Select
                      name="environment_name"
                      onChange={(e) => {
                        updateSearchParams(e)
                        setEnvironmentName(e.target.value)
                      }}
                      value={environmentName}>
                      <option value="">{t("-- Select --")}</option>
                      <EnvironmentNames />
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Application")}</Form.Label>
                    <Form.Select
                      name="application_name"
                      onChange={(e) => {
                        updateSearchParams(e)
                        setApplication_name(e.target.value)
                      }}
                      value={application_name}>
                      <option value="">{t("-- Select --")}</option>
                      <ApplicationNames />
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Request Method")}</Form.Label>
                    <Form.Select
                      name="request_method"
                      onChange={(e) => {
                        updateSearchParams(e)
                        setRequest_method(e.target.value)
                      }}
                      value={request_method}>
                      <option value="">{t("-- Select --")}</option>
                      <RequestMethods />
                    </Form.Select>
                  </Form.Group>
                </Col>

              </Row>
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Request Path")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="request_path"
                      value={request_path}
                      onChange={(e) => {
                        updateSearchParams(e)
                        setRequest_path(e.target.value)
                      }}
                      placeholder="/api/v1/assets/" />
                  </Form.Group>
                </Col>
                {/* <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Response Status")}</Form.Label>
                    <Form.Control
                      type="text"
                      value={response_status}
                      onChange={(e) => setResponse_status(e.target.value)}
                      placeholder="e.g. 200" />
                  </Form.Group>
                </Col> */}
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Trace ID")}</Form.Label>
                    <Form.Control
                      type="trace_id"
                      value={trace_id}
                      onChange={(e) => {
                        updateSearchParams(e)
                        setTrace_id(e.target.value)
                      }}
                      placeholder="UUID"
                    />
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Limit")}</Form.Label>
                    <Form.Control
                      type="number"
                      name="limit"
                      value={limit}
                      onChange={(e) => {
                        updateSearchParams(e)
                        setLimit(e.target.value)
                      }}
                      placeholder="50"
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Skip")}</Form.Label>
                    <Form.Control
                      type="number"
                      name="skip"
                      value={skip}
                      onChange={(e) => {
                        updateSearchParams(e)
                        setSkip(e.target.value)
                      }}
                      placeholder="50"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Status code (Min)")}</Form.Label>
                    <Form.Control
                      type="text"
                      value={status_code_min}
                      name="response_status_min"
                      onChange={(e) => {
                        updateSearchParams(e)
                        setStatus_code_min(e.target.value)
                      }}
                      placeholder="Status Code"
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Status code (Max)")}</Form.Label>
                    <Form.Control
                      type="text"
                      value={status_code_max}
                      name="response_status_max"
                      onChange={(e) => {
                        updateSearchParams(e)
                        setStatus_code_max(e.target.value)
                      }}
                      placeholder="Status Code"
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Start")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="period_start"
                      value={period_start}
                      onChange={(e) => {
                        updateSearchParams(e)
                        setPeriod_start(e.target.value)
                      }}
                      placeholder="Start date" />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("End")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="period_end"
                      value={period_end}
                      onChange={(e) => {
                        updateSearchParams(e)
                        setPeriod_end(e.target.value)
                      }}
                      placeholder="End date" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Excluded Status Codes")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="response_status_exclude"
                      defaultValue={response_status_exclude}
                      onChange={(e) => {
                        let array = Array.from((e.target.value.replace(" ", "")).split(','), Number)
                        updateSearchParams(e)
                        setResponse_status_exclude(array)
                        // setResponse_status_exclude(e)
                        // console.log(array)
                      }}
                      placeholder="401,402,403,..." />
                  </Form.Group>
                </Col>
              </Row>
              <br />

              <Row>
                <Col>
                  <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => navigate(-1)}>
                    {t("Back")}
                  </Button>
                  <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => resetFields()}>
                    {t("Reset")}
                  </Button>

                  <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => setUsername("*@maag.com")}>
                    {t("MAAG only")}
                  </Button>
                  
                  <Button type="submit" variant="primary"
                  >
                    {t("Query")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        {
          logData.length === 0
            ?
            <Row>
              <Col xl="12">
                <Card>
                  <Card.Header>
                    <Card.Title tag="h5"></Card.Title>
                    <h6 className="card-subtitle text-muted">
                      {t("No data found.")} {t("Run new query.")}
                    </h6>
                  </Card.Header>
                </Card>
              </Col>
            </Row>
            :
            <Row>
              <Col xl="12">
                <LogsList />
              </Col>
            </Row>
        }
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default SystemAccessLogs;
