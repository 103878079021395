import { Alert, Button } from "react-bootstrap";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import UserDataManager from "../Helper/UserDataManager";

function ErrorHandler(props) {

  const { t } = useTranslation();
  const [showError, setShowError] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const initialLoad = location.state?.initialLoad;

  function doLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("data");
    // localStorage.removeItem("tenant_id");
    localStorage.clear();
    navigate("/sign-in", { state: { from: location }, replace: true });
  }

  function getErrorMessage() {

    // console.log("-->", error.error.errorData.response.data.detail.map((entry) => { return {entry} }));

    switch (props?.error?.errorData?.response?.status) {
      case 400:
        return <>{"Bad request"} {props?.error?.errorData?.response?.data?.detail === null || props?.error?.errorData?.response?.data?.detail === undefined ? "" : ": " + props?.error?.errorData?.response?.data?.detail} {props?.error?.errorData?.response?.data?.message === null || props?.error?.errorData?.response?.data?.message === undefined ? "" : ": " + props?.error?.errorData?.response?.data?.message}</>
      case 401:
        console.log("initialLoad:", initialLoad);
        if (!initialLoad) {
          console.log("Is initial load!")
          doLogout();
        }
        return "Unauthorized"
      case 402:
        return "Payment required"
      case 403:
        return "You're not allowed to view this information. Please ask your administrator."
      case 404:
        return "Not found"
      case 405:
        return "Sorry about that. This error is on us!"
      case 408:
        return "Timeout"
      case 422:
        return <>{t("Some fields may be missing or need to be re-entered: ")} <br /> {props?.error?.errorData?.response?.data?.detail?.map((entry) => { return <><br />{t(entry.loc[1])}: {entry.msg}</> }) || "Please create a support ticket."}</>
      case 500:
        return t("Oops. Our bad. Please try again later.")
      default:
        return <>{t("We are currently working on the system. Please try again in a few minutes.")}</>
    }
  }

  if (props.error.showMessage === true && showError === true) {

    // console.log(props.error.customMessage)

    if (props.error.customMessage === "" || props.error.customMessage === undefined) {

      // if (props.error.showButton === true) {

      //   return (
      //     <div>
      //       <Alert show={showError} variant="warning" onClose={() => setShowError(false)}>
      //         <div className="alert-message">
      //         <strong>{t("Error ") + props?.error?.errorData?.response?.status || "Error"}</strong><br />
      //           {getErrorMessage()}
      //           <hr />
      //           <div className="btn-list">
      //             <Button variant="primary" className="me-1" onClick={() => doLogout()}>
      //               {t("Sign out")}
      //             </Button>
      //           </div>
      //         </div>
      //       </Alert>
      //     </div>
      //   );
      // } else {

      if (!initialLoad) {
        console.log("Is initial load!")
        return (
          <div>
            <Alert show={showError} variant="warning" onClose={() => setShowError(false)}>
              <div className="alert-message">
                <strong>{t("Error ") + (props?.error?.errorData?.response?.status || "")}</strong><br />
                {getErrorMessage()}
                {
                  UserDataManager.getInstance().isAdmin() && props?.error?.errorData?.response?.data?.message?.includes("Trace_ID: ") ?
                    <>
                      <br />
                      <br />
                      <Button size="sm" variant="outline-warning" className="me-1" onClick={() => navigate("/application_logs/?trace_id=" + props?.error?.errorData?.response?.data?.message?.split('Trace_ID: ').pop())}>
                        {t("Details")}
                      </Button>
                    </>
                    :
                    <></>
                }
              </div>
            </Alert>
          </div>
        );
      } else {
        return (<></>)
      }

    } else {
      return (
        <div>
          <Alert show={showError} variant="warning" onClose={() => setShowError(false)}>
            <div className="alert-message">
              {props?.error?.customMessage}
            </div>
          </Alert>
        </div>
      );
    }
  } else {
    return (<></>)
  }
};

export default ErrorHandler;
