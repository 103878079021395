import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Card,
  Col,
  Container,
  Row,
  Table,
  Breadcrumb,
  Dropdown,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Plus, MoreHorizontal, } from "react-feather";
import AssetTable from "../Assets/AssetTable";
import InvoiceTable from "../Invoices/InvoiceTable";
import ContractTable from "../Contracts/ContractTable";
import { useTranslation } from "react-i18next";
import FileTable from "../FileManagement/FileTable";
import { format, parseISO } from "date-fns";
import GenericChart from "../Helper/GenericChart";
import de from "date-fns/locale/de";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ErrorHandler from "../Helper/ErrorHandler";
import UploadFileModal from "../Helper/UploadFileModal";
import UploadAvatarModal from "../Helper/UploadAvatarModal";
import EditClientModal from "./ClientModal_Edit";
import AddContractModal from "../Contracts/ContractModal_Add";
import RemoveAvatarButton from "../Helper/RemoveAvatarButton";

const ClientDetails = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [assetsOfClient, setAssetsOfClient] = useState([]);
  const [invoicesOfClient, setInvoicesOfClient] = useState([]);
  const [accountManagers, setAccountManagers] = useState([]);
  const [contractsOfClient, setContractsOfClient] = useState([]);
  const [filesData, setFilesData] = useState([]);
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  useEffect(() => {
    axios
      .get("/webservice/clients/" + id)
      .then(function (response) {
        // handle success
        console.log(response.status, "Loaded client");
        setContractsOfClient(response.data.contracts);
        setAccountManagers(response.data.client_account_managers);
        setAssetsOfClient(response.data.assets);
        setData(response.data);
        setInvoicesOfClient(response.data.invoices);
        setFilesData(response.data.files);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }, []);

  function handleDelete(e) {
    e.preventDefault();
    axios
      .delete("/clients/" + id)
      .then(function (response) {
        // handle success
        console.log(response.status, "Deleted client");
        navigate(-1);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const loadAvatarBig = () => {
    return (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        src={data.avatar_url == null ? null : data.avatar_url}
        className="center"
        width={150} height={150}
      />);
  };

  const ShowAccountManagersList = () => {
    return accountManagers?.map((manager) => {
      return (

        // <li className="mb-1">
        //   <MapPin width={14} height={14} className="me-1" /> From{" "}
        //   <Link to="/dashboard/default">Boston</Link>
        // </li>

        <div className="mb-1" key={manager?.avatar_url} onClick={e => navigate(`/users/${manager.id}`)} style={{ cursor: "pointer" }}>
          <img
            src={manager?.avatar_url}
            alt="avatar"
            className="me-3 text-center rounded-circle"
            width={40} height={40}
          />
          {manager.first_name} {manager.last_name}
          {/* <User  width={14} height={14} className="me-1" /> */}
          {/* <Link to={`/users/${manager.id}`}></Link> */}
          {/* {" "} <Trash2 width={14} height={14} className="me-1" onClick={e => window.confirm(t("Are you sure you wish to remove this owner?")) && handleRemoveOwner(manager?.id)}/> */}
          {/* {" "} <Mail width={14} height={14} className="me-1" onClick={e => open("mailto:support@cap-on.de?subject=cap-on%20Support&body=")} /> */}
        </div>
      );
    });
  }

  return (
    <React.Fragment>
      <Helmet title={data.business_name} />
      <Container fluid className="p-0">
        <Breadcrumb>
          {/* <div className="vertical-center-all"> */}
          <Button variant="outline-primary" size="sm" className="me-2" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
          </Button>
          <Breadcrumb.Item href="/clients">{t("Clients")}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t("Client details")}</Breadcrumb.Item>
          {/* </div> */}
        </Breadcrumb>
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{data.business_name}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            {/* <Button
              variant="primary"
              onClick={() => navigate("/clients/" + id + "/add")}
              className="shadow-sm me-1"
            >
              <Plus className="feather" /> {t("New contract")}
            </Button> */}
            <AddContractModal id={id} />
            <Dropdown align={{ lg: 'end' }} className="d-inline">
              <Dropdown.Toggle variant="light" className="bg-white shadow-sm">
                <MoreHorizontal className="feather align-middle mt-n1" /> {t("More")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <EditClientModal />
                <UploadAvatarModal type="client" />
                <RemoveAvatarButton type="client"/>
                <Dropdown.Divider />
                <UploadFileModal buttonType="" type="client" />
                <Dropdown.Divider />
                <Dropdown.Item onClick={e => window.confirm(t("Are you sure you wish to delete this client?")) && handleDelete(e)}>{t("Remove client")}</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={refreshPage}>{t("Refresh")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Col md="9" className="d-flex">
            <Card className="flex-fill w-100">
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("About")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Table size="sm" className="my-2">
                  <tbody>
                    <tr>
                      <th>{t("Name")}</th>
                      <td>{data?.business_name || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Address")}</th>
                      <td>{data?.address || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Website")}</th>
                      <td>{data?.website || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Client number")}</th>
                      <td>{data?.client_number || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Commercial register number")}</th>
                      <td>{data?.registration_number || "-"}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col md="3" className="d-flex">
            <Card className="d-flex w-100">
              {
                data.avatar_url == null || data.avatar_url === "https://core-system.s3.eu-central-1.amazonaws.com/client_placeholder.png" ?

                  <Card.Body className="d-flex align-items-center justify-content-center w-100 h-100">
                    <div className="text-center">
                      <UploadAvatarModal type="client" style="button" />
                    </div>
                  </Card.Body>
                  :

                  <Card.Body className="d-flex align-items-center justify-content-center w-100 h-100">
                    <div className="text-center">
                      {data?.avatar_url == null ? "" : loadAvatarBig()}
                    </div>
                  </Card.Body>
              }

            </Card>
          </Col>
          {/* <Col md="3" className="d-flex">
            <Card className="d-flex w-100">
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("Account Manager")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <ShowAccountManagersList />
                {UserDataManager.getInstance().isAdmin() && <AddPermissionModal type="clients" accountManagers={data?.client_account_managers} />}
              </Card.Body>
            </Card>
          </Col> */}
        </Row>
        <Row>
          <Col xl="12">
            {contractsOfClient == null ? "" : ContractTable(contractsOfClient, t("Contracts"))}
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <AssetTable data={assetsOfClient} title={t("Assets")} slim/>
          </Col>
        </Row>
        {/* <br /> */}
        <Row>
          <Col md="6" className="d-flex">
            {invoicesOfClient == null ? "" : InvoiceTable(invoicesOfClient, t("Invoices"), false)}
          </Col>
          <Col md="6">
            {invoicesOfClient == null ? "" : GenericChart(
              invoicesOfClient?.map((entry) => { return format(parseISO(entry.due_date), "dd MM yyyy", { locale: i18n.language === "de" ? de : null }) }),
              invoicesOfClient?.map((entry) => { return entry.total }),
              (Math.max(...invoicesOfClient.map(o => o.total)) * 1.15),
              t("Invoices Chart"),
              "currency",
              "")}
          </Col>
        </Row>
        <br />
        <Row>
          <Col xl="12">
            {filesData == null ? "" : FileTable(filesData, t("Documents"), data.id, "/clients/")}
          </Col>
        </Row>
      </Container>
    </React.Fragment >
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default ClientDetails;
