import { Badge, Button, Card, Col, Table } from "react-bootstrap";
import React, { useContext, useEffect } from "react";
import { ArrowUpRight, Bell, BellOff, Plus, Trash } from "react-feather";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useState } from "react";
import { formatDistanceToNow, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import { de } from 'date-fns/locale';
import SingleWatchTowerModal from "./SingleWatchTowerModal";
import NotyfContext from "../../contexts/NotyfContext";
import UserDataManager from "../Helper/UserDataManager";
import LoadingSpinner from "../Helper/LoadingSpinner";

const WatchTowerList = ({ slim, showAddButton, watchtowers, selected_asset_id, selected_metric, selected_iot_node_id }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const notyf = useContext(NotyfContext);
  const [showWatchTowerModal, setShowWatchTowerModal] = useState(null);
  const [selectedWatchTower, setSelectedWatchTower] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    if (watchtowers) {
      setData(watchtowers?.sort((a, b) => parseISO(a?.last_trigger_at) > parseISO(b?.last_trigger_at)))
    } else {
      getWatchtowers()
    }

  }, [watchtowers]);

  function getWatchtowers() {

    setIsLoading(true)

    console.log("GET watchtowers for --->", selected_asset_id, selected_metric, selected_iot_node_id)

    axios
      .get("/watchtower/", {
        params: {
          asset_id: selected_asset_id,
          asset_metric_id: selected_metric?.asset_metric_id || null,
          iot_node_id: selected_iot_node_id || null,
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "/watchtower");
        setData(response.data?.sort((a, b) => parseISO(a?.last_trigger_at) > parseISO(b?.last_trigger_at)));
        setIsLoading(false)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setIsLoading(false)
      });
  }

  function handleDeleteWatchtower(e, id) {
    e.preventDefault();

    axios
      .delete("/watchtower/" + id)
      .then(function (response) {
        console.log(response.status, "Deleted watchtower");
        // handle success

        notyf.open({
          type: "success",
          message: t("Removed watchtower"),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        getWatchtowers()
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  function updateAlertUser(watchtowerItem) {

    var isBeingNotified = watchtowerItem?.users_to_alert?.find(user => user.user_id === UserDataManager.getInstance().getUserData().id);

    if (isBeingNotified) {
      // Add user from watchtower
      axios
        .delete("/watchtower/" + watchtowerItem?.id + "/self")
        .then(function (response) {
          // handle success
          console.log(response.status, "Edited successfully");

          notyf.open({
            type: "success",
            message: t("You will not be notified any longer."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })

          getWatchtowers();

        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    } else {

      // Remove user from watchtower
      axios
        .put("/watchtower/" + watchtowerItem?.id + "/self")
        .then(function (response) {
          // handle success
          console.log(response.status, "Deleted successfully");

          notyf.open({
            type: "success",
            message: t("You will be notified."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })

          getWatchtowers();
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }

  }

  const UserNotificationSetting = ({ item }) => {

    var isBeingNotified = item?.users_to_alert?.find(user => user.user_id === UserDataManager.getInstance().getUserData().id);

    return (
      isBeingNotified
        ? <BellOff alt="Delete" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={e => {
          e.stopPropagation();
          updateAlertUser(item)
        }} />
        : <Bell alt="Delete" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={e => {
          e.stopPropagation();
          updateAlertUser(item)
        }} />
    )
  }

  const renderWatchTowerTable = () => {
    return data?.map((item) => {
      return (
        // <tr key={item.id} onClick={() => navigate("/watchtower/" + item.id)} style={{ cursor: "pointer" }}>
        <tr key={item.id}
          onClick={() => {
            setSelectedWatchTower(item)
            setShowWatchTowerModal(true)
          }} style={{ cursor: "pointer" }}
        >
          {/* <td>{parseISO(item.created_at) < (new Date() - 24 * 60 * 60 * 1000) ?
            format(parseISO(item.created_at), "dd MMM yyyy HH:mm", { locale: i18n.language === "de" ? de : null }) :
            formatDistanceToNow(parseISO(item.created_at), { locale: i18n.language === "de" ? de : null, addSuffix: true })
          }</td> */}
          {(item?.asset_title && !slim) && <td>{item?.asset_title}</td>}
          <td>{t("Watching and notifying if")} <strong>{t(item.metric)}</strong> {t(item.comparison_mode + "_relative")} {t(item.threshold)}</td>
          <td>{item?.last_trigger_at === null ? "-" : formatDistanceToNow(parseISO(item?.last_trigger_at), { locale: i18n.language === "de" ? de : null, addSuffix: true })}</td>
          <td>{t(item?.trigger_count || "-")}</td>
          {/* <td>
            <span className={item.send_emails ? "badge bg-success" : "badge bg-warning"}>{item.send_emails ? t("Yes") : t("No")}</span>
          </td> */}
          {/* <td> <RenderSeverityLevel level={item?.level} /></td> */}
          <td> {t(item?.level || "-")}</td>
          <td>
            {ShowUserAvatars(item)}
          </td>
          <td className="table-action">
            {/* <ArrowUpRight alt="Show asset" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={() => navigate("/assets/" + item?.asset_id)} />
            &nbsp;&nbsp; */}

            <UserNotificationSetting item={item} />
            &nbsp; &nbsp;
            <Trash alt="Delete" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={e => {
              e.stopPropagation(); // This will prevent the click from bubbling up to the <tr> element
              window.confirm(t("Are you sure you wish to delete this Watch Tower?")) && handleDeleteWatchtower(e, item.id)
            }
            } />
          </td>
        </tr>
      );
    });
  };

  const ShowUserAvatars = (item) => {

    return item?.users_to_alert?.map((user) => {

      // console.log("->", user)
      if (user.avatar_url !== null) {
        return (
          <img
            key={user.avatar_url}
            src={user == null ? null : user.avatar_url}
            alt="avatar"
            className="me-3 text-center rounded-circle"
            width={32} height={32}
          />
        );
      }
      return (<></>)
    });
  }

  return (
    <div>

      {/* Conditional overlay */}
      {showWatchTowerModal && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1100, // Slightly above IoTDataPointModal but below WatchTowerModal
          }}
        />
      )}

      {showWatchTowerModal && <SingleWatchTowerModal
        desired_watchtower_id={selectedWatchTower?.id}
        desired_iot_node_id={selected_iot_node_id}
        desired_asset_metric={selected_metric}
        selected_asset_id={selectedWatchTower?.asset_id || selected_asset_id}
        handleClose={() => {
          setShowWatchTowerModal(null)
          setSelectedWatchTower(null)
          getWatchtowers()
        }} />}

      <Card className="flex-fill w-100" >
        {/* style={{ zIndex: 1080 }} */}
        <Card.Header>
          <Col>
            <Card.Title>
              {t("Watch Towers")}
            </Card.Title>
            <Card.Subtitle>
              {t("The cap-on Watch Tower automatically monitors certain sensor parameters and notifies users once values exceeded individual threshold.")}
            </Card.Subtitle>
          </Col>
          {showAddButton && <Col xs="auto" className="ms-auto text-end mt-n3">
            <Button
              onClick={() => {
                setSelectedWatchTower(null)
                setShowWatchTowerModal(true)
              }}
              size="sm"
              variant="outline-primary"
              className="shadow-sm me-1">
              <Plus className="feather" /> {t("New")}
            </Button>
          </Col>}
        </Card.Header>

        <Card.Body>

          {
            isLoading === true ?
              <LoadingSpinner withText /> :
              (data.length > 0)
                ? // If data found

                <Table responsive hover>
                  <thead>
                    <tr>
                      {/* <th scope="col">{t("Created")}</th> */}
                      {((data?.length > 0 && data[0]?.asset_title) && !slim) && <th scope="col">{t("Asset")}</th>}
                      <th scope="col"></th>
                      {/* <th scope="col">{t("Threshold")}</th>
                            <th scope="col">{t("Comparison Mode")}</th> */}
                      <th scope="col">{t("Last triggered at")}</th>
                      <th scope="col">{t("Trigger count")}</th>
                      <th scope="col">{t("Severity")}</th>
                      {/* <th scope="col">{t("Send Emails")}</th> */}
                      <th scope="col">{t("Users to alert")}</th>
                    </tr>
                  </thead>
                  {data?.length > 0
                    ? <tbody>{renderWatchTowerTable()}</tbody>
                    : <tbody className="text-muted"><tr><td>{t("No data found.")}</td></tr></tbody>
                  }
                </Table>
                : // If no data found

                <p className="card-subtitle text-muted">
                  {t("No data found.")}
                </p>
          }
        </Card.Body>
      </Card>





    </div>
  );
};

export default WatchTowerList;
