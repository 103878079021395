import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Card,
  Col,
  Container,
  Row,
  Table,
  Breadcrumb,
  Dropdown,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Plus, MoreHorizontal } from "react-feather";
import ContractTable from "../Contracts/ContractTable";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ErrorHandler from "../Helper/ErrorHandler";
import UserDataManager from "../Helper/UserDataManager";
import AddPermissionModal from "../Helper/AddPermissionModal";
import EditManufacturerModal from "./ManufacturerModal_Edit";
import UploadAvatarModal from "../Helper/UploadAvatarModal";
import AddContractModal from "../Contracts/ContractModal_Add";
import RemoveAvatarButton from "../Helper/RemoveAvatarButton";

const ManufacturerDetails = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  // const [accountManagers, setAccountManagers] = useState(null);
  // const [assets, setAssets] = useState([]);
  // const [invoices, setInvoices] = useState([]);
  const [contracts, setContracts] = useState([]);
  // const [filesData, setFilesData] = useState([]);
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  useEffect(() => {

    axios
      .get("/manufacturers/" + id)
      .then(function (response) {
        // handle success
        console.log(response.status, "/manufacturer");
        setContracts(response.data.contracts);
        // setAssets(response.data.assets);
        setData(response.data);
        // setAccountManagers(response.data.owning_users);
        // setInvoices(response.data.invoices);
        // setFilesData(response.data.files);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }, []);

  function handleDelete(e) {
    e.preventDefault();
    axios
      .delete("/manufacturers/" + id)
      .then(function (response) {
        // handle success
        console.log(response.status, "Deleted manufacturer");
        navigate(-1);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const loadAvatarBig = () => {
    return (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        src={data.avatar_url == null ? null : data.avatar_url}
        className="center"
        width={150} height={150}
      />);
  };

  // const ShowAccountManagersList = () => {
  //   if (accountManagers === null) {
  //     return (null)
  //   }
  //   return accountManagers?.map((manager) => {
  //     return (

  //       // <li className="mb-1">
  //       //   <MapPin width={14} height={14} className="me-1" /> From{" "}
  //       //   <Link to="/dashboard/default">Boston</Link>
  //       // </li>

  //       <div className="mb-1" key={manager?.avatar_url} onClick={e => navigate(`/users/${manager.id}`)} style={{ cursor: "pointer" }}>
  //         <img
  //           src={manager?.avatar_url}
  //           alt="avatar"
  //           className="me-3 text-center rounded-circle"
  //           width={40} height={40}
  //         />
  //         {manager.first_name} {manager.last_name}
  //         {/* <User  width={14} height={14} className="me-1" /> */}
  //         {/* <Link to={`/users/${manager.id}`}></Link> */}
  //         {/* {" "} <Trash2 width={14} height={14} className="me-1" onClick={e => window.confirm(t("Are you sure you wish to remove this owner?")) && handleRemoveOwner(manager?.id)}/> */}
  //         {/* {" "} <Mail width={14} height={14} className="me-1" onClick={e => open("mailto:support@cap-on.de?subject=cap-on%20Support&body=")} /> */}
  //       </div>
  //     );
  //   })
  // }

  return (
    <React.Fragment>
      <Helmet title={data.business_name} />
      <Container fluid className="p-0">
        <Breadcrumb>
          {/* <div className="vertical-center-all"> */}
          <Button variant="outline-primary" size="sm" className="me-2" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
          </Button>
          <Breadcrumb.Item href="/manufacturers">{t("Manufacturers")}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t("Manufacturer details")}</Breadcrumb.Item>
          {/* </div> */}
        </Breadcrumb>
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{data.name}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            {/* <Button
              variant="primary"
              onClick={() => navigate("/manufacturers/" + id + "/add")}
              className="shadow-sm me-1"
            >
              <Plus className="feather" /> {t("New contract")}
            </Button> */}
            <AddContractModal id={data?.id} />
            <Dropdown align={{ lg: 'end' }} className="d-inline">
              <Dropdown.Toggle variant="light" className="bg-white shadow-sm">
                <MoreHorizontal className="feather align-middle mt-n1" /> {t("More")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <EditManufacturerModal />
                <UploadAvatarModal type="manufacturer" />
                <RemoveAvatarButton type="manufacturer" />
                <Dropdown.Divider />
                <Dropdown.Item onClick={e => window.confirm(t("Are you sure you wish to delete this manufacturers?")) && handleDelete(e)}>{t("Remove manufacturer")}</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={refreshPage}>{t("Refresh")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Col md="9" className="d-flex">
            <Card className="flex-fill w-100">
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("About")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Table size="sm" className="my-2">
                  <tbody>
                    <tr>
                      <th>{t("Name")}</th>
                      <td>{data?.name || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Address")}</th>
                      <td>{data?.address || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Website")}</th>
                      <td>{data?.website || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Manufacturer number")}</th>
                      <td>{data?.manufacturer_number || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Commercial register number")}</th>
                      <td>{data?.registration_number || "-"}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col md="3" className="d-flex">
            <Card className="d-flex w-100">
              {
                data.avatar_url == null || data.avatar_url === "https://core-system.s3.eu-central-1.amazonaws.com/client_placeholder.png" ?

                  <Card.Body className="d-flex align-items-center justify-content-center w-100 h-100">
                    <div className="text-center">
                      <UploadAvatarModal type="manufacturer" style="button" />
                    </div>
                  </Card.Body>
                  :

                  <Card.Body className="d-flex align-items-center justify-content-center w-100 h-100">
                    <div className="text-center">
                      {data?.avatar_url == null ? "" : loadAvatarBig()}
                    </div>
                  </Card.Body>
              }

            </Card>
          </Col>
          {/* <Col md="3" className="d-flex">
            <Card className="d-flex w-100">
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("Account Manager")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <ShowAccountManagersList />
                {UserDataManager.getInstance().isAdmin() && <AddPermissionModal type="manufacturers" accountManagers={data?.owning_users} />}
              </Card.Body>
            </Card>
          </Col> */}
        </Row>

        <Row>
          <Col xl="12">
            {contracts == null ? "" : ContractTable(contracts, t("Contracts"))}
          </Col>
        </Row>
        {/* <Row>
          <Col xl="12">
            {assets == null ? "" : AssetTable(assets, t("Assets"))}
          </Col>
        </Row> */}
        <br />
        {/* <Row>
          <Col md="6" className="d-flex">
            {invoices == null ? "" : InvoiceTable(invoices, t("Invoices"), false, false)}
          </Col>
          <Col md="6">
            {invoices == null ? "" : GenericChart(
              invoices.map((entry) => { return format(parseISO(entry.due_date), "dd MM yyyy", { locale: i18n.language === "de" ? de : null }) }),
              invoices.map((entry) => { return entry.total }),
              (Math.max(...invoices.map(o => o.total)) * 1.15),
              t("Invoices Chart"))}
          </Col>
        </Row> */}
        <br />
        {/* <Row>
          <Col xl="12">
            {filesData == null ? "" : FileTable(filesData, t("Files"), data.id, "/manufacturers/")}
          </Col>
        </Row> */}
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default ManufacturerDetails;
