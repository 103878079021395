import React from "react";
import { Card } from "react-bootstrap";
import usePalette from "../../hooks/usePalette";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";
import { de } from "date-fns/locale";

const GenericChart = (x_data, y_data, y_maxValue, title, style, description) => {
  const palette = usePalette();
  const { t, i18n } = useTranslation();

  const data = [
    {
      name: title,
      type: "area",
      data: y_data,
    },
  ];

  const options = {
    chart: {
      stacked: false,
    },
    stroke: {
      width: [2, 2],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "100%",
      },
    },
    fill: {
      opacity: [0.85, 0.25],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: x_data,
    markers: {
      size: 0,
    },
    xaxis: {
      // type: "time",
      // max: 100,
      labels: {
        show: true,
        align: 'right',
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: palette.black,
        },
      },
    },
    yaxis: {
      min: 0,
      max: y_maxValue,
      labels: {
        show: true,
        align: 'right',
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: palette.black,
        },
        formatter: (value) => {
          return value?.toLocaleString(i18n.language === "de" ? de : "en", { style: style, currency: "EUR", maximumFractionDigits: 0 })
        },
      },
    },
    legend: {
      position: 'top',
      labels: {
        colors: palette.black,
        useSeriesColors: false
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          return y?.toLocaleString(i18n.language === "de" ? de : "en", { style: style, currency: "EUR", maximumFractionDigits: 0 })
        },
      },
    },
    colors: [
      palette.primary,
      palette.success,
      palette.warning,
      palette.info,
      palette.danger,
    ],
  };

  return (
    <Card className="flex-fill w-100 h-100">
      <Card.Header>
        <Card.Title tag="h5">{t(title)}</Card.Title>
        {/* <h6 className="card-subtitle text-muted">
          A line chart is a way of plotting data points on a line.
        </h6> */}
      </Card.Header>
      {/* <Card.Body> */}
      {
        y_data != null && y_data.length > 0
          ?
          <div className="chart">
            <Chart options={options} series={data} type="line" height="340" />
          </div>
          :
          <Card.Body>
            <p>{t("No data found.")}</p>
          </Card.Body>
      }
      {/* </Card.Body> */}
    </Card>
  );
};

export default GenericChart;
