import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import axios from "axios";
import { Edit2, Trash } from "react-feather";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import avatar1 from "../../assets/img/avatars/avatar.png";
import { format, formatDistanceStrict, parseISO, max } from "date-fns";
import { de } from "date-fns/locale";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import NotyfContext from "../../contexts/NotyfContext";
import ErrorHandler from "../Helper/ErrorHandler";
import EditUserModal from "./UserModal_Edit";
import { getIAMBaseURL, getLogsBaseURL } from "../../config";
import Chart from "react-apexcharts";
import usePalette from "../../hooks/usePalette";

const UserDetails = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation();
  const notyf = useContext(NotyfContext);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [logCountData, setLogCountData] = useState([]);
  const [logChartDataX, setLogChartDataX] = useState([]);
  const [logChartDataY, setLogChartDataY] = useState([]);
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    axios
      .get(getIAMBaseURL() + "/users/" + id)
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        setData(response.data);

        loadLogs(response.data.email);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  function loadLogs(email) {
    // e?.preventDefault();

    console.log("Error", email)

    if (email) {
      axios
        .get(getLogsBaseURL() + "/access_logs/count", {
          params: {
            username: email
          }
        })
        .then(function (response) {
          // handle success
          setLogCountData(response.data.reverse())
          setLogChartDataX(response.data.map((entry) => { return format(parseISO(entry.interval_start), "dd MMM", { locale: i18n.language === "de" ? de : null }) }));
          setLogChartDataY(response.data.map((entry) => { return entry.count_logs === null ? 0 : entry.count_logs }));
          console.log(response.status, "/logs");
          setShowChart(true);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          setShowChart(false);
          setError({ showMessage: true, errorData: error })
        });
    } else {
      console.log("Error")
    }

  }

  function getStatus(status) {
    switch (status) {
      case "approved":
        return t("Approved")
      case "active":
        return t("Active")
      case "request":
        return t("Request")
      case "inactive":
        return t("Inactive")
      case "draft":
        return t("Draft")
      case "initial":
        return t("Initial")
      default:
        return t(status)
    }
  }

  const showAvatar = () => {
    return (
      <img
        src={data.avatar_url == null ? avatar1 : data.avatar_url}
        className="rounded-circle img-responsive mt-2"
        width="128"
        height="128"
        alt="avatar"
      />);
  };

  function handleDelete(e) {
    e.preventDefault();
    axios
      .delete(getIAMBaseURL() + "/users/" + id)
      .then(function (response) {
        // handle success
        console.log(response.status, "Deleted user");
        navigate("/users");

        notyf.open({
          type: "success",
          message: t("User has been deleted."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const LogsCount = () => {
    const palette = usePalette();

    const data = [
      {
        name: "Logs",
        type: "area",
        data: logChartDataY,
      },
    ];

    const options = {
      chart: {
        animations: {
          enabled: false
        },
        stacked: false,
      },
      stroke: {
        width: [2, 2],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "100%",
        },
      },
      fill: {
        opacity: [0.85, 0.25],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: logChartDataX,
      markers: {
        size: 0,
      },
      xaxis: {
        // type: "time",
        // max: 100,
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
        },
      },
      yaxis: {
        // title: {
        //   text: t("Handshakes"),
        // },
        min: 0,
        max: max(logChartDataY) * 1.1,
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
          formatter: (value) => { return Math.round(value) },
        },
      },
      legend: {
        position: 'top',
        labels: {
          colors: palette.black,
          useSeriesColors: false
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            return y;
          },
        },
      },
      colors: [
        palette.primary,
        palette.success,
        palette.warning,
        palette.info,
        palette.danger,
      ],
    };

    return (
      <Card>
        <Card.Header>
          <Col xs="auto" className="d-none d-sm-block">
            <Card.Title tag="h5">{t("Access Logs")}</Card.Title>
          </Col>
        </Card.Header>
        <Card.Body>
          <div className="chart">
            {showChart === true && <Chart options={options} series={data} type="line" height="340" />}
          </div>
        </Card.Body>
      </Card>
    );
  };


  return (

    <React.Fragment>
      <Helmet title={t("Edit User")} />
      <Container fluid className="p-0">

        <Breadcrumb>
          <Button variant="outline-primary" size="sm" className="me-2" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
          </Button>
          <Breadcrumb.Item href="/users">{t("Users")}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t("User details")}</Breadcrumb.Item>
        </Breadcrumb>

        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{data.first_name} {data.last_name}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <EditUserModal id={id} title={t("Edit user")} />
            <Button
              variant="danger"
              onClick={e => window.confirm(t("Are you sure you wish to delete this user?")) && handleDelete(e)}
              className="shadow-sm"
            >
              <Trash className="feather" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md="3" className="d-flex">
            <Card className="flex-fill">
              <Card.Header>
                <ErrorHandler error={error} />
              </Card.Header>
              {/* <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("Avatar")}
                </Card.Title>
              </Card.Header> */}
              <Card.Body className="d-flex align-items-center justify-content-center w-100 h-100">
                <div className="text-center">
                  {showAvatar(data.avatar == null ? avatar1 : data.avatar.url)}
                  <br /><br />
                  <div>
                    <h4 className="card-title">{data.first_name} {data.last_name}</h4>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="9">
            <Card className="flex-fill">
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("About")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Table size="sm" className="my-2">
                  <tbody>
                    <tr>
                      <th>{t("Name")}</th>
                      <td>{data.first_name} {data.last_name}</td>
                    </tr>
                    <tr>
                      <th>{t("Status")}</th>
                      <td>
                        <span className={data.status === "active" || data.status === "approved" ? "badge bg-success" : "badge bg-warning"}>{getStatus(data.status)}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>{t("cap-on User Role")}</th>
                      <td>{data?.role || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Position")}</th>
                      <td>{data?.position || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Email")}</th>
                      <td>{data?.email || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Phone Number")}</th>
                      <td>{data?.phone_number || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("User since")}</th>
                      <td>{data.created_at === undefined ? "" : format(parseISO(data.created_at), "dd MMM yyyy", { locale: i18n.language === "de" ? de : null }) + " (" + formatDistanceStrict(parseISO(data.created_at), new Date(), { unit: "day", locale: i18n.language === "de" ? de : null, addSuffix: false }) + ")"}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          {
            logCountData.length === 0 ?
              <p></p>
              :
              logCountData.reduce((a, v) => a = a + v.count_logs, 0) === 0
                ?
                <Card.Body>
                  <p>{t("No data found.")}</p>
                </Card.Body>
                :
                <Row>
                  <Col xl="12">
                    <LogsCount />
                  </Col>
                </Row>
          }
        </Row>
      </Container>
    </React.Fragment >

  );
};

export default UserDetails;
