import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Dropdown, Form, Modal, OverlayTrigger, Row, Table } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { format, parseISO } from "date-fns";
import UserDataManager from "../Helper/UserDataManager";
import { Check, Crosshair, Plus, Search, Trash2, X } from "react-feather";
import { isMobile } from "react-device-detect";
import NotyfContext from "../../contexts/NotyfContext";
import { de } from "date-fns/locale";
import AsyncSelect from 'react-select/async'
import { components } from "react-select";
import { getIAMBaseURL } from "../../config";

const ContractPermissionsModal = (props) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  const notyf = useContext(NotyfContext);

  useEffect(() => {
    if (openModal) {
      getPermissions()
    }
  }, [openModal]);

  function getPermissions() {
    axios
      .get("/contracts/" + props?.contract_data?.id + "/permissions")
      .then(function (response) {
        // handle success
        console.log(response.status, "Load contract permissions");
        setTenantUsers(response.data.sort((a, b) => a.username < b.username));
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  const [tenantUsers, setTenantUsers] = useState([]);

  function handleRemoveOwner(userId) {

    axios
      .delete("/contracts/" + props?.contract_data?.id + "/permissions/" + userId)
      .then(function (response) {
        // handle success
        console.log(response.status, "Deleted successfully");
        getPermissions();
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
      });
  }

  const loadOptions = (inputValue, callback) => {
    // perform a request

    var requestResults = []

    setError({ showMessage: false, errorData: null })

    clearTimeout(id);
    if (inputValue.length >= 3) {
      id = setTimeout(function (x) {
        if (inputValue && inputValue.length > 0)
          axios
            .get(getIAMBaseURL() + "/users/lookup?search_string=" + inputValue)
            .then(function (response) {
              // handle success
              requestResults = response.data.map(e => ({ label: e.first_name + " " + e.last_name + " (" + e.email + ")", value: e.email, id: e.id }))
              callback(requestResults)
              console.log(response.status, "Search data. Found: ", response.data.length);
            })
            .catch(function (error) {
              // handle error
              callback(requestResults)
              console.log(error);
            });
      }, 500) //0,5 Sekunden
    } else {
      callback(requestResults)
    }
  }

  function handleSelectSearchResult(event) {

    if (event === null || event?.id === null) {
      console.log("Error");
    } else {

      setError({ showMessage: false, errorData: null })

      updatePermission(event?.id, event?.value, "read_all")
    }
  }

  function updatePermission(userId, username, permission) {
    axios
      .put("/contracts/" + props?.contract_data?.id + "/permissions/", {
        "user_id": userId,
        "username": username,
        "permission_level": permission
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Edited successfully");

        notyf.open({
          type: "success",
          message: t("Permission updated."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        getPermissions();
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <Search className="feather" />
      </components.DropdownIndicator>
    );
  };

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        {props.selectProps.inputValue.length === 0 && <span className="custom-css-class">{t("What are you looking for?")}</span>}
        {props.selectProps.inputValue.length > 0 && props.selectProps.inputValue.length < 3 && <span className="custom-css-class">{t("Please enter more characters.")}</span>}
      </components.NoOptionsMessage>
    );
  };

  const loadAvatar = (url) => {
    return (
      <img
        src={url == null ? null : url}
        className="center rounded-circle"
        alt="avatar"
        width={28} height={28}
      />);
  };

  const ShowPermissionsList = () => {

    return (
      <Table size="sm" responsive hover>
        <thead>
          <tr>
          <th scope="col"></th>
            <th scope="col">{t("User")}</th>
            <th scope="col">{t("Permission Level")}</th>
          </tr>
        </thead>
        <tbody>{tenantUsers?.map((user) => {
          return (
            <tr key={user.id} style={{ cursor: "pointer" }}>
              <td >{user == null ? null : loadAvatar(user.avatar_url)}</td>
              <td>{user?.username || "-"}</td>
              <td>
                <Form.Select defaultValue={user?.permission_level} onChange={event => updatePermission(user?.user_id, user?.username, event.target.value)}>
                  <option value={"no_access"}>{t("No Permission")}</option>
                  <option value={"read_all"}>{t("Read Access (read_all)")}</option>
                  <option value={"client_agent"}>{t("Client Agent")}</option>
                  <option value={"finance_agent"}>{t("Finance Agent")}</option>
                  <option value={"service_agent"}>{t("Service Agent")}</option>
                </Form.Select>
              </td>
              <td><Trash2 width={14} height={14} className="me-1" onClick={e => window.confirm(t("Are you sure you wish to remove this user?")) && handleRemoveOwner(user?.id)} style={{ cursor: "pointer" }} /></td>
            </tr>
          )
        })}</tbody>
      </Table>
    )
  }
  return (
    <>
      <Dropdown.Item onClick={() => toggle()}>{t("Edit Contract Permissions")}</Dropdown.Item>
      <Modal size="xl" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Edit Contract Permissions")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Row>
            <Col>
              {t("Add new users to this tenant. Note: All cap-on admin users can access tenants anyway.")}
              <AsyncSelect
                closeMenuOnSelect={true}
                // classNamePrefix="testSelect"
                isClearable
                // formatOptionLabel={country => (
                //   <div className="country-option">
                //     <span><strong>{country.value}</strong>&nbsp;&nbsp;</span>
                //     <span>{country.label}</span>
                //     {/* <Button className="me-1 btn btn-secondary btn-sm" onClick={e => console.log("test")}>Test</Button> */}
                //   </div>
                // )}

                theme={(theme) => ({
                  ...theme,
                  borderRadius: 3,
                  colors: {
                    ...theme.colors,
                    // primary25: 'hotpink',
                    primary: 'primary',
                  },
                })}
                components={{ DropdownIndicator, NoOptionsMessage }}
                loadOptions={loadOptions}
                loadingMessage={() => t('Searching users...')}
                placeholder={t("Search...")}
                onChange={handleSelectSearchResult}
                styles={{
                  group: provided => ({
                    ...provided,
                    borderBottom: '1px solid #ccc',
                  }),
                  placeholder: base => ({
                    ...base,
                    fontSize: "1em",
                    color: "gray",
                    fontWeight: 300
                  })
                }} />
              <br />
              <ShowPermissionsList />
            </Col>
          </Row>
          <br />
          <hr />
          <Row>
            <Col xs="auto" className="ms-auto text-end mt-n1">
              <Button onClick={e => toggle(false)} variant={"primary"}>
                {t("Done")}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ContractPermissionsModal;
