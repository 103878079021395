import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { parseISO, startOfDay } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";

const AddInvoice = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const [DueDatePicker, setDueDatePicker] = useState();
  const [PeriodStartDatePicker, setPeriodStartDatePicker] = useState();
  const [PeriodEndDatePicker, setPeriodEndDatePicker] = useState();

  const [invoiceIncludeLastDay, setInvoiceIncludeLastDay] = useState(true);
  const [sendAlert, setSendAlert] = useState(false);
  
  const [DueDate, setDueDate] = useState();
  const [PeriodStartDate, setPeriodStartDate] = useState();
  const [PeriodEndDate, setPeriodEndDate] = useState();
  const [Contract, setContract] = useState("");

  function handleChangeStartDatePicker(event) {
    setPeriodStartDatePicker(event.target.value)
    setPeriodStartDate(zonedTimeToUtc(parseISO(event.target.value), "GMT").toISOString())
  }

  function handleChangeEndDatePicker(event) {
    setPeriodEndDatePicker(event.target.value)
    setPeriodEndDate(zonedTimeToUtc(parseISO(event.target.value), "GMT").toISOString())
  }

  function handleChangeDueDatePicker(event) {
    setDueDatePicker(event.target.value)
    setDueDate(zonedTimeToUtc(parseISO(event.target.value), "GMT").toISOString())
  }

  function handleChangeDatesLastMonth(event) {

    const startOfLastMonth = new Date(new Date().getFullYear(), (new Date()).getMonth() - 1, 2)
    const endOfLastMonth = new Date(new Date().getFullYear(), (new Date()).getMonth(), 1)
    const dueDate = new Date(new Date().getFullYear(), (new Date()).getMonth() + 1, 0)

    setPeriodStartDatePicker(startOfLastMonth.toISOString().split('T')[0])
    setPeriodEndDatePicker(endOfLastMonth.toISOString().split('T')[0])
    setDueDatePicker(dueDate.toISOString().split('T')[0])

    setPeriodStartDate(zonedTimeToUtc(parseISO(startOfLastMonth.toISOString().split('T')[0]), "GMT").toISOString())
    setPeriodEndDate(zonedTimeToUtc(parseISO(endOfLastMonth.toISOString().split('T')[0]), "GMT").toISOString())
    setDueDate(zonedTimeToUtc(parseISO(dueDate.toISOString().split('T')[0]), "GMT").toISOString())
    setInvoiceIncludeLastDay(true)

    // console.log(PeriodStartDate, PeriodEndDate, DueDate)
  }

  function handleChangeDatesCurrentMonth(event) {

    const startOfLastMonth = new Date(new Date().getFullYear(), (new Date()).getMonth(), 2)
    const yesterday = startOfDay(new Date());
    const dueDate = new Date(new Date().getFullYear(), (new Date()).getMonth() + 1, 0)

    setPeriodStartDatePicker(startOfLastMonth.toISOString().split('T')[0])
    setPeriodEndDatePicker(yesterday.toISOString().split('T')[0])
    setDueDatePicker(dueDate.toISOString().split('T')[0])

    setPeriodStartDate(zonedTimeToUtc(parseISO(startOfLastMonth.toISOString().split('T')[0]), "GMT").toISOString())
    setPeriodEndDate(zonedTimeToUtc(parseISO(yesterday.toISOString().split('T')[0]), "GMT").toISOString())
    setDueDate(zonedTimeToUtc(parseISO(dueDate.toISOString().split('T')[0]), "GMT").toISOString())
    setInvoiceIncludeLastDay(true)

    // console.log(PeriodStartDate, PeriodEndDate, DueDate)
  }


  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    axios
      .post("/invoices/include_asset_bills?include_last_day=" + invoiceIncludeLastDay,
        {
          due_date: DueDate,
          contract_id: Contract,
          invoice_period_start: PeriodStartDate,
          invoice_period_end: PeriodEndDate,
          create_alerts: sendAlert,
          additional_items: [
            // {
            //   "name": "cap-on Digital Premium Package",
            //   "quantity": 1,
            //   "price": 2199,
            //   "total": 2199
            // },
            // {
            //   "name": "Taktzeitanalyse",
            //   "quantity": 1,
            //   "price": 600,
            //   "total": 600
            // },
            // {
            //   "name": "CO\u2082-Kompensation",
            //   "amount": 31
            // }
          ]
        }
      )
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        navigate("/invoices/" + response.data.id);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  useEffect(() => {
    axios
      .get("/contracts/")
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        setData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    }, []);

  const loadContracts = () => {
    return data.map((contract) => {
      return (
        <option key={contract.id} value={contract.id}>{contract.title} ({contract.client_name})</option>
      );
    });
  };

  return (
    <React.Fragment>
      <Helmet title={t("Create Invoice")} />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{t("Create Invoice")}</h1>
        <Row>
          <Col lg="12">
            <Card>
              <Card.Header>
                <Row>
                  <ErrorHandler error={error} />
                </Row>
                <Row>
                  <Col xs="auto" className="d-none d-sm-block">
                    <h6 className="card-subtitle text-muted">
                      <br />
                      {t("Simply enter the billing period, a due date and the corresponding contract in order to create a new invoice.")}
                    </h6>
                  </Col>
                  <Col xs="auto" className="ms-auto text-end">
                    <Button size="sm" variant="outline-primary" className="shadow-sm me-1" onClick={handleChangeDatesLastMonth}>
                      {t("Pre-fill Last Month")}
                    </Button>
                    <Button size="sm" variant="outline-primary" className="shadow-sm me-1" onClick={handleChangeDatesCurrentMonth}>
                      {t("Pre-fill Current Month")}
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>

                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Invoice Period Start")}</Form.Label>
                        <Form.Control
                          value={PeriodStartDatePicker}
                          required
                          // value={"2022-12-03"}
                          onChange={handleChangeStartDatePicker}
                          type="date"
                        />
                      </Form.Group>
                    </Col>
                    {/* <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  value="2381"
                  type="number"
                  onChange={handleChangeAmount}
                />
              </Form.Group>
            </Col> */}
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Invoice Period End")}</Form.Label>
                        <Form.Control
                          value={PeriodEndDatePicker}
                          required
                          onChange={handleChangeEndDatePicker}
                          type="date"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                    </Col>
                    <Col md={6}>
                      <Form.Check
                        checked={invoiceIncludeLastDay}
                        onChange={e => setInvoiceIncludeLastDay(!invoiceIncludeLastDay)}
                        label={t("Include last day")}
                        type="checkbox" />
                    </Col>

                    {/* <Col md={6}>
              <Form.Check
                type="switch"
                onChange={handleChangeIncludeLastDay}
                id="exampleCustomSwitch"
                name="customSwitch"
                label="Include last day"
              />
            </Col> */}
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      {/* <Form.Group className="mb-3">
                <Form.Label>Contract</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e59d31a7-8a41-4e35-b8d6-a89ec888b7ad"
                  onChange={handleChangeContract}
                />
              </Form.Group> */}
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Contract")}</Form.Label>
                        <Form.Select
                          name="state"
                          required
                          onChange={e => setContract(e.target.value)}>
                          <option value="">{t("-- Select --")}</option>
                          {loadContracts()}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Due date")}</Form.Label>
                        <Form.Control
                          value={DueDatePicker}
                          required
                          onChange={handleChangeDueDatePicker}
                          type="date"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={12}>
                      <Form.Check
                        checked={sendAlert}
                        onChange={e => setSendAlert(!sendAlert)}
                        label={t("Send invoice to account managers")}
                        type="checkbox" />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => navigate(-1)}>
                        {t("Cancel")}
                      </Button>
                      <Button type="submit" variant="primary">
                        {t("Create Invoice")}
                      </Button>
                    </Col>
                  </Row>

                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AddInvoice;
