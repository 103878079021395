import React, { useEffect, useState } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import tqrQzGBLogo from "../../assets/company/tqrQzGB.png";
import tWMPWtlLLogo from "../../assets/company/tWMPWtl.png";
import tJYKgNALogo from "../../assets/company/tJYKgNA.png";
// import sdlLogo2 from "../../assets/company/sdl.png";
import sdlLogo from "../../assets/img/logos/SDL_Logo_weiss.png"
import tFER6vxLogo from "../../assets/company/tFER6vx.png";
import kontron_sidebar from "../../assets/company/kontron_sidebar.png";
import SidebarLoginButton from "./SidebarLoginButton";
import { useNavigate } from "react-router-dom";
import isDemoMode from "../../pages/Helper/DemoMode";
import wegomaLogo from "../../assets/company/wegoma.png"

const Sidebar = ({ items, showFooter = false, showLoginButton = false }) => {
  const { isOpen } = useSidebar();
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState("");

  // useEffect(() => {

  //   axios
  //     .get(getIAMBaseURL() + "/tenants/" + localStorage.getItem("tenant_id"))
  //     .then(function (response) {
  //       // handle success
  //       console.log("--->", response.data)
  //       setAvatar(response.data.avatar_url)
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     })
  // }, []);

  const LoadImage = () => {

    if (isDemoMode()) {
      return (<img src={tqrQzGBLogo} alt="cap-on" style={{ height: "110px", paddingTop: "5px", paddingBottom: "10px", marginBottom: "0px" }} />)
    }

    switch (localStorage.getItem("tenant_id")) {
      case "tWMPWtl": // MAAG
        return (<img src={tWMPWtlLLogo} alt="cap-on" style={{ height: "44px", marginTop: "40px", marginBottom: "60px" }} />)
      case "tFER6vx": // tkAB
        return (<img src={tFER6vxLogo} alt="cap-on" style={{ height: "160px", paddingTop: "0px", paddingBottom: "5px", marginBottom: "0px" }} />)
      case "tJYKgNA": // Siemens
        return (<img src={tJYKgNALogo} alt="cap-on" style={{ height: "160px", paddingTop: "0px", paddingBottom: "0px", marginBottom: "0px" }} />)
      default: // cap-on

        switch (window.location.host.toLowerCase()) {
          case "sueddeutsche-leasing.cap-on.de":
          case "sueddeutsche-leasing.cap-on.io":
            return (<img src={sdlLogo} alt="cap-on" style={{ height: "100px", paddingTop: "10px", paddingBottom: "20px", marginBottom: "0px" }} />)
          case "siemens.cap-on.de":
          case "siemens.cap-on.io":
            return (<img src={tJYKgNALogo} alt="cap-on" style={{ height: "160px", paddingTop: "0px", paddingBottom: "0px", marginBottom: "0px" }} />)
          case "kontron.cap-on.de":
          case "kontron.cap-on.io":
            return (<img src={kontron_sidebar} alt="cap-on" style={{ height: "120px", paddingTop: "0px", paddingBottom: "0px", marginBottom: "0px" }} />)
          case "thyssenkruppab.cap-on.de":
          case "thyssenkruppab.cap-on.io":
            return (<img src={tFER6vxLogo} alt="cap-on" style={{ height: "160px", paddingTop: "0px", paddingBottom: "5px", marginBottom: "0px" }} />)
          case "wegoma.cap-on.de":
          case "wegoma.cap-on.io":
            return (<img src={wegomaLogo} alt="cap-on" style={{ height: "40px", marginTop: "30px", marginBottom: "30px" }} />)
          default:
            return (<img src={tqrQzGBLogo} alt="cap-on" style={{ height: "110px", paddingTop: "5px", paddingBottom: "10px", marginBottom: "0px" }} />)
        }
    }
  };

  return (
    <nav
      className={`sidebar ${!isOpen ? "collapsed" : ""}`}
      style={{
        paddingTop: "10px",

      }}
    >
      <div className="sidebar-content">
        <PerfectScrollbar>
          <div onClick={e => navigate("/dashboard")} style={{ cursor: "pointer" }} className="sidebar-brand">

            {LoadImage()}
            <br />
            <p style={{ paddingTop: "0px" }}>IoT-Hub</p>
          </div>

          <SidebarNav items={items} />
          {!!showFooter && <SidebarFooter />}
          {!!showLoginButton && <SidebarLoginButton />}
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
