import { useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { Edit } from "react-feather";

const ActivateGatewayModal = ({ gatewayData }) => {

  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  const [targetDate, setTargetDate] = useState();
  const [active, setActive] = useState(true);
  const [internetConnection, setInternetConnection] = useState();
  const [region, setRegion] = useState();

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    // hier sollte man im dropdown einen Kunden auswählen können, dessen ID dann im Post-Request gesetzt wird
    axios
      .post(`/iot_gateways/${gatewayData?.id}/con_activities`, {
        "target_date": targetDate,
        "paused": !active,
        "internet_connection": internetConnection,
        "region": region
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Load");
        refreshPage();
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });

  }

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <>
      <Button
        onClick={() => toggle()}
        variant="outline-primary"
        className="shadow-sm"
        size="sm"
      >
        <Edit className="feather me-1" /> {t("Activate / deactivate Gateway")}
      </Button>

      <Modal size="ms" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Edit gateway")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Alert variant="secondary" className="mb-4">
                <div className="alert-message">
                  {t("For the (de-)activation of a gateway, a processing fee of 25 Euro is due.")}
                </div>
              </Alert>
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Col md={12}>
              <Form.Group className="mb-4">
                <Form.Label>{t("Target date")}</Form.Label>
                <Form.Control
                  onChange={event => setTargetDate(event.target.value)}
                  type="date"
                // min={today} // Set the minimum selectable date to today
                />
              </Form.Group>
            </Col>
            <Col md="12">
              <Form.Group className="mb-4">
                <Form.Label>{t("New gateway state")}</Form.Label>
                <div className="d-flex">
                  <Form.Check
                    onChange={() => setActive(true)}
                    checked={active}
                    label={t("Active")}
                    type="radio"
                    className="me-4"
                  />
                  <Form.Check
                    onChange={() => setActive(false)}
                    checked={!active}
                    label={t("Inactive")}
                    type="radio"
                  />
                </div>
              </Form.Group>
            </Col>
            {active && <Row>
              <Col md={internetConnection === "lte" ? 6 : 12}>
                <Form.Group className="mb-4">
                  <Form.Label>{t("Internet Connection")}</Form.Label>
                  <Form.Select onChange={event => setInternetConnection(event.target.value)}>
                    <option value="">{t("-- Select --")}</option>
                    <option value={"lte"} >{t("Cellular Data / 4G / LTE")}</option>
                    <option value={"ethernet"} >{t("Ethernet Cable")}</option>
                    <option value={"wifi"} disabled >{t("Wifi Connection")} ({t("Later")})</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              {internetConnection === "lte" && <Col md={6}>
                <Form.Group className="mb-4">
                  <Form.Label>{t("Region for cellular data")}</Form.Label>
                  <Form.Select onChange={event => setRegion(event.target.value)} required>
                    <option value="">{t("-- Select --")}</option>
                    <option value={"de"}  >{t("Germany")}</option>
                    <option value={"us"} >{t("USA / Canada")}</option>
                    <option value={"eu"}  >{t("European Union")}</option>
                    <option value={"other_non_eu"} >{t("Non-EU")}</option>
                  </Form.Select>
                </Form.Group>
              </Col>}
            </Row>}
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="outline-secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Save")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal >
    </>
  );
};

export default ActivateGatewayModal;
