import { Card, Col, Row, Table } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";
import { Download, Search, Trash } from "react-feather";
import axios from "axios";
import UserDataManager from "../Helper/UserDataManager";
import UploadFileModal from "../Helper/UploadFileModal";

const FileTable = ({ data, title, parent_id, parent_type }) => {
  const { t } = useTranslation();

  function handleDelete(e, file_id) {
    e.preventDefault();
    axios
      .delete(parent_type + parent_id + "/files/" + file_id)
      .then(function (response) {
        console.log(response.status, "Load");
        // handle success
        refreshPage();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  const renderTable = () => {

    return data?.sort((a, b) => b?.title.localeCompare(a?.title))?.map((file) => {

      return (
        <tr key={file.id} onClick={() => openInNewTab(file.url)} style={{ cursor: "pointer" }} >
          <td>{file.title}</td>
          <td>{file.file_type}</td>
          {/* <td>{file.created_at}</td> */}
          <td className="table-action">
            {/* <Search
              alt="Show"
              className="align-middle"
              size={18}
              onClick={() => window.open(file.url, '_blank')}
              style={{ cursor: "pointer" }}
            />
            &nbsp;
            &nbsp; */}
            {/* <Download
              alt="Download"
              className="align-middle"
              size={18}
              onClick={() => open(file.url, file.title)}
              style={{ cursor: "pointer" }}
            /> */}

            {/* <Search
              alt="Open"
              className="align-middle"
              size={18}
              onClick={() => openInNewTab(file.url)}
              style={{ cursor: "pointer" }}
            /> 
            &nbsp;
            &nbsp;*/}

            {/* <Download
              alt="Download"
              className="align-middle"
              size={18}
              onClick={e => {
                e.stopPropagation()
                downloadFile(file.url, file.title)
              }}
              style={{ cursor: "pointer" }}
            />

            &nbsp;
            &nbsp; */}

            <Trash alt="Delete" className="align-middle" size={18} onClick={e => {
              e.stopPropagation()
              window.confirm(t("Are you sure you wish to delete this file?")) && handleDelete(e, file.id)
            }}
              style={{ cursor: "pointer" }}
            />
          </td>
        </tr>
      );
    });
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>

        <Row>
          <Col className="mt-0">
            <Card.Title tag="h5" className="mb-0">
              {t(title)}
            </Card.Title>
          </Col>
          <Col xs="auto">
            <UploadFileModal buttonType="small" type={parent_type?.replace("s/", "")?.replace("/", "")}/>
          </Col>
        </Row>

      </Card.Header>
      {/* <Card.Body> */}
      {
        data !== null && data?.length > 0
          ?
          <Table responsive hover>
            <thead>
              <tr>
                <th scope="col">{t("Title")}</th>
                <th scope="col">{t("File type")}</th>
                {/* <th scope="col">{t("Uploaded")}</th> */}
              </tr>
            </thead>
            <tbody>{renderTable()}</tbody>
          </Table>
          :
          <Card.Body>
            <p>{t("No data found.")}</p>
          </Card.Body>
      }
      {/* </Card.Body> */}
    </Card>
  );
};

function downloadFile(url, fileName) {
  const link = document.createElement('a');
  link.href = url;

  // Set the `download` attribute to ensure the browser downloads the file instead of opening it
  link.setAttribute('download', fileName || 'download.csv'); // Set default name as 'download.csv' if no fileName

  // Append the link to the document body (required for Firefox)
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Clean up by removing the link element
  document.body.removeChild(link);
}

function openInNewTab(url) {
  const win = window.open(url, '_blank'); // Open in a new tab
  if (win) {
    win.focus();  // Ensure the new tab gets focus
  } else {
    // Handle cases where the pop-up is blocked
    alert('Please allow pop-ups for this site.');
  }
}

function refreshPage() {
  window.location.reload(false);
}

export default FileTable;
